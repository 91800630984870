// Handles the add to cart functionality
define('addToCart', ['jquery', 'messaging', 'viewData', 'review', 'reviewStateProcessor', 'buildValidator', 'overlay', 'http'], function ($, messaging, viewData, review, reviewStateProcessor, buildValidator, overlay, http) {

	// initialize the buttons related to the add to cart functionality
	var init = function () {
        $('.review-container .add-to-cart').click(addToCartButtonClicked);
		$('.review-container .start-over').click(startOverLinkClicked);
		setAddToCartButton();
	};




	// add to cart button click handler
    var addToCartButtonClicked = function () {

        // add overlay to prevent unwanted clicks
        overlay.addOverlay();

		// publish a message that the add to cart button has been clicked
		messaging.addToCartButtonClicked.publish();

		// build templates break all the rules - so run though validation 1 more time before adding to cart.
        if (viewData.BuildTemplateID != null && viewData.BuildTemplateID > 0) {
            messaging.reviewUpdateRequested.publish();
        }

        // get the review state from the review module (this is so that the content added to cart matches the content of the review pane)
		var reviewState = review.getReviewState();
		// process the review state.  this gets all the other information for the template and adds in the information the user entered.
		// this also matches the ProjectBuild class definition
		var processedState = reviewStateProcessor.processReviewStateData(reviewState);

		// validate the processed state.  this module also raises any alerts and highlights missing fields
		var validBuild = buildValidator.validateBuild(processedState);
		// if the field is invalid, then return
        if (!validBuild)
        {
            overlay.removeOverlay();
            return;          
        }


		// convert the processed state to a json string
		var stateJSON = JSON.stringify(processedState);
		// create a new form element, add it to the page and post the information to the AddToCart action
		var $form = $('<form>').addClass('add-to-cart-form').attr('method', 'post').attr('action', '/Build/Project/AddToCart');
		$form.append($('<input>').attr('name', 'BuildID').val(viewData.BuildID));
		$form.append($('<textarea>').attr('name', 'BuildState').val(stateJSON));
		$('body').append($form);	
		$('.add-to-cart-form').submit();
	};

	// show a confirm message and if they confirm the start over action, redirect the user to the start over link that was provided
	var startOverLinkClicked = function () {
		var message = 'Are you sure you want to start over?';
		if (confirm(message)) {
			// handle one view project scenarios
			if (viewData.Project.OneViewProject) {
				// if the start over url is set for the build, then send them to it (in the top window)
				if (viewData.StartOverURL && (viewData.StartOverURL != ''))
					window.top.location = viewData.StartOverURL;
				else	// since this is a one view project, send them to the template selection view if the start over url isn't set
					window.location = '/build/project/oneviewtemplateselection?projectID=' + viewData.ProjectID + '&processurl=' + viewData.ProcessURL;
			}
			else	// for regular build scenarios, just reload the window
				location.reload(true);
		}
	};

	var setAddToCartButton = function () {
		var envir = http.get('/api/projects/UseDevelopmentPath').then(processSetAddToCartButton);
	};

	var processSetAddToCartButton = function (response) {		
		//if (response == 'DEV' || response == 'UAT') {
		if (response) {	
			var $input = $('<input>').attr('type', 'checkbox').attr('id','Development-Path');
			var $label = $('<span>').html('Use Development Path').css('padding-left', '5px');
			var $wrapper = $('<div>').append($input).append($label);
			$('.add-to-cart').after($wrapper);
		}
	};


	return {
		init: init
	};
});
// handles the application of the hotspot to the build and preview
define('applyHotspot', ['messaging', 'jquery', 'templateFormValidator', 'customImage', 'perspectivePreview'], function (messaging, $, templateFormValidator, customImage, perspectivePreview) {

	var validationEnabled = true;

	// initializes the click handlers
	var init = function () {
	};

	// apply button click handler
	var applyButtonClicked = function () {
		// initialize some jquery elements
		var $hotspotView = $('.hotspot-view.open');
        var $openTemplateGroup = $hotspotView.find('.menu-tab .template-group.open');;

		// if the validation is enabled, validate the template form
		//if (validationEnabled) {
		//	var formValid = templateFormValidator.validateForm($openTemplateGroup.find('.template-form'), 'Your changes cannot be applied until the following problems are fixed:');
		//	if (!formValid)
		//		return;
		//}

		// let the preview know that new settings have been applied and it needs to update
		messaging.applyPreview.publish();
		// remove all elements for this hotspot view and remove the applied class
		$hotspotView.removeClass('applied').find('.applied').each(function (index, element) {
			$(element).removeClass('applied');
		});
		// mark the currently open hotspot as applied
        $openTemplateGroup.addClass('applied').parents('.menu-tab').addClass('applied').parents('.hotspot-view').addClass('applied');

		// this zooms out the ui
		// this should be updated to be a pubsub call, not a dom action
        $('.zoom-out-link').click();

        // remove class needed for mobile UI - mobile hack
        $('.project-ui').removeClass('active-fields');

		// publish the apply button clicked message
        messaging.applyButtonClicked.publish();

        //update persepectives and their hotspot overlay
        var mobileSlideIndex = parseInt($('.mobile-perspective-views .swiper-slide-active').attr('data-index'));
        messaging.perspectiveChanged.publish();
        // move to current sldie on mobile
		perspectivePreview.updateMobileSlidePostion(mobileSlideIndex);
	};

	// clear button click handler
	// this doesn't seem to work at the moment for one view projects
	var clearLinkClicked = function () {
		var message = 'Are you sure you want to clear this location?';
		if (confirm(message)) {
			var $templateForm = $('.hotspot-view.open .menu-tab.open .template-group.open .template-form');
			// loop over all input fields in the form and clear the value and hit the change event
			$templateForm.find('input, textarea').each(function (index, element) {
				var $element = $(element);
				$element.val('').change();
			});
		}
	};

	var disableValidation = function () {
		validationEnabled = true;
	};

	return {
		init: init,
        disableValidation: disableValidation,
        applyButtonClicked: applyButtonClicked
	};
});
// this module handles the bottom bar button visibility status
define('bottomBar', ['jquery'], function ($) {
	'use strict';

	var barSelector = '.project-ui .bottom-bar';
	var guideButtonSelector = '.bottom-bar-button.guide-toggle';

	// init method for button click handler
	var init = function () {
		$(guideButtonSelector).click(guideButtonClicked);
	};

	// the guide is available, so show it
	var guideAvailable = function () {
		var $bar = $(barSelector);
		$bar.removeClass('has-guide').addClass('has-guide');
	};

	// the guide isn't available, so hide it
	var guideUnavailable = function () {
		var $bar = $(barSelector);
		$bar.removeClass('has-guide');
	};

	// enable the guide button
	var guideEnabled = function () {
		var $bar = $(barSelector);
		$bar.removeClass('guide-enabled').addClass('guide-enabled');
	};

	// disable the guide button
	var guideDisabled = function () {
		var $bar = $(barSelector);
		$bar.removeClass('guide-enabled');
	};

	// enable the clear button
	var clearEnabled = function () {
		var $bar = $(barSelector);
		$bar.find('.bottom-bar-button.clear').removeClass('hidden');
	};

	// disable the clear button
	var clearDisabled = function () {
		var $bar = $(barSelector);
		$bar.find('.bottom-bar-button.clear').addClass('hidden');
	};

	// guide button click handler
	var guideButtonClicked = function () {
		$('.toggle-guide-link').click();
	};

	init();

	return {
		guideAvailable: guideAvailable,
		guideUnavailable: guideUnavailable,
		guideEnabled: guideEnabled,
		guideDisabled: guideDisabled,
		clearEnabled: clearEnabled,
		clearDisabled: clearDisabled
	};
});
// this module validates the build state.  mostly the build validation logic itself is in the hotspotFormStateReader, but this handles the validation results
define('buildValidator', ['jquery', 'validationAlert'], function ($, validationAlert) {

	var fieldOptionTextTypes = [2, 3, 4, 5, 6, 7, 8, 9, 13, 14];

	// handles validation state for the build
	var validateBuild = function (build) {
		var validBuild = true;
		var messages = [];

		// remove any classes that mark an element as incomplete
		$('.field-option').removeClass('incomplete-field fill-color-missing outline-color-missing shadow-color-missing');
		$('.field-option-image-color-control').removeClass('replaceable-color-missing');

		// loop over each hotspot and its fields' and make sure the field is valid
		build.Hotspots.forEach(function (hotspot) {
			hotspot.Fields.forEach(function (field) {
				if (!field.ValidField) {
					validBuild = false;
					// this is an example of the underscore library usage.  it basically appends one array to teh other and makes sure the items are unique
					messages = _.union(messages, field.ValidationMessages);
					// find the active option for this field
					var $activeOption = $('.field-option[data-id=' + field.ActiveOptionUUID + ']');
					// apply any validation classes for the active field option.  this may include missing text, missing shadow color, etc
					$activeOption.addClass(field.ValidationClasses);
					// if there are any missing replaceable colors, then mark them as required
					field.MissingReplaceableColors.forEach(function (id) {
						$activeOption.find('.field-option-image-color-control[data-replaceableid=' + id + ']').addClass('replaceable-color-missing');
					});
				}
			});
		});

		// if the build isn't valid then show an alert
		if (!validBuild)
			validationAlert.showAlert(messages);

		// return the boolean indicating whether or not the build is valid
		return validBuild;
	};

	return {
		validateBuild: validateBuild
	};
});
// this module handles the bottom bar button visibility status
define('commonColorClick', ['jquery', 'utils', 'viewData'], function ($, utils, viewData) {
    'use strict';


    var ColorLimitDisplay = function () {

        // update the current selected colors per color group
        for (var i = 0; i < viewData.AvailableColorGroups.length; i++)
        {
            if (viewData.AvailableColorGroups[i].EnableColorLimit)
            {
                viewData.AvailableColorGroups[i].CurrentlySelected = [];
                $('.hotspot-view.open .field .color-option-border.selected').each(function ()
                {
                    var colorID = parseInt($(this).attr('data-id'));
                    var groupsIDs = utils.csvToIntegerArray($(this).attr('data-group-id'));
                    if ($.inArray(viewData.AvailableColorGroups[i].ColorGroupID, groupsIDs) >= 0)
                    {
                        if ($.inArray(colorID, viewData.AvailableColorGroups[i].CurrentlySelected) == -1)       
                        {
                            viewData.AvailableColorGroups[i].CurrentlySelected.push(colorID);
                        }  
                    }                    
                });
            }
        }

        // check if color limit is reached and set inactive colors
        for (var i = 0; i < viewData.AvailableColorGroups.length; i++)
        {
            if (viewData.AvailableColorGroups[i].EnableColorLimit && viewData.AvailableColorGroups[i].CurrentlySelected.length >= viewData.AvailableColorGroups[i].MaxColorsAllowed)
            {
                $('.hotspot-view.open .field .color-option-border').each(function ()
                {                
                    var colorID = parseInt($(this).attr('data-id'));
                    var groupsIDs = utils.csvToIntegerArray($(this).attr('data-group-id'));
                    var colorID = parseInt($(this).attr('data-id'));
                    if ($.inArray(viewData.AvailableColorGroups[i].ColorGroupID, groupsIDs) >= 0 &&
                        !$(this).hasClass('selected') &&
                        $.inArray(colorID, viewData.AvailableColorGroups[i].CurrentlySelected) == -1)
                    {
                        $(this).addClass('inactive');           
                    } 
                });
            }
            else if (viewData.AvailableColorGroups[i].EnableColorLimit)
            {
                $('.hotspot-view.open .field .color-option-border').each(function ()
                {
                    var groupsIDs = utils.csvToIntegerArray($(this).attr('data-group-id'));
                    if ($.inArray(viewData.AvailableColorGroups[i].ColorGroupID, groupsIDs) >= 0 && !$(this).hasClass('selected'))
                    {
                        $(this).removeClass('inactive');
                    }
                });
            }
        }
    }



    var CommonColorGroup = function (commonColorGroupUUID, colorProperty, colorId, isSelected)
    {
        var commonColorGroups = viewData.CommonColorGroups;
        if (commonColorGroups != null)
        {
            for (var j = 0; j < commonColorGroups.length; j++)
            {
                if (commonColorGroups[j].UUID == commonColorGroupUUID)
                {
                    var commonFields = commonColorGroups[j].FieldUUIDs;
                    for (var k = 0; k < commonFields.length; k++)
                    {
                        var group = '';
                        if (colorProperty == 'color') {
                            group = '.Color-field';
                        }
                        else if (colorProperty == 'outline') {
                            group = '.OutlineColor-field';
                        }
                        else if (colorProperty == 'shadow') {
                            group = '.ShadowColor-field';
                        }
                        else if (colorProperty == 'image') {
                            group = '.Color-field';
                        }

                        $('.hotspot-view.open .field').each(function ()
                        {
                            var $thisField = $(this);
                            var fieldUUID = $thisField.attr('data-id');
                            if (fieldUUID == commonFields[k])
                            {
                                // what the hell is this doing?
                                if (!$.isNumeric(colorId))
                                {
                                    colorId = '';
                                }

                                // set values for fields that are apart of the common color group
                                $thisField.find('.field-option-color-control' + group + ' input[type=hidden]').val(colorId);
                                $thisField.find('.field-option-color-control' + group + ' .color-option-border').removeClass('selected');
                                if (isSelected)
                                {
                                    $thisField.find('.field-option-color-control' + group + ' .color-option-border[data-id=' + colorId + ']').addClass('selected');
                                }
                                if (group == '.Color-field')
                                {
                                    $thisField.find('.field-option-image-color-control input').val(colorId);
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    return {
        ColorLimitDisplay: ColorLimitDisplay,
        CommonColorGroup: CommonColorGroup,
    };
});
// this module handles the bottom bar button visibility status
define('customImage', ['jquery', 'iframeDialog', 'viewData', 'messaging'], function ($, iframeDialog, viewData, messaging) {
    'use strict';

    var activeRecaptchaId;

    // initialize recaptcha script
    var InitializeRecaptcha = function () {
        DisplayCaptchas();
    }

    // validation of captcha
    var isCaptchaValid = function () {
        if (grecaptcha.getResponse(activeRecaptchaId).length > 0) {
            return true;
        }
        return false;
    }

    // validation of agree to terms checkbox
    var isDisclaimerValid = function () {
        var $iAgree = $('.field.open .field-option.active-field-option .custom-image-content-wrapper .custom-image-agree-terms');
        if ($iAgree.is(':checked')) {
            return true;
        }
        return false;
    }

    // check if field option is valid.  Active upload button if so
    var isFieldOptionInitialized = function () {
        if (isCaptchaValid() && isDisclaimerValid()) {
            $('.field.open .field-option.active-field-option .custom-image-content-wrapper .choose-image-button').addClass('active');
        } else {
            $('.field.open .field-option.active-field-option .custom-image-content-wrapper .choose-image-button').removeClass('active');
        }
    }

    // agree terms checkback change handler
    $(document).on('change', '.field.open .field-option.active-field-option .custom-image-content-wrapper .custom-image-agree-terms', function (e) {
        isFieldOptionInitialized();
    });

    // display recaptchas
    var DisplayCaptchas = function () {
        var allReCaptchas = $('.recaptcha.custom-image');
        var recaptchaIds = [];
        var activeRecaptcha;
        allReCaptchas.each(function () {
            recaptchaIds.push($(this).attr('id'));
            if ($(this).closest('.field.open').length == 1) {
                activeRecaptcha = $(this).attr('id');
            }
        });
        for (var i = 0; i < recaptchaIds.length; i++) {
            try {
                var captchaID = grecaptcha.render(recaptchaIds[i], { 'sitekey': viewData.CaptchaKey, 'callback': isFieldOptionInitialized});
                if (activeRecaptcha == recaptchaIds) {
                    activeRecaptchaId = captchaID;
                }
                $('#' + recaptchaIds[i]).closest('.custom-image-content-wrapper').find('.choose-image-button').attr('data-captchaId', captchaID);     
            }
            catch (e)
            {

            }                   
        }
    }


    // Cloudinary - Center image
    $(document).on('click', '.aoi-buttons-wrapper .aoi-button', function (e) {
        var $this = $(this);

        if ($this.closest('aoi-wrapper').hasClass('loading') || $this.hasClass('selected'))
        {
            return;
        }
      
        var cloudinaryID = $this.closest('.custom-image-content-wrapper').find('input.custom-image-id').val();
        $this.closest('.aoi-buttons-wrapper').find('.aoi-button').removeClass('selected');
        $this.addClass('selected');
      
        if (cloudinaryID != null && cloudinaryID != '')
        {
            $this.closest('.aoi-wrapper').addClass('loading');
            $this.closest('.custom-image-content-wrapper').addClass('active');      
            var $loadingImage = $('<img>').addClass('loading-gif').attr('src', '/Images/ajax-loader.gif');
            $this.closest('.custom-image-content-wrapper').find('.custom-image-wrapper').append($loadingImage);
            messaging.hotspotPreviewRequested.publish();
        }       
    });


    // upload image to cloudinary, get response and display
    var UploadCustomImage = function ($this/*, isImaggaClick = false*/)
    {       
        var data = new FormData();
        var files = $this.get(0).files;

        if (files.length != 1) {
            $this.val('');
            $this.closest('.custom-image-content-wrapper').find('.aoi-wrapper').removeClass('loading');
            return;
        }
        
        var fileToUpload = files[0];
        if (!fileToUpload) {
            $this.val('');
            alert('Error uploading image');
            return;
        }

        // check file type
        var fileType = fileToUpload.type.toLowerCase();
        if (fileType != 'image/png' && fileType != 'image/jpeg' && fileType != 'image/jpg' && fileType != 'application/postscript') {
            $this.val('');
            alert('Invalid image file.  Please upload .jpeg, .png, .eps, or .ai file type');
            return;
        }

        // size check in bytes
        if (fileToUpload.size > viewData.CloudinarySettings.MaxImageSize && fileType != 'application/postscript') {
            $this.val('');
            alert('Image size is too big.  Max image size is ' + viewData.CloudinarySettings.MaxImageSize + ' KB');
            return;
        }

        // size check in bytes
        if (fileToUpload.size < viewData.CloudinarySettings.MinImageSize && fileType != 'application/postscript') {
            $this.val('');
            alert('Image size is too small.  Minimum image size is ' + viewData.CloudinarySettings.MinImageSize + ' KB');
            return;
        }
        
        data.append("file", fileToUpload);
        $this.closest('.custom-image-content-wrapper').find('.custom-image-error-message').addClass('hide');
        var captchaResponse = grecaptcha.getResponse($this.closest('.custom-image-content-wrapper').find('.choose-image-button').attr('data-captchaId'));
        var fieldOptionId = $this.closest('.field-option.active-field-option').attr('data-id'); 
        var hotspotUUID = $this.closest('.field-option.active-field-option').attr('data-parentid');    
        var fieldLabel = $this.closest('.field').find('.field-label').html(); 
        var hotspotId = $this.closest('.template-form').attr('data-templateid'); 
        var $imageWrapper = $this.closest('div.field-option').find('div.custom-image-wrapper');
        $imageWrapper.find('img').remove();
        var $loadingImage = $('<img>').addClass('loading-gif').attr('src', '/Images/ajax-loader.gif');
        $this.closest('.custom-image-content-wrapper').find('.custom-image-wrapper .clear-image-preview').addClass('not-visible');
        $this.closest('.custom-image-content-wrapper').addClass('active');
        $this.closest('.custom-image-content-wrapper').find('.aoi-wrapper').addClass('loading');
        $imageWrapper.append($loadingImage);
        $.ajax({
            url: '/build/project/UploadCustomImageToCloudinary?buildId=' + viewData.BuildID + '&fieldOptionId=' + fieldOptionId + '&captchaResponse=' + captchaResponse + '&hotspotId=' +
            hotspotId + '&fieldLabel=' + fieldLabel + '&hotspotUUID=' + hotspotUUID,// + '&isBackgroundImage=' + isBackgroundImage + '&cloudinaryID=' + cloudinaryID + '&versionID=' + versionID + '&type=' + type,
            type: 'POST',
            data: data,
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                if (response.type == "error") {
                    $this.val('');
                    var captchaID = $this.closest('.custom-image-content-wrapper').find('.choose-image-button').attr('data-captchaId');
                    grecaptcha.reset(captchaID);
                    $this.closest('.custom-image-content-wrapper').find('.custom-image-error-message').removeClass('hide');
                    $imageWrapper.find('img').remove();
                }
                else {
                    messaging.hotspotPreviewRequested.publish();
                    if (!response.Approved)
                    {
                        alert('image not approved');
                        var captchaID = $this.closest('.custom-image-content-wrapper').find('.choose-image-button').attr('data-captchaId');
                        grecaptcha.reset(captchaID);
                    }
                    else {
                        $this.closest('.custom-image-content-wrapper').find('.custom-image-id').val(response.CloudinaryId);
                        $this.closest('.custom-image-content-wrapper').find('.custom-image-version').val(response.Version);
                        $this.closest('.custom-image-content-wrapper').find('.custom-image-type').val(response.Type);
                        $imageWrapper.find('img.custom-image-preview').remove();
                        var image = $('<img>').addClass('hide custom-image-preview').attr('src', 'data:image/png;base64,' + response.ImageBytes);
                        $imageWrapper.prepend(image);
                        var captchaID = $this.closest('.custom-image-content-wrapper').find('.choose-image-button').attr('data-captchaId');
                        grecaptcha.reset(captchaID);
                        $this.closest('.custom-image-content-wrapper').find('.custom-image-agree-terms').attr('checked', false);
                        $this.closest('.custom-image-content-wrapper').find('.custom-image-wrapper .file-name').html(response.FileName);
                        $this.closest('.custom-image-content-wrapper').find('.choose-image-button').removeClass('active');
                    }                    
                }
            },
            error: function (error) {
                $this.val('');
                alert('error uploading image');
            },
            complete: function () {
            }
        });
    }


    // detect when new image is selected
    $(document).on("change", '.custom-image-content-wrapper .custom-image-upload', function (e) {
        UploadCustomImage($(this));
    });


    // open ifraem to view disclaimer
    $(document).on("click", '.disclaimer-link', function (e) {
        iframeDialog.openDialog();
        iframeDialog.openDialog('/Build/Project/GetDisclaimer', {
        });
    });


    // UI button click that opens natural input file field.  First checks the I agree box and recaptcha are complete
    $(document).on("click", '.custom-image-content-wrapper .choose-image-button', function (e) {

        var $this = $(this);
        var validField = true;

        // I agree box checked
        var $iAgree = $this.closest('.custom-image-content-wrapper').find('.custom-image-agree-terms');
        if ($iAgree.is(':checked'))
        {
            $this.closest('.custom-image-content-wrapper').find('.custom-image-agree-terms').removeClass('requirements');          
        }
        else {
            $this.closest('.custom-image-content-wrapper').find('.custom-image-agree-terms').addClass('requirements');
            validField = false;
        }       

        // client side recaptcha check
        var response = grecaptcha.getResponse($this.attr('data-captchaId'));
        if (response === '' || response === undefined) {
            $this.closest('.custom-image-content-wrapper').find('.recaptcha.custom-image').addClass('requirements');
            validField = false;

        } else {
            $this.closest('.custom-image-content-wrapper').find('.recaptcha.custom-image').removeClass('requirements');
            $this.closest('.custom-image-content-wrapper').find('.error-message-custom-image').addClass('hide');            
        }
                
        if (validField)
        {
            $this.closest('.custom-image-content-wrapper').find('.custom-image-upload').removeAttr('disabled');
            $(this).closest('.custom-image-content-wrapper').find('.custom-image-input-wrapper input.custom-image-upload').click();
        }
        else {
            $this.closest('.custom-image-content-wrapper').find('.error-message-custom-image').removeClass('hide');
        }        
    });


    // binf image remove button
    $(document).on("click", '.custom-image-content-wrapper .clear-image-preview', function (e) {
        clearCustomImagePreview($(this));
    });


    // clear custom image from preview and field
    var clearCustomImagePreview = function ($this)
    {       
        var $parent = $this.closest('.custom-image-content-wrapper');
        $parent.find('.custom-image-id').attr('value', '');
        $parent.find('.custom-image-version').attr('value', '');
        //$parent.find('.custom-image-upcharge').attr('value', '');
        $parent.find('.custom-image-upload').val('');
        $parent.find('.custom-image-wrapper img').remove();         
        $parent.find('.custom-image-wrapper .clear-image-preview').addClass('not-visible');            
        messaging.hotspotPreviewRequested.publish();
    }

    // clean up field option UI
    var PreviewGenerationComplete = function ()
    {
        $('.custom-image-content-wrapper.active .loading-gif').remove();
        $('.custom-image-content-wrapper.active .custom-image-preview').removeClass('hide');
        $('.custom-image-content-wrapper.active .custom-image-wrapper .clear-image-preview').removeClass('not-visible');
        $('.custom-image-content-wrapper.active .aoi-wrapper').removeClass('loading');
        $('.custom-image-content-wrapper.active').removeClass('active');
    }



    return {
        InitializeRecaptcha: InitializeRecaptcha,
        clearCustomImagePreview: clearCustomImagePreview,
        PreviewGenerationComplete: PreviewGenerationComplete,
    };
});     
// create the field option select element (ie user can choose between straight text, image, arched text).  basically it is just a fancy drop down
define('fieldOptionListing', ['jquery', 'viewData', 'messaging'], function ($, viewData, messaging) {

	// initializes and returns a select element to be added to the template form
    var createSelectElement = function (fieldID, fieldOptionLabel, validOptions, currentOption, fieldLabel) {
		// if the label isn't specified, default to 'Field Type'
        if (!fieldOptionLabel)
            fieldOptionLabel = 'Field Type';
		// initialize the basic elements of the field option select element
		var $dropDown = $('<div>').addClass('option-select-dropdown-container').attr('data-fieldid', fieldID);
        // this is the hidden input that holds the value of the field option selected
		var $input = $('<input>').attr('type', 'hidden').addClass('field-option-select').change(optionChanged);
		// this is the element that displays the currently selected field option
        var $selectedOption = $('<div>').addClass('field-option-select-button').click(openListingHandlerFactory(validOptions));
        $selectedOption.append();
		var $selectedOptionContent = $('<div>').addClass('selected-option-content');
		// initialize the starting value
        if (validOptions.length == 1) {
            $selectedOptionContent.html(validOptions[0].Label);
            // wait 300 milliseconds before applying the help text
            setTimeout(function () {
                setFieldOptionHelpText($dropDown, validOptions[0].HelpText);
            }, 300);
			$input.val(validOptions[0].UUID);
        }
		else if (validOptions.length > 1) {
            $selectedOptionContent.html('Select ' + fieldLabel + ' Field Option');
			// wait 300 milliseconds before applying the help text
			setTimeout(function () {
			//	setFieldOptionHelpText($dropDown, validOptions[0].HelpText);
			}, 300);
		}
		// if there is only one valid option then hide the field option select since it is just extra ui elements
        if (validOptions.length < 2)
        {
            $dropDown.addClass('hidden-field');	
        }			
		$selectedOption.append($selectedOptionContent);
        $selectedOption.append($('<div>').addClass('dropdown-icon'));
      
		// initialize and add each valid option
		var $options = $('<div>').addClass('options');

		if (validOptions.length > 1) {
			validOptions.forEach(function (option, index) {
				$options.append(createOption(option, index));
			});
        }
		
		// append all of the different elements to the main element
        $dropDown.append($input).append($selectedOption).append($options);
        $dropDown.after($('<div>').addClass('clearfix'));           
		return $dropDown;
	};

	// set the field option help text.  this might not be used anymore
    var setFieldOptionHelpText = function ($element, optionHelpText) {
        if (!optionHelpText)
            optionHelpText = '';
        var $field = $element.parents('.field');
        var $activeField = $field.find('.field-option.active-field-option');
        $activeField.find('.field-option-helptext').remove();
        var $fieldOptionHelpText = $('<div>').addClass('field-option-helptext').html(optionHelpText);       
        if (optionHelpText != '') {
            $activeField.prepend($fieldOptionHelpText);
            $fieldOptionHelpText.addClass('has-copy');
        }
	};

	// this method is a handler factory that is used to open the listing
	var openListingHandlerFactory = function (validOptions) {
		return function () {
			var $this = $(this).parents('.option-select-dropdown-container');
			// if this field option listing is read only (typically a build template for departments), then don't allow anyone to open the listing
			if ($this.hasClass('read-only'))
				return;
			// get the field id data from the this context element
			var fieldID = $this.attr('data-fieldid');
			// get the options element for the field options element
			var $options = $this.find('.options');
			// open the menu overlay and populate it with options
			var $menuOverlay = $('.menu-overlay');
            $menuOverlay.addClass('open');
            // add fixed class to body - needed for safari mobile
            $('body').addClass('fixed-body');
			// initialize the content for the menu overlay
			var $optionSelectContainer = $('<div>').addClass('field-option-select-container').attr('data-fieldid', fieldID);
			var $backDiv = $('<div>').addClass('cancel-container').html('Field Options');
			$backDiv.append($('<div>').addClass('cancel-link').html('X').click(cancelLinkClicked));
			$optionSelectContainer.append($backDiv);
			// retrieve the specific options and add the options to the menu overlay
			var $options = $this.find('.options .option');
			$options.each(function (index, element) {
				var option = validOptions[index];
				// using clone here so that the options info is just initialized once and copied to the menu overlay instead of initialized more than once
				var $option = $(element).clone().click(optionClickHandlerFactory(option));
				$optionSelectContainer.append($option);
			});
			// add the option select listing to the menu overlay
            $menuOverlay.append($optionSelectContainer);

            //update styling
            var currentSelectedFieldOption = $('.hotspot-view.open .field.open .field-option.active-field-option');
            if (currentSelectedFieldOption.length == 1) {
                var selectedFieldOptionId = currentSelectedFieldOption.attr('data-id');
                $('.menu-overlay.open .option[data-id="' + selectedFieldOptionId + '"] .image-wrapper').addClass('selected');
            }
			window.scrollTo(0, 0);
		};
	};

	// creates an option element and returns it
	var createOption = function (option, index) {
		// gets the appropriate image file for the option (pre formatted image that shows what the text looks like)
		var url = createOptionPreviewURL(option);
		// initialize the format element to be returned
		var $format = $('<div>').addClass('option').attr('data-id', option.UUID).attr('data-previewurl', url).attr('data-label', option.Label).attr('data-maxchars', option.MaxCharacters);
		// if the index is 0, then it is the first option and add a special class to the element
		if (index == 0)
            $format.addClass('first-option');

        // field option descriptive info and image
        var $imageWarpper = $('<div>').addClass('image-wrapper');
        //var $image = $('<img>').attr('src', url).on('error', function () {
        //    $(this).parent().html(option.Label);
        //});

		if(option.DefaultImagePath ){
			$imageWarpper.css('background-image', 'url(/images/uploads/' + option.DefaultImagePath + ')'); //$imageWarpper.css('background-image', 'url(' + url + ')');
		}

        var $contentWrapper = $('<div>').addClass('content-wrapper');
        var $label = $('<div>').addClass('field-option-label').html(option.Label);
        var $fieldOptionDescription = $('<div>').addClass('field-option-description').html(option.Description);
        $contentWrapper.append($label).append($fieldOptionDescription);
        $format.append($imageWarpper).append($contentWrapper);
        $format.append($('<div>').addClass('clearfix'));
		return $format;
	};

	// simple function to format the image that is used as a preview of image formatting
	var createOptionPreviewURL = function (option) {
		return '/Images/Cache/FieldOptionPreview_' + option.UUID + '.png';
	};

	// when the option changes, this method is called to display the image in the selected field option element
	var showSelectedFieldOption = function ($input) {
		// get the relevant info for the selected option
		var $field = $input.parents('.field');
		var id = $input.val();
		var $dropdown = $input.parents('.option-select-dropdown-container');
		var label = $dropdown.find('.options .option[data-id=' + id + ']').attr('data-label');			
        // clear the existing selected option content
        if (label != undefined) // needed for editing saved one-view builds.
        {
            $dropdown.find('.field-option-select-button .selected-option-content').empty().html(label);
        }
		var $activeOption = $field.find('.field-option[data-id=' + id + ']');
		// don't do anything if the previous option is the current option clicked
        if ($activeOption.hasClass('active-field-option')) {
            return;
        }

        // added for resposnive
        if ($activeOption.hasClass('required')) {
            $activeOption.closest('.field').find('.field-label-wrapper .field-requirement-type-label').html('(required)');
        } else {
            $activeOption.closest('.field').find('.field-label-wrapper .field-requirement-type-label').html('(optional)');
        }

		// copy current field option settings to the newly selected option settings
		var $previousActiveOption = $activeOption.siblings('.field-option.active-field-option');
		if ($previousActiveOption) {
			// get the values of the previously active option and save them to local vars
			var currentTextContent = $previousActiveOption.find('.field-option-text-control input').val();
			var currentTextColor = parseInt($previousActiveOption.find('.field-option-color-control.Color-field input').val() || -1);
			var currentTextOutlineColor = parseInt($previousActiveOption.find('.field-option-color-control.OutlineColor-field input').val() || -1);
			var currentTextShadowColor = parseInt($previousActiveOption.find('.field-option-color-control.ShadowColor-field input').val()) || -1;


			//set active field 
			$input.closest('.field-options').find('.customizable-item-wrapper').removeClass('active');
			$activeOption.find('.field-option-text-control .customizable-item-wrapper[data-buildid="' + viewData.BuildID + '"]').addClass('active');
			// set the text content and color values as the values of the new field option
			$activeOption.find('.field-option-text-control input').val(currentTextContent).change();
			if (currentTextColor != -1)
				$activeOption.find('.field-option-color-control.Color-field input').val(currentTextColor).change();
			if (currentTextOutlineColor != -1)
				$activeOption.find('.field-option-color-control.OutlineColor-field input').val(currentTextOutlineColor).change();
			if (currentTextShadowColor != -1)
				$activeOption.find('.field-option-color-control.ShadowColor-field input').val(currentTextShadowColor).change();
		}

		// cleanup the classes for the previously active field option and the new one
		$previousActiveOption.removeClass('active-field-option');
		$activeOption.addClass('active-field-option');              
		$field.removeClass('has-multiple-textformats');
		if ($activeOption.hasClass('has-multiple-textformats'))
            $field.addClass('has-multiple-textformats');

        // initialize active image field options (if field is of type of image)         
        messaging.initializeActiveImageField.publish();     
	};

	// option changed handler
	var optionChanged = function () {
		// since this functionality might get called in more than once place I made it into a separate function
		showSelectedFieldOption($(this));
		// the option has changed so the preview needs to get updated
        messaging.hotspotPreviewRequested.publish();
	};

	// click handler factory for when an option is clicked
	var optionClickHandlerFactory = function (option) {
		return function () {
			// set the data to local vars for the option clicked
            var $this = $(this);
			var $selectContainer = $this.parents('.field-option-select-container');
			var fieldID = $selectContainer.attr('data-fieldid');
			var $select = $('.template-form .field[data-id=' + fieldID + '] .option-select-dropdown-container');
			var uuid = $this.attr('data-id');
			// this input is hidden and is what is referenced when determining what option to display on the ui and the review pane
			$select.find('input').val(uuid).change();
			// set the help text for the field option to the new value
			setFieldOptionHelpText($select, option.HelpText);
			// close the menu overlay
			closeOverlay();
		};
	};

	// cancel link click handler
    var cancelLinkClicked = function () {
      
		closeOverlay();
	};

	// this method closes the menu overlay that appears on top of the form on the right side of the customer ui on top of the form that is normally there
    var closeOverlay = function () {
        $('body').removeClass('fixed-body');
		var $menuOverlay = $('.menu-overlay');
		$menuOverlay.empty().removeClass('open');
		window.scrollTo(0, 0);
	};

	return {
		createSelectElement: createSelectElement
	};
});
// very important module that might do too much and could use a refactoring or at least a rename
// reads the state of the hotspot form.  so when you select a hotspot template for a build (or are on a one view project)
// this module will read the values the user inputs and returns a javascript object
define('hotspotFormStateReader', ['jquery', 'menu', 'loadedTemplates', 'underscore', 'viewData'], function ($, menu, loadedTemplates, _, viewData) {
	
	// all the selectors for the form state reader
	//var menuSelector = '.menu';
    var designFormSelector = '.menu .hotspot-view.open .template-group.open .template-form';// '.menu .hotspot-view.open .design-form .template-group.open .template-form';
    var designFormTemplateFieldSelector = '.menu .hotspot-view.open  .template-group.open .template-form .field'; //'.menu .hotspot-view.open .design-form .template-group.open .template-form .field';
	var fieldSelector = '';

	// main method that reads the form state of the element provided (or tries to detect the open element)
	var readHotspotFormState = function ($element) {		
		var state = {
			//Mode: mode
		};		

        // if the element was provided, get the form state from the element, otherwise try to get the appropriate element based on the mode
        if ($element)
        {
            var $templateForm = $element;
            state.TemplateID = parseIntOrZero($templateForm.attr('data-templateID'));
            state.Fields = readAppledFormStateFields($templateForm, getLoadedTemplate(state.TemplateID));
            return state;
         
        }
        else {
            var $templateForm = $(designFormSelector);
            state.TemplateID = parseIntOrZero($templateForm.attr('data-templateID'));
            state.Fields = readOpenFormStateFields(getLoadedTemplate(state.TemplateID));
            return state;
        }
       
	};

	// the loadedTemplates module keeps the templates that the user selects in memory so they don't have to be loaded again
	var getLoadedTemplate = function (templateID) {
		if (templateID === 0)
			return null;
		return loadedTemplates.getTemplate(templateID);
    };

    // read the design form state fields using the selector, one field at a time
    var readOpenFormStateFields = function (template) {
        var fields = [];
        $(designFormTemplateFieldSelector).each(function (index, element) {
            var state = readTemplateFieldFormState($(element), template);
            if (state)
                fields.push(state);
        });
        return fields;
    };

    //  read the design form state fields using the selector, one field at a time
    var readAppledFormStateFields = function ($templateForm, template) {
        var fields = [];
        $templateForm.find('.field').each(function (index, element) {
            var state = readTemplateFieldFormState($(element), template);
            if (state)
                fields.push(state);
        });
        return fields;
    };


	// reads the settings from the template for a specific field			
	var readTemplateFieldFormState = function ($field, template) {
		// initialize the basic info for the form state
		var state = {};
		state.UUID = $field.attr('data-id');
        var $activeOption = $field.find('.active-field-option');
        
		state.VariationID = viewData.VariationID;
		state.VariationLabel = viewData.ProductName;
		state.VariationQuantity = viewData.Quantity;
		state.ActiveOptionUUID = $activeOption.attr('data-id');
		state.Type = parseIntOrZero($activeOption.attr('data-type'));
		state.Required = $activeOption.hasClass('required');
		// boolean that says whether the field state is valid
		// this is used in the review pane and other areas to decide if the content should be rendered in the preview or included in the subtotal
		state.ValidField = true;
		// an array of validation messages for the field.  these messages are unioned with other arrays of messages and presented to the user
		state.ValidationMessages = [];
		// this property are the classes that should be applied to this field when the validation is checked (in the case of an apply or add to cart click)
		state.ValidationClasses = '';
		state.MissingReplaceableColors = [];
		// gets a specific field from the template using the uuid
		var field = getFieldByUUID(template, state.UUID);
		// gets a specific field option from the template using the field uuid and the option uuid
		var fieldOption = getFieldOption(template, state.UUID, state.ActiveOptionUUID);
		// if the field option isn't found, just return
		if (!fieldOption)
			return;
		// the array of numbers here should be put into a module with other application constants
		// this array of numbers represents the option types that are text based
		if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(state.Type) != -1) {
			// clone state object without reference
			var stateClone = JSON.parse(JSON.stringify(state));
			state = readTemplateTextFieldFromState(fieldOption, field, stateClone, $field, $activeOption, $activeOption.find('.customizable-item-wrapper.active'));
			state.CustomizableItemFields = [];

			//Now that the base information has been collected in the stateclone variable, go ahead and loop over all variations and get their build info.  
			$activeOption.find('.customizable-item-wrapper').each(function () {
				// clone state object without reference
				var customizableItemStateClone = JSON.parse(JSON.stringify(stateClone));
				var customizableItemStateFull = readTemplateTextFieldFromState(fieldOption, field, customizableItemStateClone, $field, $activeOption, $(this));
				customizableItemStateFull.VariationID = parseInt($(this).attr('data-variationid'));
				customizableItemStateFull.VariationLabel = $(this).attr('data-label');
				customizableItemStateFull.VariationQuantity = $(this).attr('data-quantity');
				customizableItemStateFull.BuildID = $(this).attr('data-buildid');
				customizableItemStateFull.CustomizableItemFields = [];
				state.CustomizableItemFields.push(customizableItemStateFull);
			});
			
		}
		// this is the image type
		else if (state.Type == 1) {
			// get the basic info for the image field
			var $input = $activeOption.find('.Image-field input');
			state.ImageID = parseIntOrZero($input.val());
			state.ImageSKU = $input.attr('data-sku');
			state.Price = parseFloatOrZero($input.attr('data-price'));
			// this is for processing the replaceable colors, if any, for the image
			var $replaceableColors = $activeOption.find('.Image-field .field-color-controls .field-option-color-control');
			state.ReplaceableColors = [];
			// this is validation logic for the image field selection
			if (state.Required && (state.ImageID === 0)) {
				state.ValidField = false;
				state.ValidationClasses += 'incomplete-field ';
				state.ValidationMessages.push(field.Label + ':  No Image Selected');
			}
			// loop over each replaceable color and add it to the form state, and also validate it
			$replaceableColors.each(function (index, element) {
				var $element = $(element);
				var $colorInput = $element.find('input[type=hidden]');
				// initialize the replaceable color javascript object
				var replaceableColor = {
					ID: parseInt($element.attr('data-replaceableid')),
					Label: $element.attr('data-label'),
					ColorID: parseIntOrZero($colorInput.val()),
                    Required: $element.hasClass('required'),
                    ImageGroupColorID: $(element).find('.color-option-border.selected').attr('data-group-id'),
                };

                if (replaceableColor.ImageGroupColorID == null)
                {
                    replaceableColor.ImageGroupColorID = $(element).find('.color-option-border[data-id="' + replaceableColor.ColorID + '"]').attr('data-group-id');
                }

                // this is validation logic for specific replaceable colors
                if (replaceableColor.Required && (replaceableColor.ColorID === 0)) {
					state.ValidField = false;
					var validationLabel = replaceableColor.Label;
					// this bit checks to see if the replaceable color already has color in its name so that it doesn't double up in the validation message
					if (validationLabel.toLowerCase().indexOf('color') === -1)
						validationLabel += ' Color';
					state.ValidationMessages.push(field.Label + ':  ' + validationLabel + ' is Required');
					state.MissingReplaceableColors.push(replaceableColor.ID);
				}
				state.ReplaceableColors.push(replaceableColor);
			});
		}
		// custom select type
		else if (state.Type === 11)
		{
			var $options = $activeOption.find('.CustomSelectOptions-field');
			state.SelectedOptions = [];
			state.TotalSelectableOptions = $options.find('input').length;
			$options.find('input:checked').each(function (index, element) {
				var $element = $(element);
				state.SelectedOptions.push({
					Label: $element.attr('data-label'),
					Sku: $element.attr('data-sku'),
					Price: parseFloatOrZero($element.attr('data-price'))
				});
			});
			// this is validation logic for the custom select field selection
			if (state.Required && (state.SelectedOptions.length === 0)) {
				state.ValidField = false;
				state.ValidationClasses += 'incomplete-field ';
				state.ValidationMessages.push(field.Label + ':  No Options Selected');
			}
        }
        // custom image type
        else if (state.Type === 12) {
            var customImageObj = {
                CloudinaryId: $activeOption.find('input.custom-image-id').val(),
                Price: ($.isNumeric(parseFloat($activeOption.find('#Custom-Image-Upcharge').val())) ? parseFloat($activeOption.find('#Custom-Image-Upcharge').val()) : 0.00),
                Version: $activeOption.find('input.custom-image-version').val(),
                CustomChargeID: $activeOption.find('input.custom-charge-id').val(),
                SKU: $activeOption.find('input.custom-charge-sku').val(),
                Type: $activeOption.find('input.custom-image-type').val(),
                UseImagga: $activeOption.find('.aoi-buttons-wrapper .aoi-button.selected').hasClass('on') ? true : false,
            }
            state.CustomImage = customImageObj;

            // this is validation logic for the custom image field selection
            if (state.Required && state.CustomImage.CloudinaryId == '')
            {
                state.ValidField = false;
                state.ValidationClasses += 'incomplete-field ';
                state.ValidationMessages.push(field.Label + ':  Image is Required');
            }           
		}
		// salesperson/NS
		else if (state.Type === 15) {
			//var $options = $activeOption.find('.CustomSelectOptions-field');
			state.NetSuiteSalesPersonData = {};
			state.NetSuiteSalesPersonData.Sku1 = $activeOption.find('input.NS-Sku1-Hidden').val();
			state.NetSuiteSalesPersonData.Sku1Price = parseFloat($activeOption.find('input.NS-Sku1-Price-Hidden').val()).toFixed(2);
			state.NetSuiteSalesPersonData.Sku1Description = $activeOption.find('.NS-Sku1-Description-Hidden').html();
			state.NetSuiteSalesPersonData.Sku1Cost = $activeOption.find('.NS-Sku1-Cost-Hidden').val();


			// this is validation logic 
			if (state.Required && (state.NetSuiteSalesPersonData.Sku1 == '')) {
				state.ValidField = false;
				state.ValidationClasses += 'incomplete-field ';
				state.ValidationMessages.push('NetSuite Sku is a required field');
			}
			// this is validation logic 
			if (state.Required && isNaN(state.NetSuiteSalesPersonData.Sku1Price)) {
				state.ValidField = false;
				state.ValidationClasses += 'incomplete-field ';
				state.ValidationMessages.push('NetSuite Sku Price is a required field');
			}
			// this is validation logic 
			if (state.Required && (state.NetSuiteSalesPersonData.Sku1Description == '')) {
				state.ValidField = false;
				state.ValidationClasses += 'incomplete-field ';
				state.ValidationMessages.push('NetSuite Description is a required field');
			}	


			if ($activeOption.find('#NetSuite-Sku2-Option').is(':checked')) {

				state.NetSuiteSalesPersonData.Sku2 = $activeOption.find('input.NS-Sku2-Hidden').val();
				state.NetSuiteSalesPersonData.Sku2Price = parseFloat($activeOption.find('input.NS-Sku2-Price-Hidden').val()).toFixed(2);
				state.NetSuiteSalesPersonData.Sku2Description = $activeOption.find('.NS-Sku2-Description-Hidden').html();
				state.NetSuiteSalesPersonData.Sku2Cost = $activeOption.find('.NS-Sku2-Cost-Hidden').val();

				// this is validation logic 
				if (state.NetSuiteSalesPersonData.Sku2 == '') {
					state.ValidField = false;
					state.ValidationClasses += 'incomplete-field ';
					state.ValidationMessages.push('NetSuite Sku2 is a required field');
				}
				// this is validation logic 
				if (isNaN(state.NetSuiteSalesPersonData.Sku2Price)) {
					state.ValidField = false;
					state.ValidationClasses += 'incomplete-field ';
					state.ValidationMessages.push('NetSuite Sku2 Price is a required field');
				}
				// this is validation logic 
				if (state.NetSuiteSalesPersonData.Sku2Description == '') {
					state.ValidField = false;
					state.ValidationClasses += 'incomplete-field ';
					state.ValidationMessages.push('NetSuite Description2 is a required field');
				}
			}
			else {
				state.NetSuiteSalesPersonData.Sku2 = null;
				state.NetSuiteSalesPersonData.Sku2Price = 0;
				state.NetSuiteSalesPersonData.Sku2Description = null;
				state.NetSuiteSalesPersonData.Sku2Cost = 0;
            }

		}

		// return the field state
		return state;
    };


	var readTemplateTextFieldFromState = function (fieldOption, field, state, $field, $activeOption, $activeVariation) {
		state.Text = $activeVariation.find('input.field-option-text-input').val() == undefined ? '' : $activeVariation.find('input.field-option-text-input').val();  //$activeOption.find('.Text-field input').val(); 
		var $textFormatSelect = $activeOption.find('.text-format-select');
		state.TextFormatUUID = $textFormatSelect.val();
		state.TextFormatLabel = $textFormatSelect.attr('data-formatlabel');
		state.TextFormatAddedCost = parseFloat($textFormatSelect.attr('data-addedcost'));			
		state.TextFormatPerCharacterCost = parseFloat($textFormatSelect.attr('data-percharcost'));
		state.PerCharacterCost = parseIntOrZero($activeOption.attr('data-percharcost'));
		// no fill color is a checkbox and says whether there should be no fill color for the field, if allowed (so it would be shadow and / or outline)
		state.NoFillColor = $activeOption.find('.Color-field input[type=checkbox]').prop('checked');
		if (!state.NoFillColor)
			state.NoFillColor = false;
		state.ColorID = parseIntOrZero($activeOption.find('.Color-field input[type=hidden]').val());			
		state.OutlineColorID = parseIntOrZero($activeOption.find('.OutlineColor-field input').val());
		state.ShadowColorID = parseIntOrZero($activeOption.find('.ShadowColor-field input').val());
		state.ColorGroupColorID = parseIntOrZero($activeOption.find('.Color-field .color-field-container .color-option-border.selected').attr('data-group-id'));
		state.OutlineGroupColorID = parseIntOrZero($activeOption.find('.OutlineColor-field .color-field-container .color-option-border.selected').attr('data-group-id'));
		state.ShadowGroupColorID = parseIntOrZero($activeOption.find('.ShadowColor-field .color-field-container .color-option-border.selected').attr('data-group-id'));
		state.AllowFontChoice = $activeOption.find('.lock-font-wrapper .lock-font').is(':checked') ? true : false;
		state.AdminImageTextUpcharge = parseFloatOrZero($activeOption.attr('data-adminImageTextCharge'));
		state.FieldOptionPrice = parseFloatOrZero($activeOption.attr('data-fieldOptionPrice'));
		state.VariationID = parseInt($activeVariation.attr('data-variationid'));
		if (isNaN(state.VariationID))
			state.VariationID = viewData.VariationID;

		// this is validation logic for the text
		if (state.Required && (state.Text.trim() === '')) {
			state.ValidField = false;
			state.ValidationClasses += 'incomplete-field ';
			state.ValidationMessages.push(field.Label + ':  Missing Text');
		}
		// this is validation logic for the colors.  if the field isn't required and the user doesn't enter text, then this validation can be skipped
		if ((state.Text.trim() !== '') || (state.Required)) {
			if (fieldOption.FillColorRequired && (state.ColorID === 0)) {
				state.ValidField = false;
				state.ValidationClasses += 'fill-color-missing ';
				state.ValidationMessages.push(field.Label + ':  Fill Color is Required');
			}
			if (fieldOption.TextOutlineRequired && (state.OutlineColorID === 0)) {
				state.ValidField = false;
				state.ValidationClasses += 'outline-color-missing ';
				state.ValidationMessages.push(field.Label + ':  Outline Color is Required');
			}
			if (fieldOption.TextShadowRequired && (state.ShadowColorID === 0)) {
				state.ValidField = false;
				state.ValidationClasses += 'shadow-color-missing ';
				state.ValidationMessages.push(field.Label + ':  Shadow Color is Required');
			}
		}


		return state;
	};



	// the following couple functions could probably be placed in a dedicated module for template utilities
	// get a specific field option for a template using the field uuid and the field option uuid
	var getFieldOption = function (template, fieldUUID, fieldOptionUUID) {
		if ((!template) || (!template.Fields))
			return;
		return getFieldOptionByUUID(getFieldByUUID(template, fieldUUID), fieldOptionUUID);
	};

	// get a specific field for the template
	var getFieldByUUID = function (template, uuid) {
		if ((!template) || (!template.Fields))
			return;
		// this is an example of underscore js in action.  basically linq for javascript
		return _.find(template.Fields, function (field) { return field.UUID === uuid });
	};

	// get a specific field option for the template
	var getFieldOptionByUUID = function (field, uuid) {
		if ((!field) || (!field.FieldOptions))
			return;
		// this is an example of underscore js in action.  basically linq for javascript
		return _.find(field.FieldOptions, function (option) { return option.UUID === uuid });
	};

	// this is a utility function that will parse an integer value, and if it is NaN, then return a default value.  the default value is optional
	var parseIntOrZero = function (value, defaultValue) {
		if (!defaultValue)
			defaultValue = 0;
		var numberValue = parseInt(value);
		if (isNaN(numberValue))
			return defaultValue;
		return numberValue
	};

	// this is a utility function that will parse a float value, and if it is NaN, then return a default value.  the default value is optional
	var parseFloatOrZero = function (value, defaultValue) {
		if (!defaultValue)
			defaultValue = 0;
		var numberValue = parseFloat(value);
		if (isNaN(numberValue))
			return defaultValue;
		return numberValue
	};

	// sub function for when / if custom image uploads are implemented
	var readUploadFormState = function () {
		
	};

	return {
		readHotspotFormState: readHotspotFormState,
		readTemplateFieldFormState: readTemplateFieldFormState
	};
});
// another important module that handles the requesting, loading and rendering of preview images for hotspots
define('hotspotPreview',
    ['messaging', 'http', 'viewData', 'templateOptionsView', 'hotspotFormStateReader', 'menu', 'loadedTemplates', 'bottomBar'],
    function (messaging, http, viewData, templateOptionsView, hotspotFormStateReader, menu, loadedTemplates, bottomBar) {
	'use strict';

	// global vars for the module and constant dom selectors
	var projectUISelector = '.project-ui';
	var previewSelector = '.preview-container .preview';
	var zoomViewSelector = '.preview-container .zoom-view';
	var previewImageContainer = '.preview-container .zoom-preview-container';
	var currentHotspotSelector = '.preview-container .current-hotspot';
	var zoomOutLink = '.zoom-out-link';
	var showGuideLink = '.toggle-guide-link';
	var previewTimer = 0;
	var currentPerspective = {};
	var currentHotspot = {};
	var currentTemplate = {};
	var showGuide = false;
	var ajaxRequest = -1;
	var zoomedIn = false;

	// initialization function that subscribes to numerous pubsub topics and sets up some click handlers
	var init = function () {
		messaging.hotspotSelected.subscribe(hotspotSelected);
		messaging.hotspotPreviewRequested.subscribe(hotspotPreviewRequested);
		messaging.hotspotTemplateSelected.subscribe(hotspotTemplateSelected);
		messaging.applyPreview.subscribe(applyPreview);       
		$(zoomOutLink).click(zoomOutLinkClicked);
		$(showGuideLink).click(showGuideLinkClicked);
	};

	// zoom out link click handler
    var zoomOutLinkClicked = function () {
        $('.project-help-text').removeClass('hide');
		zoomedIn = false;
		$(previewSelector).removeClass('zoomed');
        $(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('perspective-mode');

        // update hotspot area UI
        var hotspotStepData = {
            currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
            nextStep: 0,
        }
        messaging.stepChange.publish(hotspotStepData);
        // needed to recalculate perspective areas
        $(window).resize();
        messaging.zoomedOut.publish();
	};

	// show preview guid link click handler
	var showGuideLinkClicked = function () {
		// this click handler is basically adds toggle functionality for field guides
		showGuide = !showGuide;
		var $showGuide = $(showGuideLink);
		if (showGuide) {
			// change the text of the link
			$showGuide.html('Hide Guide');
			bottomBar.guideDisabled();
		}
		else {
			// change the text of the link
			$showGuide.html('Show Guide');
			bottomBar.guideEnabled();
		}
		// request an updated hotspot preview
		hotspotPreviewRequested();
	};

	// hotspot selected handler
	var hotspotSelected = function (data) {
		// set private vars for this module
		zoomedIn = true;
		$(previewImageContainer).css('background-image', 'none');
		currentPerspective = data.perspective;
		currentHotspot = data.hotspot;
		// make the dom changes to zoom in the info
		$(previewSelector).removeClass('zoomed').addClass('zoomed');
		$(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('hotspot-mode');
		$(currentHotspotSelector).html('[ Currently Designing: ' + data.hotspot.Label + ' ]');
		// gets the currently loaded template
		var template = getCurrentTemplate();
		if (template == null)
			$(zoomViewSelector).removeClass('customizing');
		else
			$(zoomViewSelector).removeClass('customizing').addClass('customizing');
		// request an updated hotspot preview
        hotspotPreviewRequested();    


        // update hotspot area UI
        var hotspotStepData = {
            currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
            nextStep: parseInt($('.hotspot-view.open').attr('data-step')) + 1,
        }
        messaging.stepChange.publish(hotspotStepData);


        //populate the input of the disabled checkbox
        if (viewData.SavedBuild != null && viewData.SavedBuild.DisabledHotspots != null)
        {
            for (var i = 0; i < viewData.SavedBuild.DisabledHotspots.length; i++)
            {
                if (viewData.SavedBuild.DisabledHotspots[i] == data.hotspot.ID)
                {
                    $('.hotspot-view.open .department-prevent-alterations-wrapper .input-prevent-hotspot-alterations').prop('checked', true);
                }
            }
        }
	};

	// this is a typical function that keeps hotspot preview requests to a minimum
	var hotspotPreviewRequested = function () {
		// if the ajaxRequest object was saved, then abort it (doesn't always do anything)
		if (ajaxRequest != -1) {
			ajaxRequest.abort();
			ajaxRequest = -1;
		}
		clearTimeout(previewTimer);
		previewTimer = setTimeout(generatePreview, 750);
	};

	// hotspot template selected handler
	var hotspotTemplateSelected = function (template) {
		var $zoomView = $(zoomViewSelector);
		$zoomView.removeClass('customizing').addClass('customizing');
		showGuide = true;
	};

	
	// apply preview handler
	// this is just like the generatePreview method, except this one saves a preview out to disk
	var applyPreview = function () {
		// this posts the relevant data to generate a hotspot preview
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			PerspectiveID: currentPerspective.ID,
			HotspotID: currentHotspot.ID,
			BackgroundColor: viewData.ProductColor,
			Mode: menu.getCustomizationMode(),
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide,
			savePreview: true
		};
		http.post('/Build/Project/HotspotPreview', data).error(errorHandler);
	};

	// generate preview method
	// this calls the ajax endpoint to generate a preview
	var generatePreview = function () {
		if (!zoomedIn)
			return;
		// show the loading div at the top
		$(previewSelector).removeClass('loading').addClass('loading');
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			PerspectiveID: currentPerspective.ID,
			HotspotID: currentHotspot.ID,
			BackgroundColor: viewData.ProductColor,
			Mode: menu.getCustomizationMode(),
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide
		};
        ajaxRequest = http.post('/Build/Project/HotspotPreview', data).error(errorHandler).done(previewGenerated);

       
	};

	// error handler for image generation ajax calls
	var errorHandler = function () {
		$(previewSelector).removeClass('loading');
		//alert('An error occurred while generating the preview.  An error notification \nhas been sent.  Please check your settings for any issues.');
	};

	// handles the response from the hotspot preview action, which is a base 64 byte array of the preview image
	var previewGenerated = function (response) {
		var $zoomView = $(zoomViewSelector);
		var template = getCurrentTemplate();
		// need to come back to this code and figure out what it does
		if (template != null) {
			if (template.FieldGuideImage && (template.FieldGuideImage != '')) {
				$zoomView.removeClass('has-guide').addClass('has-guide');
				bottomBar.guideAvailable();
				var $showGuide = $(showGuideLink);
				if (showGuide) {
					$showGuide.html('Hide Guide');
					bottomBar.guideDisabled();
				}
				else {
					$showGuide.html('Show Guide');
					bottomBar.guideEnabled();
				}
			}
			else {
				$zoomView.removeClass('has-guide'); 
				bottomBar.guideUnavailable();
            }
		}
		else
			$zoomView.removeClass('has-guide');
		// apply the response to the preview div background
		$(previewSelector).removeClass('loading');
		var $previewImage = $(previewImageContainer);
		$previewImage.css('background-image', 'url(\'data:image/png;base64,' + response + '\')');

        // trick to make the main preview image take up the whole div as a background image - CSS trick
        var hiddenImageWrapper = $('<div>').addClass('preview-image-wrapper').css('visibility', 'hidden').css('opacity', 0);
        var hiddenImage = $('<img>').attr('src', 'data:image/png;base64,' + response);
        hiddenImageWrapper.append(hiddenImage);
        $previewImage.html(hiddenImageWrapper);

        ajaxRequest = -1;
	};

	// get the current perspective that is being used
	var getCurrentPerspective = function () {
		return currentPerspective;
	};

	// get the current hotspot that is being used
	var getCurrentHotspot = function () {
		return currentHotspot;
	};

	// get the current template that is being previewed
	var getCurrentTemplate = function () {
		var formState = hotspotFormStateReader.readHotspotFormState();
		var template = loadedTemplates.getTemplate(formState.TemplateID);
		return template;
	};

	return {
		init: init,
		getCurrentPerspective: getCurrentPerspective,
		getCurrentHotspot: getCurrentHotspot,
        getCurrentTemplate: getCurrentTemplate,
	};
});
// this module handles teh replaceable colors for images
define('imageColorFieldControl', ['jquery', 'messaging', 'commonColorClick', 'viewData'], function ($, messaging, commonColorClick, viewData) {
	'use strict';

	// initialize a replaceable color control instance
    var initializeControl = function (replaceableColor, availableColors, fieldID) {
		// set up the basic element info
		var $control = $('<div>').addClass('field-option-color-control field-option-image-color-control field-option-control').attr('data-replaceableid', replaceableColor.ID).attr('data-label', replaceableColor.Label).attr('data-type', 'image');
        if (replaceableColor.Required) {
            $control.addClass('required');
        }
        
        // field option label
        var $fieldLabel = $('<div>').addClass('field-option-label').html(replaceableColor.Label);
        if (replaceableColor.Label.toLowerCase().indexOf('color') < 0) {
            // append the word color if not there
            $fieldLabel.html(replaceableColor.Label + ' Color');
        }
        var $requiredIcon = $('<span>').addClass('required').html('*');
        $fieldLabel.append($requiredIcon);
        $control.append($fieldLabel);
        
		var $field = $('<div>').addClass('color-field-container');
		// input field where the color value will be stored for this repalceable color
		var $input = $('<input>').attr('type', 'hidden').addClass('replaceable-color').change(colorValueChanged);
		$field.append($input);
		// list the available repalceable colors
		availableColors.forEach(function (color, index) {
			// initialize an individual color option element
			var $option = $('<div>').addClass('color-option').css('background-color', '#' + color.HexadecimalCode).click(colorClicked);
			$option.append($('<div>').addClass('color-option-label callout bottom-left').html(color.Label));
            var $optionContainer = $('<div>').addClass('color-option-border').attr('data-id', color.ColorID).attr('data-group-id', color.ColorGroupIDs).attr('data-price', color.Price).append($option);
			$field.append($optionContainer);
		});
        $control.append($field);

        //hide color selection if part of common color group
        var commonColorGroups = viewData.CommonColorGroups;
        if (commonColorGroups!= null && commonColorGroups.length > 0)
        {                                                                                       
            for (var i = 0; i < commonColorGroups.length; i++)
            {
                for (var j = 0; j < commonColorGroups[i].FieldUUIDs.length; j++)
                {
                    if (commonColorGroups[i].FieldUUIDs[j] == fieldID) {
                        $control.addClass('hide');
                    }
                }                
            }
        } 


		return $control;
	};              

	// color changed handler        
	var colorValueChanged = function () {
		var $this = $(this);
		if (!$this.val())
			return;
		var $colorControl = $this.parents('.field-option-color-control');
		var $colorOptions = $colorControl.find('.color-option-border');
		$colorOptions.removeClass('selected');
		$colorControl.find('.color-option-border[data-id=' + $this.val() + ']').addClass('selected');
	};

	// color click handler
    var colorClicked = function () {      
        var $this = $(this).parent();

        // check if dummy color group color was clicked.  make proper adjustments if so
        var dummyColorControlClick = false;
        var dummyColorId = -1;
        if ($(this).closest('.field-option-color-control').hasClass('dummy'))
        {
            dummyColorId = parseInt($(this).closest('.color-option-border').attr('data-id'));
            dummyColorControlClick = true;
            var $dummyThis = $(this).parent();
            var dataReplaceableId = $(this).closest('.field-option-color-control').attr('data-replaceableid');
            $dummyThis.siblings('.selected').removeClass('selected');
            $dummyThis.toggleClass('selected');
            $this = $dummyThis.closest('.field-option.active-field-option').find('.field-option-image-control .field-option-color-control[data-replaceableid="' + dataReplaceableId + '"] .color-field-container .color-option-border[data-id="' + dummyColorId + '"]');
        }

                    
        var $field = $this.closest('.field-option-color-control');
        if ($this.closest('.field-option-image-control').hasClass('read-only') || $field.hasClass('read-only') || $this.hasClass('inactive'))
            return;

		// remove the selected class from the existing selected color
		$this.siblings('.selected').removeClass('selected');
		var $colorField = $this.parents('.color-field-container');
		// this allows the user to turn off a color they selected if they want
		$this.toggleClass('selected');
        var colorID = parseInt($colorField.find('.selected').attr('data-id'));
        if (dummyColorControlClick) {
            colorID = dummyColorId;
        }
        $colorField.find('input[type=hidden]').val(colorID);

          // common color click functions
        var commonColorGroupUUID = $this.closest('div.field').find('.CommonColorGroupUUID').html();
        commonColorClick.CommonColorGroup(commonColorGroupUUID, $field.attr('data-type'), colorID, $this.hasClass('selected'));
        commonColorClick.ColorLimitDisplay();  


        // display color price if exist
        var colorPrice = $this.attr('data-price');
        var fieldUUID = $this.closest('div.field').attr('data-id');
        var $colorMessageParent = $(this).closest('.color-field-container');
        if (colorPrice != null) {
            var colorLabel = $this.find('div.color-option-label').html();
            var $colorPriceMessageDiv = $('<div>').addClass('color-price-message').html(colorLabel + ' has an added cost of $' + colorPrice);
            $colorMessageParent.find('.color-price-message').remove();
            $colorMessageParent.append($colorPriceMessageDiv);
        }
        else {
            $colorMessageParent.find('.color-price-message').remove();
        }

        messaging.hotspotPreviewRequested.publish();
	};

	return {
		initializeControl: initializeControl
	};
});
// module for accessing the image data api for bisibi
define('imageData', ['http'], function (http) {

	// this gets the specified image categories and sub images, along with specially selected images
	var getImageOptions = function (imageIDs, categoryIDs) {
		var params = {
			imageIDs: imageIDs,
			categoryIDs: categoryIDs
		};
		return http.get('/api/images/imageoptions', params).fail(serviceError).then(processListResponse);
	};

	// get a specific image from the data api
	var getImage = function (imageID) {
		var params = {
			imageID: imageID
		};
		return http.get('/api/images/find', params).fail(serviceError).then(processFindResponse);
	};

	// handle errors
	var serviceError = function (error) {
		//alert(error);
	};

	// handle responses that contain a list of items
	var processListResponse = function (response) {
		return response.Data;
	};

	// handle responses that contain a single item
	var processFindResponse = function (response) {
		return response.Data;
	};

	return {
		getImageOptions: getImageOptions,
		getImage: getImage
	};
});
// this module handles the generation of the image category and image listing that appears in the modal window
define('imageListingGenerator', ['messaging', 'jquery', 'imageData'], function (messaging, $, imageData) {
	'use strict';

    

	// use the provided data to generate an listing element that can be used in a modal dialog
	var getImageOptionsListing = function (data) {
		var $listingContainer = $('<div>').addClass('images-listing-container');
		// loop over each category and add a category element
		data.forEach(function (category, categoryIndex, categories) {
			$listingContainer.append(createCategoryElement(category, data.length));
        });
        $listingContainer.append(createCategoryRowImageListingElement());
        $listingContainer.append($('<div>').addClass('clearfix'))
		return $listingContainer;
	};

	// create a basic category element
	var createCategoryElement = function (category, totalCategoryCount) {
        var $category = $('<div>').addClass('category col-sm-6 ').attr('data-id', category.ID).click(categoryClickHandlerFactory(category, totalCategoryCount));
        $category.append($('<div>').addClass('category-preview').css('background-image', 'url(' + category.PreviewImage + ')'));
		$category.append($('<div>').addClass('category-label').html(category.Label));		
		return $category;
	};

	// this is the row beneath each row of categories that contains the selected category images
	var createCategoryRowImageListingElement = function () {
        return $('<div>').addClass('category-row-images');
	};

	// create a click handler for a category
    var categoryClickHandlerFactory = function (category, totalCategoryCount) {
		return function () {
            var $this = $(this);
            var $parent = $this.closest('.images-listing-container');
            $parent.find('.category').addClass('hide');

            var x = $this.closest('.field-option.active-field-option').find('.Image-field');

			// if the category is selected, then remove the selected class and empty the associated image row
			if ($this.hasClass('selected')) {
				$this.removeClass('selected');
				$this.siblings('.category-row-images.open').empty().removeClass('open');
				return;
			}
			// clear the images in the category images row
			$this.siblings('.category-row-images.open').empty().removeClass('open');
			$this.removeClass('selected');
			// remove the selected class from any associated category sibling
			$this.siblings('.selected').removeClass('selected');
			$this.addClass('selected');
			// figure out which category row images to insert the images into
			var $rowImages = $this.siblings('.category-row-images'/*[data-row=' + row + ']'*/);
            $rowImages.addClass('open');

            // add back button to image category selection view
            if (totalCategoryCount > 1)
            {
                var $backToImageCategoryWrapper = $('<div>').addClass('back-to-image-categories-wrapper').click(backToImageCategories);
                var $arrow = $('<span>').addClass('arrow').append('<span class="arrow"><img src="/images/back-arrow.png"></span>');
                var fieldLabel = $this.closest('.field').find('.field-label-wrapper .field-label').html();
                var $fieldLabel = $('<span>').addClass('current-field-label').html('All ' + fieldLabel + ' Options');
                $backToImageCategoryWrapper.append($arrow).append($fieldLabel);
                var $currentImageCategory = $('<div>').addClass('current-image-category-label').html(category.Label + ' Options');
                //$parent.before($backToImageCategoryWrapper);
                x.after($backToImageCategoryWrapper);
                $backToImageCategoryWrapper.after($currentImageCategory);
            }           

			// add each category image to the category image row
			category.Images.forEach(function (image) {
				$rowImages.append(createCategoryImageElement(category, image));
			});
		};
	};


    // click handler to get back to image cateogires within a field option
    var backToImageCategories = function () {
        var $this = $(this);      
        $this.closest('.field-option.active-field-option').find('.images-listing-container .category-row-images').empty();
        $this.closest('.field-option.active-field-option').find('.images-listing-container .category').removeClass('hide selected');
        $this.closest('.field-option.active-field-option').find('.current-image-category-label').remove();
        $this.remove();
    }

	// create a category row image element
	var createCategoryImageElement = function (category, image) {
        var $imageContainer = $('<div>').addClass('image-container col-sm-6 col-md-4').click(applyButtonClickHandlerFactory(category, image));
		var $image = $('<div>').addClass('image').attr('data-id', image.ID);
        $image.append($('<div>').addClass('image-preview').css('background-image', 'url(' + image.Image + ')'));
		var $imageLabel = $('<div>').addClass('image-label-container');
        $imageLabel.append($('<span>').addClass('image-sku').html(image.FrontendLabel));
		$image.append($imageLabel);
		var price = parseFloatOrZero(image.Price);
		if (price > 0)
			$image.append($('<div>').addClass('image-price').html('$' + price.toFixed(2)));
		$imageContainer.append($image);
		return $imageContainer;
	};
	

    // remove selected image form hotspot template
    var clearImagePreview = function () {
        var $imageField = $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .Image-field');
        $imageField.find('input').val('').removeAttr('data-sku').removeAttr('data-price').change();
        $imageField.removeClass('has-image');
        // since the image is cleared, then the replaceable colors also need to be cleared
        var $colorFieldContainer = $imageField.find('.field-color-controls');
        $colorFieldContainer.empty();
        // remove active dummy color options
        $imageField.closest('.field-option.active-field-option').find('.field-option-image-color-control.dummy').remove();
    };


	// when the apply button for an image is clicked, then grab that image's data and use imageGrabbed to handle the response
	var applyButtonClickHandlerFactory = function (category, image) {
        return function () {    
            var $this = $(this)

            // for common department user
            if ($this.hasClass('read-only')) {
                return;
            }
            // if clicking on selected image than deselect it and remove hidden input values
            if ($this.find('.image-preview').hasClass('selected'))
            {
                clearImagePreview();
                $this.find('.image-preview').removeClass('selected');
                return;
            }
            // select a new image
            $this.closest('.category-row-images.open').find('.image-preview').removeClass('selected');
            $this.find('.image-preview').addClass('selected');
            //display image
			imageData.getImage(image.ID).done(imageGrabbed);
		};
    };


	// handles the response from the image api when the image is grabbed
	var imageGrabbed = function (image) {
		// publish that the image is selected, along with the image data
		messaging.imageSelected.publish(image);
	};

	// parses a value as a float, or returns 0 if the value is NaN
	var parseFloatOrZero = function (value) {
		var numericValue = parseFloat(value);
		if (isNaN(numericValue))
			return 0;
		return numericValue;
	};

	return {
		getImageOptionsListing: getImageOptionsListing
	};
});
// simple module that handles opening the image selection modal window
define('imageOptionsView',
    ['modalView', 'imageData', 'imageListingGenerator', 'viewData', 'underscore'], function 
        (modalView, imageData, imageListingGenerator, viewData, _) {

	// calls the image data api and gets the entities for the provided image and category ids
	var openImageOptionsView = function (imageIDs, categoryIDs) {
		imageData.getImageOptions(imageIDs, categoryIDs).done(imagesGrabbed);
	};

	// handler when the image option data has been grabbed
    var imagesGrabbed = function (data) {

        // needed for saved builds.  looping through the saved build options could add multiple image list containers
        if ($('.hotspot-view.open .template-group.open .field.open .field-option.active-field-option .images-listing-container').length != 0) {
            return;
        }

		// generate the option listing with the data
		var $viewContent = imageListingGenerator.getImageOptionsListing(data);
		// append the new html to the active image filed option
        $('.hotspot-view.open .template-group.open .field.open .field-option.active-field-option .field-option-image-control').after($viewContent);  
		// this checks to see if there is only one category displayed, and if so, then applies a class that make sthe modal window just display the images
        var $imageCategories = $('.hotspot-view.open .template-group.open .field.open .field-option.active-field-option .images-listing-container .category');           
		if ($imageCategories.length == 1) {
			$imageCategories.parent().addClass('one-category-group');
			$imageCategories.click();
        }

        // add required icon if needed
        var $activeFieldOption = $('.hotspot-view.open  .template-group.open  .field.open .field-options .field-option.active-field-option');
        if ($activeFieldOption.hasClass('required')) {
            $($activeFieldOption.find('.images-listing-container').before($('<div>').addClass('required-icon').html('*')))
        }


        // preselect images for saved builds.  This is where that added class 'saved-build-first-view' comes into play.  This block of code should only be hit once when user gets to a field the first time (of a saved build)
        if ($('.hotspot-view.open  .template-group.open  .field.open').hasClass('saved-build-first-view')){
            var fieldId = $('.hotspot-view.open  .template-group.open  .field.open').attr('data-id');
            var templateGroupID = parseInt($('.hotspot-view.open  .template-group.open').attr('data-id'));
            // get saved field values
            var hotspot = _.findWhere(viewData.SavedBuild.Hotspots, { TemplateGroupID: templateGroupID });
            var field = _.findWhere(hotspot.Fields, { UUID: fieldId})            

            // if more than 1 image category than find and select the correct one
            if (data.length > 1) {              
                var imageCategory = _.filter(data, function (r) { for (var i = 0; i < r.Images.length; i++) { if (r.Images[i].ID === field.ImageID) { return r.ID; } } }); 
                $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .images-listing-container .category[data-id="' + imageCategory[0].ID + '"]').click();
            }
            var $selectedImage = $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .images-listing-container .category-row-images .image-container .image[data-id="' + field.ImageID + '"] .image-preview').addClass('selected');
            var $imageContainer = $selectedImage.closest('.image-container').addClass('selected');
            // clone image container and move it to the front of the list of images within an image category
            var $clone = $imageContainer.clone(true, true);
            $imageContainer.remove();
            $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .images-listing-container .category-row-images.open ').prepend($clone);
            $('.hotspot-view.open  .template-group.open  .field.open').removeClass('saved-build-first-view');

            // if department build for common user than remove non-selected images
            if (viewData.SavedBuild != null && viewData.SavedBuild.BuildTemplateID > 0 && !viewData.IsDepartmentManagerEnvironment) {
                var otherImages = $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .images-listing-container .category-row-images .image-container').not('.selected').remove();
                $clone.addClass('read-only');          
                // remove back to image category option
                $('.hotspot-view.open  .template-group.open  .field.open .field-option.active-field-option .back-to-image-categories-wrapper').remove();
            }
        }
	};


	return {
		openImageOptionsView: openImageOptionsView,
	};
});
// module that renders image type controls
define('imageSelectorFieldControl', ['jquery', 'http', 'modalView', 'uuid', 'messaging', 'imageOptionsView', 'imageData', 'imageColorFieldControl', 'commonColorClick', 'viewData', 'underscore'], function ($, http, modalView, uuid, messaging, imageOptionsView, imageData, imageColorFieldControl, commonColorClick, viewData, _) {

		// private variables for the module         
		var savedAvailableReplacementColors = [];

		// init function that subscribes to a few topics
		var init = function () {
			messaging.imageSelected.subscribe(imageSelected);
            messaging.initializeActiveImageField.subscribe(initializeActiveImageField);
        };

   

		// this method initializes a image selection control
		var initializeControl = function (imageIDs, categoryIDs, availableReplacementColors, field) {
			// default null image ids array to an empty array
			if (!imageIDs)
				imageIDs = [];
			// default null category ids array to an empty array
			if (!categoryIDs)           
				categoryIDs = [];
			// create the control
            var thisControlUUID = uuid.generateUUID();
            var $control = $('<div>').addClass('field-option-image-control field-option-control Image-field').attr('data-uuid', thisControlUUID);
			// if there is exactly one image to be displayed, then the control should be read only and hidden, if possible
			if ((imageIDs.length === 1) && (categoryIDs.length === 0))
				$control.addClass('read-only-image-control');
			// initialize the hidden field to hold the selected image id
			var $input = $('<input>').attr('type', 'hidden').change(changeHandler);
            $control.append($input);
            var $dataDiv = $('<div>').addClass('hide image-data').attr('data-imageids', arrayToCSVString(imageIDs)).attr('data-categoryids', arrayToCSVString(categoryIDs));
            $control.append($dataDiv);

			// these are preview image controls
			$control.append($('<div>').addClass('image-preview'));
			$control.append($('<textarea>').addClass('hidden').html(JSON.stringify(availableReplacementColors)));
            $control.append($('<div>').addClass('clear-image-preview'));
			$control.append($('<div>').addClass('field-color-controls'));
			// if there is exactly one image id, then the control should be read only and hidden, if possible
			if ((imageIDs.length == 1) && (categoryIDs.length == 0)) {          
				$control.addClass('readonly-image').attr('data-imageid', imageIDs[0]);
				$input.val(imageIDs[0]);
                imageData.getImage(imageIDs[0]).done(function (image) {                             
					applySelectedImageToField($control, image);
                });              
            }

            // apply the saved replacable colors to saved builds.  Only do for multi-view projects.  oneview projects are being handled in oneViewInitializer.js initializeForm();
            if (viewData.SavedBuild != null && !viewData.OneViewProject)
            {
                var selectedField = '';
                var isSet = false;
                for (var i = 0; i < viewData.SavedBuild.Hotspots.length; i++)               
                {
                    for (var j = 0; j < viewData.SavedBuild.Hotspots[i].Fields.length; j++) {
                        if (viewData.SavedBuild.Hotspots[i].Fields[j].UUID == field.UUID && !isSet && viewData.SavedBuild.Hotspots[i].Fields[j].ImageSelected == null ) {
                            selectedField = viewData.SavedBuild.Hotspots[i].Fields[j];
                            viewData.SavedBuild.Hotspots[i].Fields[j].ImageSelected = true; // add a variable at run time.  Need in case same hotspot and same replacable image is used in the same build
                            isSet = true;
                            break;
                        }
                    }
                }

                imageData.getImage(selectedField.ImageID).done(function (image) {
                    // apply the selected image to the field
                    applySelectedImageToField($control, image);             
                    // loop over each replaced color and initialize the value
                    selectedField.ReplacedColors.forEach(function (replacedColor) {
                        var replaceableColorID = replacedColor.ID;
                        var colorID = replacedColor.ColorID;   
                        $('.field-option[data-id=' + selectedField.ActiveOptionUUID + '] .field-option-image-control[data-uuid=' + thisControlUUID + ']  .field-option-image-color-control[data-replaceableid=' + replaceableColorID + '] input[type=hidden]').val(colorID).change();
                    });
                });         
            }
            else {
               // alert('new build')  
            }
			return $control;
		};

    

        // update the settings of an active field with an active field option.  This is the responsive update to the old modal window
        var initializeActiveImageField = function ()
        {
            var $activeFieldOption = $('.hotspot-view.open  .template-group.open  .field.open .field-options .field-option.active-field-option');
            // if image field && field option image has already been "activated" and not a read-only-image-control (field option with just 1 possible image - like the maltese)
            if (parseInt($activeFieldOption.attr('data-type')) == 1 && $activeFieldOption.find('.images-listing-container').length == 0 && !$activeFieldOption.find('.Image-field').hasClass('read-only-image-control'))
            {
                var imageIDs = $activeFieldOption.find('.image-data').attr('data-imageids');
                var categoryIDs = $activeFieldOption.find('.image-data').attr('data-categoryids');          
                // open the image listing modal window with the specified image and category ids
                imageOptionsView.openImageOptionsView(imageIDs, categoryIDs);               
            }
        }


		// image selected handler
		var imageSelected = function (image) {
            var $imageField = $('.hotspot-view.open .template-group.open .field.open  .field-option.active-field-option .Image-field').addClass('has-image');  //      $('.Image-field[data-uuid=' + viewOpenedForUUID + ']').addClass('has-image');
			// this method is called in more than once place, so it was made into a method
			applySelectedImageToField($imageField, image);
		};

		// method to handle applying a selected image to the field
        var applySelectedImageToField = function ($field, image) {
			// set the value for the image hidden field, along with associated data like sku and price
			$field.find('input').val(image.ID).attr('data-sku', image.Sku).attr('data-price', image.Price).change();
			var availableReplacementColors = JSON.parse($field.find('textarea').html());
			// this code handles the replacement colors, if any, for this image
			var $colorFieldContainer = $field.find('.field-color-controls');
            $colorFieldContainer.empty();
            // remove active dummy color options
            $field.closest('.field-option.active-field-option').find('.field-option-image-color-control.dummy').remove();

            // add image control field for color changing images
            if (image.ColorReplacementsEnabled)
            {              
				if (image.ReplaceableColors.length > 0)
				{
					// if the field is hidden and has replaceable colors, then the image should be shown
					$colorFieldContainer.parents('.field.hidden-field').removeClass('hidden-field');
                }

                // loop over each replaceable color part of this image and add it to the element
                var fieldId = $field.closest('div.field').attr('data-id');
                for (var i = 0; i < image.ReplaceableColors.length; i++)
                {                   
                    var $selectedImage =  $field.closest('.field-options').find('.category-row-images .image-container .image-preview.selected');
                    var $selectedImageContainer = $selectedImage.closest('.image-container');
                    var selectedImageContainerIndex = $selectedImageContainer.index();
                    var totalCategoryImages = $selectedImage.closest('.category-row-images.open').find('.image-container').length;


                    // add dummy color controls for tablet/desktop
                    if ($(window).width() >= 769 && $(window).width() <= 991)
                    {
                        // tablet and 2 columns                       
                        if (selectedImageContainerIndex % 2 == 0) {
                            $selectedImageContainer.next().after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                        }
                        else {
                            $selectedImageContainer.after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                        }                      
                    }
                    else if ($(window).width() >= 992)
                    {
                       // desktop and 3 columns
                        if (selectedImageContainerIndex % 3 == 0) {
                            if (totalCategoryImages < 3 || selectedImageContainerIndex == totalCategoryImages - 1) {
                                $selectedImageContainer.after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                            } else if (selectedImageContainerIndex == totalCategoryImages - 2){
                                $selectedImageContainer.next().after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                            } else {
                                $selectedImageContainer.next().next().after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                            }
                        }
                        else if (selectedImageContainerIndex % 3 == 1) {
                            if (totalCategoryImages < 3 || selectedImageContainerIndex == totalCategoryImages - 1) {
                                $selectedImageContainer.after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                            } else {
                                $selectedImageContainer.next().after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                            }
                        } 
                        else if (selectedImageContainerIndex % 3 == 2) {
                            $selectedImageContainer.after(imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId).addClass('dummy'));
                        } 
                    }

                    // main color control
                    var $imageColorControl = imageColorFieldControl.initializeControl(image.ReplaceableColors[i], availableReplacementColors, fieldId);
                    if ($(window).width() >= 769 && $field.closest('.field-option.active-field-option').find('.images-listing-container').length > 0) {
                        $imageColorControl.addClass('hide');
                    }
                    $colorFieldContainer.append($imageColorControl);
                }


                messaging.hotspotPreviewRequested.publish();
                commonColorClick.ColorLimitDisplay();
			}
		};
    
		// handler function for when the selected image id (in the hidden field) changes
		var changeHandler = function () {
			var $this = $(this);
			var $imageField = $this.parents('.Image-field');
			var $inputField = $imageField.find('input');
			// get the selected image id
			var id = $imageField.find('input').val();
            // if the id exists, then get the data from the image data api and update the preview image thumbnail
            if (id && $imageField.closest('.field').find('.images-listing-container').length == 0) {
				imageData.getImage(id).done(function (data) {
					$imageField.find('.image-preview').css('background-image', 'url(' + data.Image + ')');
					// initialize the image sku and price for the review pane
					$inputField.attr('data-sku', data.Sku).attr('data-price', data.Price);
				});
				$imageField.addClass('has-image');
            }
            else {
                $imageField.find('.image-preview').addClass('hide');
            }
			// request a new preview
			messaging.hotspotPreviewRequested.publish();
		};

		// another utility function that should be called from a utility module or added to such a module if it doesn't exist yet.  
		var arrayToCSVString = function (array) {
			var csv = '';
			for (var i = 0; i < array.length; i++) {
				if (csv != '')
					csv += ',';
				csv += array[i];
			}
			return csv;
		};

      

		return {
			init: init,
			initializeControl: initializeControl,
            applySelectedImageToField: applySelectedImageToField,
		};
	});
// simple helper module that maintains an in-memory storage of hotspot templates for easy retrieval without hitting the data api again
define('loadedTemplates', ['messaging'], function (messaging) {
	
	// where the loaded templates are stored
	var templates = [];

	// init function for subscribing to a pubsub topic
	var init = function () {
		messaging.hotspotTemplateSelected.subscribe(hotspotTemplateSelected);
	};

	// when a hotspot template is selected, use the published data and store it in memory
	var hotspotTemplateSelected = function (template) {
		loadTemplate(template);
	};

	// load the template into the in-memory storage
	var loadTemplate = function (template) {
		// check to make sure the template isn't already stored
		for (var i = 0; i < templates.length; i++) {
			if (templates[i].ID === template.ID)
				return;             
		}   
		// the template isn't stored yet, so store it
		templates.push(template);
	};

	// finds a template from the in-memory storage and returns it, or returns null if it isn't found
	var getTemplate = function (id) {
		for (var i = 0; i < templates.length; i++) {
			if (templates[i].ID === id)
				return templates[i];   
		}
		return null;
	};

	return {
		init: init,
		getTemplate: getTemplate,
		loadTemplate: loadTemplate
	};
});
// this module handles some of the menu options that don't clearly fall into the functionality of other modules
define('menu', ['messaging', 'jquery', 'bottomBar'], function (messaging, $, bottomBar) {
	'use strict';

	// module private variables
	var menuSelector = '.menu';

	// init function for subscribing to topics and setting up a click event handler for when a tab is clicked
	var init = function () {
		messaging.hotspotSelected.subscribe(hotspotSelected);
		messaging.zoomedOut.subscribe(zoomedOut);

		$('.menu-tab .menu-label').click(tabClicked);
	};

	// when teh hotspot is selcted, then "zoom in" the view to the associated hotspot view
	var hotspotSelected = function (data) {
		var hotspot = data.hotspot;
		$('.hotspot-view.open').removeClass('open');
		// find the hotspot that needs to be viewed and add the open class to it
		var $hotspot = $('.hotspot-view[data-id=' + hotspot.ID + ']');	                
		$hotspot.addClass('open');
		// this is for when the customer is building from an existing template, in which case the clear button shouldn't be accessible
		if ($hotspot.hasClass('build-template-related'))
			bottomBar.clearDisabled();          
	};

	// when the customer chooses to zoom out, then remove the open class from the hotspot view
	var zoomedOut = function () {
		$('.hotspot-view.open').removeClass('open');
		// since we are zoomed out, then the clear button doesn't have a use in this context since you are then looking at a perspective
		bottomBar.clearEnabled();           
	};

	// tab click handler.  this functionality should probably be reviewed with Becca so that you can determine if the tabs are useful anymore
	var tabClicked = function () {
		var $this = $(this);
		var $parent = $this.parents('.menu-tab');
		if ($parent.hasClass('locked-out'))
            return;

		$parent.siblings('.open').removeClass('open');
		$parent.removeClass('open').addClass('open');
        messaging.hotspotPreviewRequested.publish();


        // auto select template group option if parameters are met.
        var menuTabs = $('.hotspot-view.open  .menu-tab');
        var templateGroups = $('.hotspot-view.open  .menu-tab.open .menu-tab-content .template-group');
        var fields = $('.hotspot-view.open  .menu-tab.open .menu-tab-content .template-group .field');
        if (templateGroups.length == 1 && fields.length == 0 && menuTabs.length > 1) {
            $('.hotspot-view.open  .menu-tab.open .menu-tab-content .template-group .select-template-link').click();
            //alert('2 or more menu');
        }
	};

	// get the customization mode based on the currently open tab.  in most cases, this will just be the design tab
	var getCustomizationMode = function () {
		var $hotspot = $('.hotspot-view.open');
		var $openTab = $hotspot.find('.menu-tab.open');
		var currentCustomizationMode = '';
		if ($openTab.hasClass('design'))
			currentCustomizationMode = 'design';
		else if ($openTab.hasClass('text'))
			currentCustomizationMode = 'text';
		else if ($openTab.hasClass('upload'))
			currentCustomizationMode = 'upload';
		return currentCustomizationMode;
	};

	return {
		init: init,
		getCustomizationMode: getCustomizationMode
	};
});
// this is the module that provides a unified api for plugging into pub sub topics
define('messaging', ['messagingFactory'], function (messagingFactory) {
	'use strict';

	// the messaging factory just creates a somewhat cleaner access point for a topic in pubsub
	// example messaging.formChanged.publish() instead of pubsub.publish('formChanged')
	// the topic string could change by typo or regression, so it is better to have it tied to a module's exposed properties
	var extracts = {
		perspectiveChanged: messagingFactory.createChannel('perspectiveChanged'),
		hotspotSelected: messagingFactory.createChannel('hotspotSelected'),
		hotspotPreviewRequested: messagingFactory.createChannel('hotspotPreviewRequested'),
		hotspotTemplateSelected: messagingFactory.createChannel('hotspotTemplateSelected'),
		hotspotTemplateCanceled: messagingFactory.createChannel('hotspotTemplateCanceled'),
		formChanged: messagingFactory.createChannel('formChanged'),
		hotspotModeChanged: messagingFactory.createChannel('hotspotModeChanged'),					
		zoomedIn: messagingFactory.createChannel('zoomedIn'),
		zoomedOut: messagingFactory.createChannel('zoomedOut'),
		applyButtonClicked: messagingFactory.createChannel('applyButtonClicked'),
		reviewUpdateRequested: messagingFactory.createChannel('reviewUpdateRequested'),
		perspectivePreviewReady: messagingFactory.createChannel('perspectivePreviewReady'),
		imageSelected: messagingFactory.createChannel('imageSelected'),
		imageCanceled: messagingFactory.createChannel('imageCanceled'),
		applyPreview: messagingFactory.createChannel('applyPreview'),
        addToCartButtonClicked: messagingFactory.createChannel('addToCartButtonClicked'),
        stepChange: messagingFactory.createChannel('stepChange'),
        initializeActiveImageField: messagingFactory.createChannel('initializeActiveImageField'),
	};
                    
	return extracts;
});
// this module is just a wrapper function for the dialog common module
define('modalView', ['dialog'], function (dialog) {
	'use strict';

	// by default, open a modal window at 90% width, 90% height
	var openModalView = function ($content) {
		dialog.openDialog($content, {
			width: '90%',
			height: '90%',
		});
	};

	return {
		openModalView: openModalView,
	};
});
// this handles the generation of the select element that shows users the text options they have for a field option
define('optionFormatListing', ['jquery', 'viewData', 'messaging'], function ($, viewData, messaging) {

	var $currentDropDownContainer = null;

	// create the select element for the text format select
	var createSelectElement = function (fieldOptionID, label, validFormats, currentOption) {
		// default the label to text format if not provided
		if (!label)
			label = 'Text Format';
		// initialize the select element
		var $dropDown = $('<div>').addClass('format-select-dropdown-container').attr('data-optionid', fieldOptionID);
        var $input = $('<input>').attr('type', 'hidden').addClass('text-format-select').change(optionChanged);
		var $selectedOption = $('<div>').addClass('field-option-format-select-button').click(openListing);
		var $selectedOptionContent = $('<div>').addClass('selected-option-content');

        // initialize the format to the first valid format
        if (validFormats.length > 0) {
            $selectedOptionContent.html('Select Font');
        }

		// if there is only one valid format, then hide the field
        if (validFormats.length < 2)
        {
            var font = getFontForFontID(validFormats[0].FontID);
			if (font) {
				$selectedOptionContent.html(font.Label);
				$input.val(validFormats[0].UUID);
			}
            $dropDown.addClass('hidden-field');

        }
		$selectedOption.append($selectedOptionContent); 
        $selectedOption.append($('<div>').addClass('dropdown-icon'));

        // added for responsive
        if (currentOption.FillColorRequired || currentOption.TextOutlineRequired || currentOption.TextShadowRequired) {
            $selectedOption.append($('<span>').html('*').addClass('required'));
        }

		var $options = $('<div>').addClass('options');
		// loop over each format and add a format element to the selectable options
		validFormats.forEach(function (format, index) {
			var $option = createOption(format, index);
            if ($option)
            {
                $options.append($option);
            }
        });	

        // for departments
        // add checkbox for lock font choice - used only for departments
        var $lockFontWrapper = $('<div>').addClass('lock-font-wrapper');
        var $lockFont = $('<input>').attr('type', 'checkbox').addClass('lock-font').change(optionChangedLockFont);;
        var $lockFontLabel = $('<span>').addClass('helper-copy').html('Allow users within the department to change font selection');
        $lockFontWrapper.append($lockFont).append($lockFontLabel);              
        $dropDown.append($input).append($selectedOption).append($options).append($lockFontWrapper);

		return $dropDown;
	};

    // scroll button actions for font overlay
    var scrollFontOption = function (){
        var $this = $(this);
        var $fontOptionWrapper = $this.closest('.format-select-container').find('.options-wrapper');
        if ($this.hasClass('up'))
        {
            $fontOptionWrapper.animate({
                scrollTop: $fontOptionWrapper.scrollTop() - 100
            }, 300);
        } else
        {
            $fontOptionWrapper.animate({
                scrollTop: $fontOptionWrapper.scrollTop() + 100
            }, 300);
        }
       
    }

	// open listing click handler
	var openListing = function () {	
		var $this = $(this).parents('.format-select-dropdown-container');
		var $templateGroup = $this.parents('.template-group');
		var templateGroupID = parseInt($templateGroup.attr('data-id'));
		// if this is a read only select, then don't open the menu
		if ($this.hasClass('read-only'))
			return;
		var fieldOptionID = $this.attr('data-optionid');		// get and open the menu overlay
		var $menuOverlay = $('.menu-overlay');
		$menuOverlay.addClass('open');
		// initialize the element that will be added to the menu overlay
		var $formatSelectContainer = $('<div>').addClass('format-select-container').attr('data-optionid', fieldOptionID);
        var $backDiv = $('<div>').addClass('cancel-container').html('Select Font');
		$backDiv.append($('<div>').addClass('cancel-link').html('X').click(cancelLinkClicked));
        $formatSelectContainer.append($backDiv);
        var $upDirection = $('<div>').addClass('font-button up').html('<img src="/images/font-up-arrow.png" />').click(scrollFontOption);
        $formatSelectContainer.append($upDirection);
        // add fixed class to body - needed for safari mobile
        $('body').addClass('fixed-body');
		var $options = $this.find('.options .option');
        // loop over each of the text formats and clone them into the element that will be added to the menu overlay
        var $optionsWrapper = $('<div>').addClass('options-wrapper');      
		$options.each(function (index, element) {
			// we are using clone here because we are cloning dom elements from one place to another
			var $option = $(element).clone().click(optionClickHandlerFactory(templateGroupID));
            $optionsWrapper.append($option);
        });
       
        $formatSelectContainer.append($optionsWrapper);
        var $downDirection = $('<div>').addClass('font-button down').html('<img src="/images/font-down-arrow.png" />').click(scrollFontOption);
        $formatSelectContainer.append($downDirection);
        $menuOverlay.append($formatSelectContainer);

        //update styling
        var currentSelectedFieldOption = $('.hotspot-view.open .field.open .field-option.active-field-option');
        if (currentSelectedFieldOption.length == 1) {
            var selectedFontId = currentSelectedFieldOption.find('.format-select-dropdown-container .text-format-select').val();
            $('.menu-overlay.open .option[data-id="' + selectedFontId + '"]').addClass('selected');
        }
		window.scrollTo(0, 0);
    };

	// method that creates an individual text format option for the select
	var createOption = function (format, index) {
		// make sure the font is valid, otherwise, don't include it as an option
		var font = getFontForFontID(format.FontID);
		if (!font)	
			return null;
		// figure out the maximum characters for the format based on the breakpoints that were set up for the format
		var maxCharacters = 0;
		format.FontSizeKerningBreakpoints.forEach(function (breakpoint) {
			// this check is here because we can't be certain that the admin will always sort the breakpoints correctly or set them up correctly
			if (breakpoint.MaxCharacters > maxCharacters)
				maxCharacters = breakpoint.MaxCharacters;
		});
		// initialize the format element
		var $format = $('<div>').addClass('format option')	.attr('data-id', format.UUID)
															.attr('data-formatlabel', format.Label)
															.attr('data-previewurl', font.PreviewImage)
															.attr('data-maxchars', maxCharacters)
															.attr('data-addedcost', font.AddedCost)
															.attr('data-percharcost', (format.PerCharacterCost) ? format.PerCharacterCost : '')
															.attr('data-forceuppercase', font.ForceUppercase);
		// if this format is the first one, then add a special class to indicate such
		if (index == 0)
			$format.addClass('first-format');
		// include a preview image for the format.  so the user can see Zabars vesus Serpentine versus Calibri as options
		$format.append($('<img>').attr('src', font.PreviewImage));	
		return $format;
	};

	// get the font data from the available fonts provided in the viewdata
	var getFontForFontID = function (fontID) {
		// loop oever the available fonts and return the font when it is found, otherwise return null
		for (var i = 0; i < viewData.AvailableFonts.length; i++) {
			if (viewData.AvailableFonts[i].FontID == fontID)
				return viewData.AvailableFonts[i];
		}
		return null;
	};

	// change handler for when the selected text format is changed
	var changeSelectedTextFormat = function ($input) {
		// get the id of the input field that holds the selected format
        var id = $input.val();
        if (id == null || id == '') {
            // removed preselected text for new responsive design.  So if no font is selected than just return
            return;
        }
		var $dropdown = $input.parents('.format-select-dropdown-container');
		// find the option in question
		var $option = $dropdown.find('.options .option[data-id=' + id + ']');
		// clear the selected option that is currently there and repalce it with the format label for the newly select option
		$dropdown.find('.selected-option-content').empty().html($option.attr('data-formatlabel'));
		// get the data for the selected format
		var $fieldOption = $input.parents('.field-option');
		var maxChars = parseInt($option.attr('data-maxchars'));

		// set the max character and other data to the hidden input
		$input.attr('data-maxchars', maxChars).attr('data-formatlabel', $option.attr('data-formatlabel')).attr('data-addedcost', $option.attr('data-addedcost'))
			.attr('data-percharcost', $option.attr('data-percharcost'));

		// loop over all the variations.  Most times it will just be 1
		$fieldOption.find('input.field-option-text-input').each(function () {
			var $this = $(this);
			// check the length of the text
			var textLength = $this.val().length;
			
			// if the font requires uppercase, then put the text to upper case
			var forceUppercase = $option.attr('data-forceuppercase') === 'true';
			if (forceUppercase)
				$this.addClass('force-uppercase').val($this.val().toUpperCase());
			else
				$this.removeClass('force-uppercase');
			// set the max length of the text input field in accordance with the max characters allowed by the text format
			$this.attr('maxlength', maxChars);
			$this.html(maxChars - textLength);
		});


	};

	// option changed handler
	var optionChanged = function () {
		changeSelectedTextFormat($(this));
		messaging.hotspotPreviewRequested.publish();                
    };

    // font-lock changed handler
    var optionChangedLockFont = function () {
        messaging.hotspotPreviewRequested.publish();
    };




	// factory for option click handlers
	var optionClickHandlerFactory = function (templateGroupID) {
		return function () {
			// assign the clicked formats value to the input field and then close the menu overlay
			var $this = $(this);
			var fieldOptionID = $this.parents('.format-select-container').attr('data-optionid');
			var $select = $('.template-group[data-id=' + templateGroupID + '] .format-select-dropdown-container[data-optionid=' + fieldOptionID + ']');
			var uuid = $this.attr('data-id');
			$select.find('input').val(uuid).change();
			closeOverlay();
		};
	};

	// when the menu overlay's cancel link is clicked, close the menu overlay
	var cancelLinkClicked = function () {
		closeOverlay();
	};

	// closes the menu overlay
    var closeOverlay = function () {
        $('body').removeClass('fixed-body');
		var $menuOverlay = $('.menu-overlay');
		$menuOverlay.empty().removeClass('open');
		window.scrollTo(0, 0);
	};

	return {
		openListing: openListing,
		createSelectElement: createSelectElement
	};
});
// this module handles the bottom bar button visibility status
define('overlay', ['jquery'], function ($) {
    'use strict';

    var addOverlay = function ()
    {
        var $overlay = $('<div>').addClass('overlay-loading');
        var $loadingGifWrapper = $('<div>').addClass('loadingGif-wrapper');
        var $loadingGif = $('<img>').attr('src', '/images/Customizing-Gif.gif');
        $loadingGifWrapper.append($loadingGif);
        $('body').append($overlay).append($loadingGifWrapper);
    }

    var removeOverlay = function () {
        $('.overlay-loading').remove();
        $('.loadingGif-wrapper').remove();
    }


    return {
        addOverlay: addOverlay,
        removeOverlay: removeOverlay
    };
});     
// this modules handles the generation of perspective hotspot previews
// so when you customize a job shirts left chest hotspot and apply the customizations, when you return to the perspective view
// you will see a little thumbnail of your customizations on top of the perspective at the hotspot area
define('perspectiveHotspotPreview', ['messaging', 'viewData', 'jquery', 'menu', 'hotspotFormStateReader', 'perspectivePreview', 'http'],
						function	(messaging, viewData, $, menu, hotspotFormStateReader, perspectivePreview, http) {

	// initialize the module private variables
	var previewSelector = '.preview-container .preview';

	// init function for subscribing to pubsub topics
	var init = function () {
		messaging.applyButtonClicked.subscribe(applyButtonClicked);
		messaging.perspectiveChanged.subscribe(applyButtonClicked);
	};

	// apply button subscription handler
	var applyButtonClicked = function () {
		showPerspectiveHotspotPreviews();
	};

	// method to show the hotspot previews (if they exist) in the perspective hotspots 
	var showPerspectiveHotspotPreviews = function () {
        // update all persepective becuase mobile shows all perspecitves at once in slideshow
        var allPerspectives = perspectivePreview.getAllPerspective();
        for (var i = 0; i < allPerspectives.length; i++)
        {
            // loop over each hotspot for the perspective and apply the preview so that it can be shown in the perspective hotspot area
            for (var j = 0; j < allPerspectives[i].Hotspots.length; j++) {
                var hotspot = allPerspectives[i].Hotspots[j];
                var $hotspotView = $('.hotspot-view[data-id=' + hotspot.ID + ']');
                // the hotspot has to have the class applied before it can show a preview there
                // this is assigned when the user hits the apply button for a hotspot template
                if ($hotspotView.hasClass('applied')) {
                    handleAppliedHotspot(allPerspectives[i], hotspot, $hotspotView);
                }
            }
        }
	};
	
	// method that tries to generate hotspot previews (or hopefully retrieve them from cache)
	var handleAppliedHotspot = function (perspective, hotspot, $hotspotView) {
		// get the relevant data for generating the hotspot preview
		var $appliedTab = $hotspotView.find('.menu-tab.applied');
		var $appliedGroup = $appliedTab.find('.template-group.applied');
		var $appliedForm = $appliedGroup.find('.template-form');
		var mode = '';
		if ($appliedTab.hasClass('design'))
			mode = 'design';
		else if ($appliedTab.hasClass('text'))
			mode = 'text';
		// add the loading div to indicate the preview is refreshing
		$(previewSelector).removeClass('loading').addClass('loading');
		var data = {
			ProjectID: viewData.ProjectID,
			PerspectiveID: perspective.ID,
			HotspotID: hotspot.ID,
			Mode: mode,
			// read the form state for the hotspot form
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState($appliedForm))
		};
		// call the hotspot preview action with the assembled data so that it will generate a hotspot preview
		http.post('/Build/Project/HotspotPreview', data).error(errorHandler).done(previewGeneratedFactory(perspective, hotspot));
	};

	// error handler for when the hotspot preview failed
	var errorHandler = function () {
		$(previewSelector).removeClass('loading');
		//alert('An error occurred while generating the preview.  An error notification \nhas been sent.  Please check your settings for any issues.');
	};

	// this factory creates the done handlers for teh hotspot preview api call
	var previewGeneratedFactory = function (perspective, hotspot) {
		return function (response) {
			// remove teh loading div
			$(previewSelector).removeClass('loading');
			// apply the hotspot preview to the perspective hotspot area
            var $hotspotOverlay = $('.perspective-view .hotspot[data-hotspotid=' + hotspot.ID + ']');
            var $mobileHotspotOverlay = $('.mobile-perspective-views .hotspot[data-hotspotid=' + hotspot.ID + ']');
            $hotspotOverlay.addClass('applied');
            $mobileHotspotOverlay.addClass('applied');
			hotspot.PerspectivePreviewImage = response;
			// the hotspot preview is a base64 string of the image content
            $hotspotOverlay.find('.perspective-hotspot-preview-container').css('background-image', 'url(\'data:image/png;base64,' + hotspot.PerspectivePreviewImage + '\')').css('background-size', 'contain');
            $mobileHotspotOverlay.find('.perspective-hotspot-preview-container').css('background-image', 'url(\'data:image/png;base64,' + hotspot.PerspectivePreviewImage + '\')').css('background-size', 'contain');
		};
	};

	return {
		init: init,
		showPerspectiveHotspotPreviews: showPerspectiveHotspotPreviews
	};
});
// thi module just shows the little perspective thumbs at the bottom of the perspecitve-level view
define('perspectiveListing', ['viewData', 'messaging'], function (viewData, messaging) {
	'use strict';

	// set up private vars
	var perspectivesSelector = '.perspectives';

	// initialize the perspective listing
	var initializeUI = function () {
		var perspectives = viewData.Project.Perspectives;
        var $perspectives = $(perspectivesSelector);
        $('.project-help-text').html(viewData.Project.HelpText);

		// loop over each perspective and generate then add the perspective element
		perspectives.forEach(function (perspective, index) {
			var $perspective = createPerspectiveElement(perspective);
			// selects the first perspective element
			if (index == 0)
				$perspective.addClass('selected');
			$perspectives.append($perspective);
        });

        // compress width if more than 4 perspectives
        if ($('.perspectives .perspective').length > 4)
        {
            var width = (100 / $('.perspectives .perspective').length) - 2;
            width = width.toString() + '%';
            $('.perspectives .perspective').css('width', width).addClass('mulitples');
        }
	};

	// creates a perspective element
	var createPerspectiveElement = function (record) {
		// initialize the elmeent and set the data attributes
		var $perspective = $('<div>').addClass('perspective');
		$perspective.attr('data-perspectiveId', record.ID);
		$perspective.attr('data-perspectiveLabel', record.Label);
		$perspective.attr('data-perspectiveImageURL', record.ImageURL);
		// iniitalize the image preview element
        var $perspectiveImageContainer = $('<div>').addClass('perspective-image-container');
		var $perspectiveImage = $('<div>').addClass('perspective-image').css('background-image', 'url(' + record.ImageURL + ')');
        var $perspectiveLabel = $('<div>').addClass('perspective-label').html(record.Label);
		$perspectiveImageContainer.append($perspectiveImage);
        $perspective.append($perspectiveImageContainer).append($perspectiveLabel);        
        return $perspective.click(perspectiveClicked);
	};

	// perspective element click handler
	var perspectiveClicked = function () {
		var $this = $(this);
		// if the preview is zoomed, then zoom out the view
		if ($('.preview-container .preview').hasClass('zoomed'))
		{
			$('.zoom-out-link').click();
		}
		// deselect any selected perspectives
		$this.siblings().removeClass('selected');
		if ($this.hasClass('selected'))
			return;
		// add the selected class to the clicked perspective
		$this.addClass('selected');
		// get the perspective id
		var id = parseInt($this.attr('data-perspectiveId'));
		// find the perspective for the project and publish a perspective changed message
		viewData.Project.Perspectives.forEach(function (perspective) {
			if (perspective.ID == id)
				messaging.perspectiveChanged.publish(perspective);
		});
	};

	return {
		initializeUI: initializeUI
	};
});
// module that generates perspective previews
define('perspectivePreview', ['messaging', 'jquery', 'viewData'], function (messaging, $, viewData) {
	'use strict';

	// private variables
	var previewWidth, previewHeight, originalWidth, originalHeight;
    var currentPerspective = {};
    var allPerspectives = {};
	var resizedTimer = 0;
    var swiper;
    var moveSlide = false;
    var slideToMoveTo = 0;

	// initialization function for subscribing and window resizing event handling
	var init = function () {
        messaging.perspectiveChanged.subscribe(showPerspectivePreview);
        messaging.perspectiveChanged.subscribe(showMobilePerspectivePreview);
        $(window).resize(windowResized);

        // initiate mobile slideshow of perspectives
        swiper = new Swiper('.swiper-container', {
            slidesPerView: 1.3,
            centeredSlides: true,
            spaceBetween: 0,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });
        showMobilePerspectivePreview();
        allPerspectives = viewData.Project.Perspectives;     
	};

    var updateMobileSlidePostion = function (mobileSlideIndex)
    {      
        if (!viewData.Project.OneViewProject)
        {
            moveSlide = true;
            slideToMoveTo = mobileSlideIndex;
        }
    }

	// event handler for when the window resizes
	// this event is handled because the perspective preview needs to resize when the window resizes
    var windowResized = function () {
        // adds loafing message
        $('.preview-container .preview').addClass('loading');
		// resize the perspective on a 500 millisecond delay
		clearTimeout(resizedTimer);
        $('.perspective-view .hotspot').remove();
        $('.mobile-perspective-views .hotspot').remove();
		resizedTimer = setTimeout(function () {
            showPerspectiveHotspots(getCurrentPerspective());
            showMobilePerspectivePreview();
            if (moveSlide) {
                if (isNaN(slideToMoveTo)) {
                    slideToMoveTo = 0;
                }
                swiper.slideTo(slideToMoveTo);
                moveSlide = false;
            }
            // removes loading message
            $('.preview-container .preview').removeClass('loading');
        }, 500);
       
	};

	// function to initialize the ui with the first perspective
	var initializeUI = function () {
        if (viewData.Project.Perspectives.length > 0)
        {
            showPerspectivePreview(viewData.Project.Perspectives[0]);
        }
	};

	// add the perspective to the ui
    var showPerspectivePreview = function (perspective) {
        if (perspective.Hotspots != undefined) {
            currentPerspective = perspective;               
        } else {
            perspective = currentPerspective;
        }
		$('.preview .perspective-view .preview-image').remove();
		$('.preview .perspective-view .hotspot').remove();
        var $previewImageDiv = $('<div>').addClass('preview-image').css('background-image', 'url(' + perspective.ImageURL + ')');
        $('.preview .perspective-view').append($previewImageDiv);
        // add the perspective hotspots to the ui for the perspective
        showPerspectiveHotspots(perspective);
	};


    // add the perspective to the ui
    var showMobilePerspectivePreview = function () {
        $('.preview .mobile-perspective-views .preview-image').remove();
        $('.preview .mobile-perspective-views .hotspot').remove();
        viewData.Project.Perspectives.forEach(function (perspective, i) {  
             //currentPerspective = perspective;
             var $previewImageDiv = $('<div>').addClass('swiper-slide preview-image').css('background-image', 'url(' + perspective.ImageURL + ')').attr('data-perspectiveid', perspective.ID).attr('data-index', i);
             $('.preview .mobile-perspective-views').append($previewImageDiv);
             swiper.appendSlide($previewImageDiv);
             // add the perspective hotspots to the ui for the perspective
             showMobilePerspectiveHotspots(perspective);
        });
        // update swiper after appenging the perspective slides
        swiper.update();    
    };


    // add the perspective hotspots to the ui
    var showMobilePerspectiveHotspots = function (perspective) {
        var $image = $('.preview .mobile-perspective-views .preview-image[data-perspectiveid="' + perspective.ID + '"]');
        var $container = $('.preview .mobile-perspective-views .preview-image[data-perspectiveid="' + perspective.ID + '"]');
        // set the current width and height of the preview
        previewWidth = $image.width();
        previewHeight = $image.height();
        // add the hotspot overlay element to the ui
        perspective.Hotspots.forEach(function (hotspot) {
            $container.append(getMobileHotspotOverlayElement(perspective, hotspot));
        });
        // publish a perspective preview ready message
        messaging.perspectivePreviewReady.publish(perspective);       
        // adjust label positioning for dynamic-absolute positioned hotspot label
        $('.preview .mobile-perspective-views .preview-image[data-perspectiveid="' + perspective.ID + '"] .hotspot').each(function () {
            var $hotspot = $(this);
            var hotspotWidth = $hotspot.outerWidth();
            var $hotspotLabel = $hotspot.find('.hotspot-label');            
            var hotspotLabelWidth = $hotspotLabel.outerWidth();
            if (hotspotWidth < hotspotLabelWidth) {
                var leftAdjust = (hotspotLabelWidth - hotspotWidth) / 2;
                $hotspotLabel.css('left', -leftAdjust);
            }
        });
    };

    // create a hotspot overlay element for the perspective hotspot
    var getMobileHotspotOverlayElement = function (perspective, hotspot) {
        // get the original aspect ratio of the perspective
        var originalAspectRatio = perspective.ImageWidth / perspective.ImageHeight;
        var $previewImage = $('.mobile-perspective-views .preview-image[data-perspectiveid="' + perspective.ID + '"]');         
        // set the max width for the preview image
        var maxWidth = $previewImage.width();
        var maxHeight = $previewImage.height();
       
        // vet the preview area aspect ratio
        var previewAreaAspectRatio = maxWidth / maxHeight;
        var newHeight = 0;
        var newWidth = 0;

        // compute the appropriate preview width and height for the relevant aspect ratios
        if (originalAspectRatio <= previewAreaAspectRatio) {
            newHeight = maxHeight;
            newWidth = newHeight * originalAspectRatio;
        }
        else {
            newWidth = maxWidth;
            newHeight = newWidth / originalAspectRatio;
        }

        // calculate the placement for the hotspot overlay and the perspective
        var widthRatio = newWidth / perspective.ImageWidth;
        var heightRatio = newHeight / perspective.ImageHeight;
        var previewX = (hotspot.X * widthRatio) + ((maxWidth - newWidth) / 2);
        var previewY = (hotspot.Y * heightRatio) + ((maxHeight - newHeight) / 2);
        var hotspotHeight = hotspot.PreviewHeight * heightRatio;
        var hotspotWidth = hotspot.PreviewWidth * widthRatio;
        // add the hotspot overlay at the specified location
        var $hotspot = $('<div>').addClass('hotspot').attr('data-hotspotid', hotspot.ID).attr('data-perspectiveid', perspective.ID);
        $hotspot.css('left', (previewX - (hotspotWidth / 2)) + 'px');
        var top = (previewY - (hotspotHeight / 2));
        $hotspot.css('top', top + 'px');
        $hotspot.css('width', hotspotWidth + 'px');
        $hotspot.css('height', hotspotHeight + 'px');
        var $hotspotPreview = $('<div>').addClass('perspective-hotspot-preview-container').click(hotspotClickedFactory(perspective, hotspot))
        // set the perspective preview image for the hotspot in the hotspot overlay
        if (hotspot.PerspectivePreviewImage) {
            $hotspot.addClass('applied');
            $hotspotPreview.css('background-image', 'url(\'data:image/png;base64,' + hotspot.PerspectivePreviewImage + '\')').css('background-size', 'contain');
        }

        //get hotspot label
        var $hotspotLabel = $('<div>').addClass('hotspot-label');
        for (var i = 0; i < perspective.Hotspots.length; i++) {
            if (perspective.Hotspots[i].ID == hotspot.ID) {
                $hotspotLabel.html(perspective.Hotspots[i].Label);
            }
        }

        // return the hotspot overlay element
        $hotspot.append($hotspotPreview).append($hotspotLabel);
        if ($.isNumeric(viewData.BuildTemplateID) && viewData.BuildTemplateID > 0 && viewData.CustomizationPurchaseOnly)
            return $hotspot;

        $hotspot.append($('<div>').addClass('clear-button').click(clearButtonClicked));
        return $hotspot;
    };

	// add teh perspective hotspots to the ui
	var showPerspectiveHotspots = function (perspective) {
		var $image = $('.preview .perspective-view .preview-image');
		var $container = $('.preview .perspective-view');
		// set the current width and height of the preview
		previewWidth = $image.width();
		previewHeight = $image.height();
		// add the hotspot overlay element to the ui
        setTimeout(
            function () {
                perspective.Hotspots.forEach(function (hotspot) {
                    $container.append(getHotspotOverlayElement(perspective, hotspot));
                });

                // adjust label positioning for dynamic-absolute positioned hotspot label
                $('.perspective-view .hotspot').each(function () {

                    var hotspotWidth = $(this).width();
                    var $hotspotLabel = $(this).find('.hotspot-label');
                    var hotspotLabelWidth = $hotspotLabel.outerWidth();
                    if (hotspotWidth < hotspotLabelWidth) {
                        var width = (hotspotLabelWidth - hotspotWidth) / 2;
                        $hotspotLabel.css('left', -width);
                    }
                });
            }, 250);
		
		// publish a perspective preview ready message
        messaging.perspectivePreviewReady.publish(perspective);
	};



    var getHotspotOverlayElement = function (perspective, hotspot) {
        // get the original aspect ratio of the perspective
        var originalAspectRatio = perspective.ImageWidth / perspective.ImageHeight;
        var $previewImage = $('.perspective-view .preview-image');
        // set the max width for the preview image
        var maxWidth = $previewImage.width();
        var maxHeight = $previewImage.height();
        // vet the preview area aspect ratio
        var previewAreaAspectRatio = maxWidth / maxHeight;
        var newHeight = 0;
        var newWidth = 0;

        // compute the appropriate preview width and height for the relevant aspect ratios
        if (originalAspectRatio <= previewAreaAspectRatio) {
            newHeight = maxHeight;
            newWidth = newHeight * originalAspectRatio;
        }
        else {
            newWidth = maxWidth;
            newHeight = newWidth / originalAspectRatio;
        }

        // calculate the placement for the hotspot overlay and the perspective
        var widthRatio = newWidth / perspective.ImageWidth;
        var heightRatio = newHeight / perspective.ImageHeight;
        var previewX = (hotspot.X * widthRatio) + ((maxWidth - newWidth) / 2);
        var previewY = (hotspot.Y * heightRatio) + ((maxHeight - newHeight) / 2);
        var hotspotHeight = hotspot.PreviewHeight * heightRatio;
        var hotspotWidth = hotspot.PreviewWidth * widthRatio;
        // add the hotspot overlay at the specified location
        var $hotspot = $('<div>').addClass('hotspot').attr('data-hotspotid', hotspot.ID).attr('data-perspectiveid', perspective.ID);
        $hotspot.css('left', (previewX - (hotspotWidth / 2)) + 'px');
        var top = (previewY - (hotspotHeight / 2)) + parseInt($previewImage.position().top);
        $hotspot.css('top', top + 'px');
        $hotspot.css('width', hotspotWidth + 'px');
        $hotspot.css('height', hotspotHeight + 'px');
        var $hotspotPreview = $('<div>').addClass('perspective-hotspot-preview-container').click(hotspotClickedFactory(perspective, hotspot))
        // set the perspective preview image for the hotspot in the hotspot overlay
        if (hotspot.PerspectivePreviewImage) {
            $hotspot.addClass('applied');
            $hotspotPreview.css('background-image', 'url(\'data:image/png;base64,' + hotspot.PerspectivePreviewImage + '\')').css('background-size', 'contain');
        }


        //get hotspot label
        var $hotspotLabel = $('<div>').addClass('hotspot-label');
        for (var i = 0; i < perspective.Hotspots.length; i++) {
            if (perspective.Hotspots[i].ID == hotspot.ID) {
                $hotspotLabel.html(perspective.Hotspots[i].Label);
            }
        }

        if ($('.perspective-view').find('.hotspot.applied[data-hotspotid="' + hotspot.ID + '"]').length == 0) {
            // return the hotspot overlay element
            $hotspot.append($hotspotPreview).append($hotspotLabel);
            if ($.isNumeric(viewData.BuildTemplateID) && viewData.BuildTemplateID > 0 && viewData.CustomizationPurchaseOnly)
                return $hotspot;

            $hotspot.append($('<div>').addClass('clear-button').click(clearButtonClicked));
            return $hotspot;
        }
        else return "";

      
    };

 

    
	// click handler factory for the hotspot
    var hotspotClickedFactory = function (perspective, hotspot) {
        return function () {
            $('.project-help-text').addClass('hide');
			var data = {
				perspective: perspective,
				hotspot: hotspot
			};
			messaging.hotspotSelected.publish(data);
		};
    };

	// clear button click handler for when someone clicks to clear a hotspot 
	var clearButtonClicked = function () {
		// confirm that the customer wants to clear the hotspot
		if (confirm('Are you sure you want to clear this location?  This cannot be undone!'))
		{
			// remove the relevant hotspot info from the build
			var $this = $(this);
            var $hotspotPreview = $this.parents('.hotspot.applied');
			$hotspotPreview.removeClass('applied');
			$hotspotPreview.find('.perspective-hotspot-preview-container').css('background-image', 'none');
            var hotspotID = parseInt($hotspotPreview.attr('data-hotspotid'));
			var $hotspotView = $('.menu .hotspot-view[data-id=' + hotspotID + ']');
			$hotspotView.removeClass('applied');
			var $tab = $hotspotView.find('.menu-tab.applied');
			$tab.removeClass('applied');
			var $templateGroup = $tab.find('.template-group.applied');
			$templateGroup.removeClass('applied open');
			var $templateForm = $templateGroup.find('.template-group-content .template-form');
			// clears the form which clears the user's inputs for the perspective hotspot template
			$templateForm.empty().removeAttr('data-templateid');
            messaging.reviewUpdateRequested.publish();

           
            var perspective = getCurrentPerspective();
            perspective.Hotspots.forEach(function (hotspot) {
                if (hotspot.ID == hotspotID) {
                    delete hotspot.PerspectivePreviewImage;
                }
                //$container.append(getHotspotOverlayElement(perspective, hotspot));
            });

		}		
	};

	// get the current perspective
	var getCurrentPerspective = function () {
		return currentPerspective;
    };

    // get the current perspective
    var getAllPerspective = function () {
        return allPerspectives;
    };

	return {
		init: init,
		initializeUI: initializeUI,
        getCurrentPerspective: getCurrentPerspective,
        getAllPerspective: getAllPerspective,
        updateMobileSlidePostion: updateMobileSlidePostion
	};
});
// critical module that handles the review data and the presentation of the review pane
define('review', ['messaging', 'jquery', 'hotspotFormStateReader', 'viewData', 'loadedTemplates'],
    function (messaging, $, hotspotFormStateReader, viewData, loadedTemplates) {
        'use strict';

        // private variables
        var reviewLineItemsContainer = '.review .line-items';
        var reviewState = {};

        // initialization function that subscribes to a few topics and binds some events
        var init = function () {

            messaging.applyButtonClicked.subscribe(reviewUpdateRequested);
            messaging.reviewUpdateRequested.subscribe(reviewUpdateRequested);

            $('.review-close-overlay').click(reviewOverlayClicked);
            $('.project-ui .review-container .review-tab').click(reviewContainerClicked);
            $('.review .review-design-summary').click(showReview);
            $('.project-ui.oneview .review .edit-build').click(editOneViewBuildClicked);
        };

        // for mobile.  handles going from review to persepctive view
        var showReview = function () {
            var $this = $(this);
            if ($this.hasClass('mobile-expand')) {
                $('.preview-review-column').removeClass('hide');
                $('.review-details-wrapper').addClass('mobile-hide');
                $this.html('REVIEW DESIGN SUMMARY');
                $this.removeClass('mobile-expand');
            }
            else {
                $('.preview-review-column').addClass('hide');
                $('.review-details-wrapper').removeClass('mobile-hide');
                $this.addClass('mobile-expand');
                $this.html('BACK TO DESIGN');
            }
        };


        var editOneViewBuildClicked = function () {
            // hide back button in first field
            $('.hotspot-view.open .navigation-wrapper .field-nav-button.back, .hotspot-view.open .navigation-wrapper .field-nav-button.next').addClass('one-view-first-field');
            // remove the review-status class from the project container
            $('.project-ui').removeClass('review-step');
            // show perspective view in case review panel was currently active
            $('.preview-review-column ').removeClass('hide');
            // hide the review container
            $('.menu .review-container').addClass('hide');
            //show nav buttons
            $('.menu .navigation-wrapper').removeClass('hide');
            // show template fields and note field
            $('.template-form .field, .template-form .field-notes').addClass('hide');
            // unhide the first field
            $('.template-form .field:first-child').addClass('open').removeClass('hide');
            // show finsiehed UI to user
            $('.template-group-content, .template-form').removeClass('hide');
        };


        // the review overlay is actually the invisible div that closes the open review pane when the user clicks it
        var reviewOverlayClicked = function () {
            $('.project-ui .review-container .review-tab').click();
        };

        // review container click handler
        var reviewContainerClicked = function () {
            var $reviewContainer = $(this).parents('.review-container');
            var $reviewOverlay = $('.review-close-overlay');
            // toggles the open / close state for the review pane
            $reviewContainer.toggleClass('open');
            // depending on the state of the review pane, open or close the review overlay
            if ($reviewContainer.hasClass('open'))
                $reviewOverlay.removeClass('open').addClass('open');
            else
                $reviewOverlay.removeClass('open');
        };

        // handler for when the customer hovers the review pane, which shoudl open the review pane
        var addToCartMouseOver = function () {
            var $reviewContainer = $('.project-ui .review-container');
            if (!$reviewContainer.hasClass('open'))
                $reviewContainer.addClass('open');
        };

        // handler for when the customer stops hovering the review pane, which should close the review pane
        var addToCartMouseOut = function () {
            var $reviewContainer = $('.project-ui .review-container');
            $reviewContainer.removeClass('open');
        };

        // review update requested message handler
        var reviewUpdateRequested = function () {
            refreshReviewUI();
        };

        // refreshes teh review ui in the review pane
        var refreshReviewUI = function () {
            // get the current review details
            var details = getDetails();
            reviewState = details;
            // render the details
            renderDetails(details);
        };

        // gets the details for the build
        var getDetails = function () {
            var details = {};
            details.hotspots = [];
            // loop over each applied hotspot and get the review details for it         
            $('.hotspot-view.applied').each(function (index, hotspotElement) {
                // initialize the hotspot data for the review
                var hotspot = { TemplateID: 0, Template: {} };
                hotspot.fields = [];
                var $hotspot = $(hotspotElement);
                hotspot.Label = $hotspot.attr('data-label');
                hotspot.ID = $hotspot.attr('data-id');
                var $activeTab = $hotspot.find('.menu-tab');
                var $activeTemplateGroup = $activeTab.find('.template-group.open');
                hotspot.Notes = $activeTemplateGroup.find('.field-notes textarea').val();
                hotspot.GroupName = $activeTemplateGroup.attr('data-label');
                hotspot.TemplateID = parseInt($activeTemplateGroup.find('.template-form').attr('data-templateid'));
                hotspot.TemplateGroupID = parseInt($activeTemplateGroup.attr('data-id'));
                hotspot.TemplateGroupFixedID = $activeTemplateGroup.attr('data-fixedid');
                hotspot.TemplateGroupName = $activeTemplateGroup.attr('data-label');
                // get the hotspot template that was loaded
                if (hotspot.TemplateID !== 0)
                    hotspot.Template = loadedTemplates.getTemplate(hotspot.TemplateID);
                // loop over each field for the hotspot template in the form
                $activeTemplateGroup.find('.template-form .field').each(function (fieldIndex, fieldElement) {
                    var $field = $(fieldElement);
                    // read the template field form state
                    var fieldState = hotspotFormStateReader.readTemplateFieldFormState($field, hotspot.Template);
                    if (!fieldState)
                        return;
                    // set the data for the field
                    fieldState.Label = $field.find('.field-label').html();
                    fieldState.FieldOptionLabel = $field.find('.option.selected').html();
                    hotspot.fields.push(fieldState);
                });
                details.hotspots.push(hotspot);
            });
            return details;
        };

        // render the review details
        var renderDetails = function (details) {
            // clear the line items from the review pane
            var $lineItems = $(reviewLineItemsContainer);
            $lineItems.empty();
            // this is the subtotal for the build
            var total = 0;
            //get total customizableItems Quatity
            var customizableItemsSum = 0;
            if (viewData.CustomizableItems.length == 1 && viewData.CustomizableItems[0].length == 1) {
                // either a saved build or a build with just 1 variation
                customizableItemsSum = viewData.CustomizableItems[0][0].Quantity;
            } else {
                viewData.CustomizableItems.forEach(function (customizableItemArray, i) {
                    customizableItemsSum += customizableItemArray.length;
                });
            }

            // loop over each hotspot and output the review details for it
            var showBulkCopy = false;
            details.hotspots.forEach(function (hotspot) {
                // if no hotspot template is set for it, then return
                if ((hotspot.TemplateID === 0) || (!hotspot.Template))
                    return;

                // initialize teh line item element
                var $lineItem = $('<div>').addClass('line-item');
                var $lineItemLabel = $('<div>').addClass('line-item-label').html(hotspot.Template.Label + " - " + hotspot.Label);
                // if this isn't a one view project, then add edit buttons to the ui for each review line item
                if (!viewData.Project.OneViewProject)
                    $lineItemLabel.click(editButtonClicked).attr('data-id', hotspot.ID);
                $lineItem.append($lineItemLabel);

                // append the template and price info
                if ((hotspot.TemplateID !== 0) && hotspot.Template) {
                    // initialize the template line item info           
                    var $templateLineItemElement = $('<div>').addClass('line-item-element');
                    var templateLabel = '';
                    if (hotspot.GroupName && (hotspot.GroupName != ''))
                        templateLabel = hotspot.GroupName + ':  ';
                    else
                        templateLabel = 'Style:  ';
                    $templateLineItemElement.append($('<div>').addClass('element-label').html(templateLabel + hotspot.Template.SKU));

                    if (hotspot.Template.SKU.toUpperCase().match("^CCE-")) {
                        showBulkCopy = true;
                    }



                    // if the hotspot template has pricing included, then add it to the review pane
                    if (hotspot.Template.Price != 0) {
                        var templatePrice = hotspot.Template.Price;
                        if (hotspot.Template.Price2 != 0) {
                            templatePrice += hotspot.Template.Price2;
                        }
                        if (viewData.IsDepartmentManagerEnvironment != null && viewData.IsDepartmentManagerEnvironment && viewData.Project.OneViewProject) {
                            templatePrice = viewData.OneViewHotspotTemplateDepartmentPrice + viewData.OneViewHotspotTemplateDepartmentPrice2;
                        }

                        total += (templatePrice * customizableItemsSum);
                        var $lineItemTemplateElementPrice = $('<div>').addClass('price').html('$' + templatePrice.toFixed(2));
                        $templateLineItemElement.append($lineItemTemplateElementPrice);

                        // handle mutliple customizable items
                        if (customizableItemsSum > 1) {
                            $lineItemTemplateElementPrice.html('$' + templatePrice.toFixed(2) + ' X ' + customizableItemsSum);
                        }
                    }
                    else if (hotspot.Template.Price == 0 && hotspot.Template.Price2 > 0) {
                        // this block of code handles if sku 1 has no price but sku2 has a price > 0.  the if statetment for deparments oneview porjects is duplicate code from above. look into writting code that removes this duplicate
                        var templatePrice2 = hotspot.Template.Price2;
                        if (viewData.IsDepartmentManagerEnvironment != null && viewData.IsDepartmentManagerEnvironment && viewData.Project.OneViewProject) {
                            templatePrice2 = viewData.OneViewHotspotTemplateDepartmentPrice + viewData.OneViewHotspotTemplateDepartmentPrice2;
                        }

                        total += (templatePrice2 * customizableItemsSum);
                        var $lineItemTemplateElementPrice = $('<div>').addClass('price').html('$' + templatePrice2.toFixed(2));
                        $templateLineItemElement.append($lineItemTemplateElementPrice);
                        // handle mutliple customizable items
                        if (customizableItemsSum > 1) {
                            $lineItemTemplateElementPrice.html('$' + templatePrice2.toFixed(2) + ' X ' + customizableItemsSum);
                        }
                    }
                    $lineItem.append($templateLineItemElement);
                }

                // loop over any hotspot fields and add them to the ui
                if (hotspot.fields.length > 0) {
                    var colorSkus = [];
                    // append the fields
                    hotspot.fields.forEach(function (field) {
                        // if the field isn't valid, then don't add it to the review pane ui
                        if (!field.ValidField)
                            return;
                        var lineItemCost = 0;
                        var colorUpchargeCost = 0;
                        var finalLineItemCost = 0;
                        var $lineItemElement = $('<div>').addClass('line-item-element');
                        var elementLabel = field.Label + ':  ';
                        var elementDetails = '';
                        // handle text based field options
                        if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) != -1) {
                            if ((!field.Text) || (field.Text == ''))
                                return;
                            // loop over the customizable items
                            var customizableItemFieldsGroups = _.groupBy(field.CustomizableItemFields, 'VariationID');
                            var groupArrays = _.toArray(customizableItemFieldsGroups);
                            groupArrays.forEach(function (groupArray) {
                                var $lineItemElementSubWrapper = $('<div>').addClass('line-item-element-sub-wrapper');
                                $lineItemElement.append($lineItemElementSubWrapper);
                                groupArray.forEach(function (cfi, counter) {
                                    // add vairation label 
                                    if (counter == 0 && groupArrays.length > 1) {
                                        var $customizableItemLabel = $('<div>').html(cfi.VariationLabel + ' (' + groupArray.length + ')').addClass('font-weight-bold');
                                        $lineItemElementSubWrapper.append($customizableItemLabel);
                                    }
                                    if (counter == 0) {
                                        // add field label
                                        var $fieldLabel = $('<div>');//.addClass('').html(field.Label + ': ');
                                        $lineItemElementSubWrapper.append($fieldLabel);

                                        var mvElementDetails = '';
                                        // if the field has a custom field option label set, then display it
                                        if (cfi.FieldOptionLabel != '')
                                            mvElementDetails = appendElementDetails(mvElementDetails, cfi.FieldOptionLabel);

                                        // calculate the added costs (general added cost for fonts as well as per character costs)
                                        var formatAddedCost = 0;
                                        var characterCount = cfi.Text.replace(/ /g, '').length;
                                        if (cfi.TextFormatAddedCost) {
                                            lineItemCost += cfi.TextFormatAddedCost;
                                            formatAddedCost += cfi.TextFormatAddedCost;
                                        }
                                        if (!isNaN(cfi.TextFormatPerCharacterCost)) {
                                            lineItemCost += cfi.TextFormatPerCharacterCost * characterCount;
                                            formatAddedCost += cfi.TextFormatPerCharacterCost * characterCount;
                                        }
                                        else if (!isNaN(cfi.PerCharacterCost)) {
                                            lineItemCost += cfi.PerCharacterCost * characterCount;
                                            formatAddedCost += cfi.PerCharacterCost * characterCount;
                                        }

                                        // admin image cost
                                        if (!isNaN(cfi.AdminImageTextUpcharge)) {
                                            lineItemCost += cfi.AdminImageTextUpcharge;
                                            formatAddedCost += cfi.AdminImageTextUpcharge;
                                        }

                                        // field option total price
                                        if (!isNaN(cfi.FieldOptionPrice)) {
                                            lineItemCost += cfi.FieldOptionPrice;
                                            formatAddedCost += cfi.FieldOptionPrice;
                                        }

                                        // if the text format has a custom label, then display it
                                        if (cfi.TextFormatLabel != '') {
                                            if (formatAddedCost > 0)
                                                mvElementDetails = appendElementDetails(mvElementDetails, cfi.TextFormatLabel + ' [Added Cost: $' + formatAddedCost + ']');
                                            else
                                                mvElementDetails = appendElementDetails(mvElementDetails, cfi.TextFormatLabel);
                                        }

                                        // if the field option has no fill color enabled, then display that information
                                        if (cfi.NoFillColor) {
                                            mvElementDetails = appendElementDetails(mvElementDetails, 'No Fill Color');
                                        }
                                        // if the field has a fill color selected, then display that information
                                        else if (cfi.ColorID != 0) {
                                            // get the color from the available colors array
                                            var color = getColor(cfi.ColorID);
                                            if (color) {
                                                // get color upcharge
                                                var detailsAddendum = color.Label;
                                                if (color.Sku != null) {
                                                    var newColorPrice = true;
                                                    for (var i = 0; i < colorSkus.length; i++) {
                                                        if (colorSkus[i] == color.Sku) {
                                                            newColorPrice = false;
                                                        }
                                                    }
                                                    if (newColorPrice) {
                                                        // lineItemCost += color.Price;
                                                        colorUpchargeCost += color.Price;
                                                        colorSkus.push(color.Sku);
                                                        detailsAddendum += ' [Added Cost: $' + color.Price.toFixed(2) + ']';
                                                    }
                                                }
                                                mvElementDetails = appendElementDetails(mvElementDetails, detailsAddendum);
                                                $lineItemElementSubWrapper.attr('data-color-sku', color.Sku);
                                            }
                                        }
                                        // if the field has an outline color selected, then display that information
                                        if (cfi.OutlineColorID != 0) {
                                            // get the color from the available colors array
                                            var color = getColor(cfi.OutlineColorID);
                                            if (color) {
                                                // get color upcharge
                                                var detailsAddendum = color.Label;
                                                if (color.Sku != null) {
                                                    var newColorPrice = true;
                                                    for (var i = 0; i < colorSkus.length; i++) {
                                                        if (colorSkus[i] == color.Sku) {
                                                            newColorPrice = false;
                                                        }
                                                    }
                                                    if (newColorPrice) {
                                                        // lineItemCost += color.Price;
                                                        colorUpchargeCost += color.Price;
                                                        colorSkus.push(color.Sku);
                                                        detailsAddendum += ' [Added Cost: $' + color.Price.toFixed(2) + ']';
                                                    }
                                                }
                                                mvElementDetails = appendElementDetails(mvElementDetails, detailsAddendum);
                                            }
                                        }
                                        // if the field has a shadow color selected, then display that information
                                        if (cfi.ShadowColorID != 0) {
                                            // get the color from the available colors array
                                            var color = getColor(cfi.ShadowColorID);
                                            if (color) {
                                                // get color upcharge
                                                var detailsAddendum = color.Label;
                                                if (color.Sku != null) {
                                                    var newColorPrice = true;
                                                    for (var i = 0; i < colorSkus.length; i++) {
                                                        if (colorSkus[i] == color.Sku) {
                                                            newColorPrice = false;
                                                        }
                                                    }
                                                    if (newColorPrice) {
                                                        // lineItemCost += color.Price;
                                                        colorUpchargeCost += color.Price;
                                                        colorSkus.push(color.Sku);
                                                        detailsAddendum += ' [Added Cost: $' + color.Price.toFixed(2) + ']';
                                                    }
                                                }
                                                mvElementDetails = appendElementDetails(mvElementDetails, detailsAddendum);
                                            }
                                        }
                                        $fieldLabel.html(field.Label + ': ' + mvElementDetails);
                                    }
                                   // append the text field value to the line item
                                    $lineItemElementSubWrapper.append($('<div>').addClass('pl-5').html(cfi.Text));
                                });
                            });
                            // divide the line cost by total customizable items.  otherwise price will be incorrect
                            lineItemCost = parseFloat(lineItemCost / viewData.CustomizableItems.length);
                        }                  
                        else if (field.Type == 1) {
                                // handle image field options
                            // if now image sku exists, then skip the field option in the review pane
                            if (!field.ImageSKU)
                                return;
                            // add teh image data for the field option      
                            elementLabel += field.ImageSKU;
                            elementDetails = '';
                            // loop over each replaceable image color and add the fnformation to the review pange
                            for (var i = 0; i < field.ReplaceableColors.length; i++) {
                                var replaceableColor = field.ReplaceableColors[i];
                                var color = getColor(replaceableColor.ColorID);
                                if (color) {
                                    if (color.Price != null) {
                                        // get color upcharge
                                        var detailsAddendum = "Color: " + color.Label;
                                        if (color.Sku != null) {
                                            var newColorPrice = true;
                                            for (var i = 0; i < colorSkus.length; i++) {
                                                if (colorSkus[i] == color.Sku) {
                                                    newColorPrice = false;
                                                }
                                            }
                                            if (newColorPrice) {
                                                colorUpchargeCost += color.Price;
                                                colorSkus.push(color.Sku);
                                                detailsAddendum += ' [Added Cost: $' + color.Price.toFixed(2) + ']';
                                            }
                                        }
                                        elementDetails = appendElementDetails(elementDetails, detailsAddendum);
                                    }
                                    else {
                                        elementDetails = appendElementDetails(elementDetails, replaceableColor.Label + ': ' + color.Label);
                                    }
                                }
                            }
                            // add any image field price to the subtotal
                            if (field.Price > 0)
                                lineItemCost += field.Price;
                        }
                     
                        else if (field.Type === 11) {
                               // handle custom select field options
                            if (field.SelectedOptions.length == 0)
                                return;
                            if (field.TotalSelectableOptions == 1) {
                                elementLabel += field.SelectedOptions[0].Label + ': Yes';
                                lineItemCost += field.SelectedOptions[0].Price;
                            }
                            else {
                                var selectedOptionSkus = '';
                                field.SelectedOptions.forEach(function (selectedOption) {
                                    if (selectedOptionSkus !== '')
                                        selectedOptionSkus += ', ';
                                    selectedOptionSkus += selectedOption.Sku;
                                    if (selectedOption.Price > 0)
                                        lineItemCost += selectedOption.Price;
                                });
                                elementLabel += selectedOptionSkus;
                            }
                        }                     
                        else if (field.Type == 12) {
                               //handle cusatom images
                            var cloudinaryId = $('.field-option[data-id="' + field.ActiveOptionUUID + '"] #Custom-Image-ID').val()
                            if (cloudinaryId != null && cloudinaryId != '') {
                                elementLabel += 'Custom Image ';
                                lineItemCost += parseFloat($('.field-option[data-id="' + field.ActiveOptionUUID + '"] #Custom-Image-Upcharge').val());
                            }
                            else {
                                return;
                            }
                        }
                        else if (field.Type === 15) {
                            lineItemCost += field.NetSuiteSalesPersonData.Sku1Price;
                            elementLabel += field.NetSuiteSalesPersonData.Sku1 + ' (' + field.NetSuiteSalesPersonData.Sku1Description + ')';//+ ' [$' + field.NetSuiteSalesPersonData.Sku1Price + ']';                            
                        }



                        // include the field option element details in the review line item
                        if (elementDetails != '')
                            elementLabel += ' (' + elementDetails + ')';
                        if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(field.Type) < 0) {
                            // add the line item element to the review element
                            $lineItemElement.append($('<div>').addClass('element-label').html(elementLabel));
                        }


                        // divide lintItemCost by the total customizable items to get the individual price
                        finalLineItemCost = parseFloat(lineItemCost + colorUpchargeCost);
                        // make sure to output the line item cost
                        if (lineItemCost > 0 || colorUpchargeCost > 0) {
                            var priceCopy = '$' + finalLineItemCost.toFixed(2);
                            if (customizableItemsSum > 1)
                                priceCopy += ' X ' + customizableItemsSum;
                            var $lineItemElementPrice = $('<div>').addClass('price').html(priceCopy);
                            $lineItemElement.append($lineItemElementPrice);
                        }

                      

                        // add the line item cost to the subtotal
                        total += (finalLineItemCost * customizableItemsSum);
                        $lineItem.append($lineItemElement);


                        // freaking hack needed becuase request are getting too much.  really need to change the view pane to a partial view
                        if (field.Type === 15 && (field.NetSuiteSalesPersonData.Sku2 != null && field.NetSuiteSalesPersonData.Sku2 != '')) { 
                            var $sku2LineItemElementSubWrapper = $('<div>').addClass('line-item-element');
                            var sku2Price = parseFloat(field.NetSuiteSalesPersonData.Sku2Price);
                            var $sku2Label = $('<div>').addClass('element-label').html('SKU2:' + '(' + field.NetSuiteSalesPersonData.Sku2Description + ')');
                            var priceCopy = '$' + parseFloat(field.NetSuiteSalesPersonData.Sku2Price).toFixed(2);
                            if (customizableItemsSum > 1)
                                priceCopy += ' X ' + customizableItemsSum;
                            var $sku2Price = $('<div>').addClass('price').html(priceCopy);
                            $sku2LineItemElementSubWrapper.append($sku2Label).append($sku2Price);
                            $lineItem.append($sku2LineItemElementSubWrapper); 
                            total += (sku2Price * customizableItemsSum);
                        }
                    });
                }

                // append the notes to the review data
                var $notesLineItemElement = $('<div>').addClass('notes-line-item-element');
                $notesLineItemElement.append($('<div>').addClass('notes-element-label').html('Notes:'));
                $notesLineItemElement.append($('<div>').addClass('notes-element').html(hotspot.Notes));
                $lineItem.append($notesLineItemElement);
                $lineItems.append($lineItem);
            });
            if (showBulkCopy) {
                $('#Bulk-Item-Purchase-Copy').removeClass('hide');
            }
            else {
                $('#Bulk-Item-Purchase-Copy').addClass('hide');
            }
            $('.review .grand-total').html('$' + total.toFixed(2));
        };

        // method that just takes care of the csv formatting functionality
        var appendElementDetails = function (details, addendum) {
            if (!addendum)
                return details;
            if (details != '')
                details += ', ';
            details += addendum;
            return details;
        };


        // get teh color for the specified id from the available colors in the view data
        // good candidate for underscore usage
        var getColor = function (id) {
            for (var i = 0; i < viewData.AvailableColors.length; i++) {
                var color = viewData.AvailableColors[i];
                if (color.ColorID === id)
                    return color;
            }
            return null;
        };

        // edit button click handler
        var editButtonClicked = function () {
            // get the data for the clicked edit button in the review pane
            var $this = $(this);
            var id = parseInt($this.attr('data-id'));
            var data = {};
            var dataFound = false;
            // find the project perspective and hotspot for the specified id
            var perspectives = viewData.Project.Perspectives;
            for (var i = 0; i < perspectives.length; i++) {
                var perspective = perspectives[i];
                var hotspots = perspective.Hotspots;
                for (var j = 0; j < hotspots.length; j++) {
                    var hotspot = hotspots[j];
                    if (hotspot.ID === id) {
                        dataFound = true;
                        data.perspective = perspective;
                        data.hotspot = hotspot;
                        break;
                    }
                }
                if (dataFound)
                    break;
            }
            // publish the message that a hotspot is selected
            messaging.hotspotSelected.publish(data);
        };

        // get the current review state
        var getReviewState = function () {
            return reviewState;
        };

        return {
            init: init,
            getReviewState: getReviewState,
            refreshReviewUI: refreshReviewUI
        };
    });
// process the current review state and add in the extra data for adding to the cart
define('reviewStateProcessor', ['viewData'], function (viewData) {
	'use strict';

	// takes the review state and adds in the extra contextual data for saving and processing the build
	var processReviewStateData = function (reviewState) {
		// initialize the build state
		var buildState = {};
		buildState.ProjectID = viewData.ProjectID;
		buildState.ProductCode = viewData.ProductCode;
		buildState.ProductColorID = viewData.ProductColorID;
		buildState.ProductColorName = viewData.ProductColorName;
		buildState.ProductName = viewData.ProductName;
		buildState.ProcessURL = viewData.ProcessURL;
		buildState.VariationID = viewData.VariationID;
		buildState.OneViewProject = viewData.Project.OneViewProject;
		buildState.Hotspots = [];
		buildState.ProjectSKU = viewData.ProjectSKU;
		buildState.Quantity = viewData.Quantity;
		buildState.DisabledHotspots = [];
		buildState.DisabledPerspectives = [];
		buildState.BuildTemplateID = viewData.BuildTemplateID;
		buildState.DepartmentBisibiOneViewProductID = viewData.DepartmentBisibiOneViewProductID;
		buildState.DuplicateBuild = (viewData.SavedBuild != undefined && viewData.SavedBuild != null) ? viewData.SavedBuild.DuplicateBuild : false;
		buildState.CustomizableItems = viewData.CustomizableItems;
		buildState.UseDevelopmentPath = $('#Development-Path').prop('checked') ? true : false;

		// process disabled hotspots for departments
		$('.department-prevent-alterations-wrapper .input-prevent-hotspot-alterations').each(function (i, obj) {
			var $this = $(this);
			if ($this.is(':checked')) {
				buildState.DisabledHotspots.push(parseInt($this.val()));
			}
		});

		// loop over the review state hotspots and add that information to the build state object 
		reviewState.hotspots.forEach(function (hotspot) {

			// process hotspot info
			var buildHotspot = {};
			buildHotspot.ID = hotspot.ID;
			buildHotspot.Label = hotspot.Label;
			buildHotspot.Notes = hotspot.Notes;
			buildHotspot.Fields = [];
			buildHotspot.TemplateGroupID = hotspot.TemplateGroupID;
			buildHotspot.TemplateGroupName = hotspot.TemplateGroupName;
			buildHotspot.TemplateGroupFixedID = hotspot.TemplateGroupFixedID;

			// process hotspot selected template info
			var template = hotspot.Template;
			if (!template)
				return;
			var buildTemplate = {};
			buildTemplate.ID = template.ID;
			buildTemplate.Label = template.Label;
			buildTemplate.SKU = template.SKU;
			buildTemplate.Price = template.Price;
			buildTemplate.SKU2 = template.SKU2;
			buildTemplate.Price2 = template.Price2;

			// get the user input fields
			var inputFields = hotspot.fields;

			// process hotspot template fields
			var templateFields = [];
			inputFields.forEach(function (inputField) {

				// store user inputted data
				var buildField = {};
				buildField.ValidField = inputField.ValidField;
				buildField.ValidationMessages = inputField.ValidationMessages;
				buildField.ValidationClasses = inputField.ValidationClasses;
				buildField.MissingReplaceableColors = inputField.MissingReplaceableColors;
				buildField.UUID = inputField.UUID;
				buildField.ActiveOptionUUID = inputField.ActiveOptionUUID;
				buildField.Label = inputField.Label;
				buildField.Required = inputField.Required;
				buildField.Type = inputField.Type;
				buildField.CustomizableItemFields = [];
				buildField.VariationID = inputField.VariationID;
				buildField.VariationLabel = inputField.VariationLabel;
				buildField.VariationQuantity = inputField.VariationQuantity;
				// handle the text based field options
				if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(buildField.Type) != -1) {
					buildField.Text = inputField.Text;
					if (inputField.NoFillColor) {
						buildField.NoFillColor = true;
						buildField.ColorID = 0;
						buildField.ColorName = '';
					}
					else {
						buildField.NoFillColor = false;
						buildField.ColorID = inputField.ColorID;
						buildField.ColorName = getColorName(inputField.ColorID);
						buildField.ColorPrice = getColorPrice(inputField.ColorID);
						buildField.ColorSku = getColorSku(inputField.ColorID);
						buildField.ColorGroupColorID = inputField.ColorGroupColorID;
						buildField.OutlineGroupColorID = inputField.OutlineGroupColorID;
						buildField.ShadowGroupColorID = inputField.ShadowGroupColorID;
					}
					buildField.OutlineColorID = inputField.OutlineColorID;
					buildField.OutlineColorName = getColorName(inputField.OutlineColorID);
					buildField.OutlineColorPrice = getColorPrice(inputField.OutlineColorID);
					buildField.OutlineColorSku = getColorSku(inputField.OutlineColorID);
					buildField.ShadowColorID = inputField.ShadowColorID;
					buildField.ShadowColorName = getColorName(inputField.ShadowColorID);
					buildField.ShadowColorPrice = getColorPrice(inputField.ShadowColorID);
					buildField.ShadowColorSku = getColorSku(inputField.ShadowColorID);
					buildField.AllowFontChoice = inputField.AllowFontChoice;

					// set field data for text fields with multiple variations
					var customizableItemFields = [];
					for (var i = 0; i < viewData.CustomizableItems.length; i++) {
						var variationGroup = viewData.CustomizableItems[i];
						for (var j = 0; j < variationGroup.length; j++) {
							// clone build field without stealing reference
							var buildFieldClone = JSON.parse(JSON.stringify(buildField));
							buildFieldClone.VariationID = variationGroup[j].VariationID;
							buildFieldClone.Quantity = variationGroup[j].Quantity;
							buildFieldClone.BuildID = variationGroup[j].BuildID;
							var variationInputField = _.findWhere(inputField.CustomizableItemFields, { BuildID: variationGroup[j].BuildID });
							buildFieldClone.Text = variationInputField.Text;// inputField.CustomizableItemFields[j].Text;
							customizableItemFields.push(buildFieldClone);
						}
					}

					buildField.CustomizableItemFields = customizableItemFields;
				}
				// handle the image field options
				else if (buildField.Type == 1) {
					buildField.ImageID = inputField.ImageID;
					buildField.ImageSKU = inputField.ImageSKU;
					buildField.ReplacedColors = [];
					inputField.ReplaceableColors.forEach(function (replaceableColor) {
						buildField.ReplacedColors.push({
							ID: replaceableColor.ID,
							Label: replaceableColor.Label,
							ColorID: replaceableColor.ColorID,
							ColorLabel: getColorName(replaceableColor.ColorID),
							Required: replaceableColor.Required,
							Price: getColorPrice(replaceableColor.ColorID),
							SKU: getColorSku(replaceableColor.ColorID),
							ImageGroupColorID: replaceableColor.ImageGroupColorID,
						});
					});

					// set field data for text fields with multiple variations
					var customizableItemFields = [];
					for (var i = 0; i < viewData.CustomizableItems.length; i++) {
						var variationGroup = viewData.CustomizableItems[i];
						for (var j = 0; j < variationGroup.length; j++) {
							// clone build field without stealing reference
							var buildFieldClone = JSON.parse(JSON.stringify(buildField));
							buildFieldClone.VariationID = variationGroup[j].VariationID;
							buildFieldClone.Quantity = variationGroup[j].Quantity;
							buildFieldClone.BuildID = variationGroup[j].BuildID;
							customizableItemFields.push(buildFieldClone);
						}
					}
					buildField.CustomizableItemFields = customizableItemFields;
				}
				else if (buildField.Type === 11) {
					buildField.SelectedOptions = inputField.SelectedOptions;

					// set field data for text fields with multiple variations
					var customizableItemFields = [];
					for (var i = 0; i < viewData.CustomizableItems.length; i++) {
						var variationGroup = viewData.CustomizableItems[i];
						for (var j = 0; j < variationGroup.length; j++) {
							// clone build field without stealing reference
							var buildFieldClone = JSON.parse(JSON.stringify(buildField));
							buildFieldClone.VariationID = variationGroup[j].VariationID;
							buildFieldClone.Quantity = variationGroup[j].Quantity;
							buildFieldClone.BuildID = variationGroup[j].BuildID;
							customizableItemFields.push(buildFieldClone);
						}
					}
					buildField.CustomizableItemFields = customizableItemFields;
				}
				else if (buildField.Type === 12) {
					var CustomImage = {
						CloudinaryId: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.CloudinaryId : null),
						Price: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.Price : 0),
						Version: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.Version : null),
						CustomChargeID: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.CustomChargeID : 0),
						SKU: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.SKU : null),
						Type: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.Type : 0),
						UseImagga: (inputField.CustomImage.CloudinaryId != null && inputField.CustomImage.CloudinaryId != '' ? inputField.CustomImage.UseImagga : false),
					};
					buildField.CustomImage = CustomImage;

					// set field data for text fields with multiple variations
					var customizableItemFields = [];
					for (var i = 0; i < viewData.CustomizableItems.length; i++) {
						var variationGroup = viewData.CustomizableItems[i];
						for (var j = 0; j < variationGroup.length; j++) {
							// clone build field without stealing reference
							var buildFieldClone = JSON.parse(JSON.stringify(buildField));
							buildFieldClone.VariationID = variationGroup[j].VariationID;
							buildFieldClone.Quantity = variationGroup[j].Quantity;
							buildFieldClone.BuildID = variationGroup[j].BuildID;
							customizableItemFields.push(buildFieldClone);
						}
					}
					buildField.CustomizableItemFields = customizableItemFields;
				}
				else if (buildField.Type === 15) {
					buildField.NetSuiteSalesPersonData = inputField.NetSuiteSalesPersonData;

					// set field data for text fields with multiple variations
					var customizableItemFields = [];
					for (var i = 0; i < viewData.CustomizableItems.length; i++) {
						var variationGroup = viewData.CustomizableItems[i];
						for (var j = 0; j < variationGroup.length; j++) {
							// clone build field without stealing reference
							var buildFieldClone = JSON.parse(JSON.stringify(buildField));
							buildFieldClone.VariationID = variationGroup[j].VariationID;
							buildFieldClone.Quantity = variationGroup[j].Quantity;
							buildFieldClone.BuildID = variationGroup[j].BuildID;
							customizableItemFields.push(buildFieldClone);
						}
					}
					buildField.CustomizableItemFields = customizableItemFields;
				}

				// get and store the field data
				var fieldDefinition = getField(template.Fields, inputField.UUID);
				buildField.EBMSLabel = fieldDefinition.EBMSLabel;
				buildField.Position = {};
				buildField.Position.FieldHorizontalOrientation = fieldDefinition.Position.FieldHorizontalOrientation;
				buildField.Position.FieldVerticalOrientation = fieldDefinition.Position.FieldVerticalOrientation;
				buildField.Position.X = fieldDefinition.Position.X;
				buildField.Position.Y = fieldDefinition.Position.Y;


				// get and store the field option data
				var optionDefinition = getFieldOption(fieldDefinition.FieldOptions, inputField.ActiveOptionUUID);
				var formatDefinition = getTextFormat(optionDefinition.TextFormats, inputField.TextFormatUUID);
				var fieldOption = {};
				fieldOption.ActiveFormatUUID = inputField.TextFormatUUID;
				fieldOption.CircleRadius = optionDefinition.CircleRadius;
				fieldOption.DistortionMagnitude = optionDefinition.DistortionMagnitude;
				fieldOption.ImageMaxHeight = optionDefinition.ImageMaxHeight;
				fieldOption.ImageMaxWidth = optionDefinition.ImageMaxWidth;
				fieldOption.Label = optionDefinition.Label;
				fieldOption.TextOutlineEnabled = optionDefinition.TextOutlineEnabled;
				fieldOption.TextShadowEnabled = optionDefinition.TextShadowEnabled;
				fieldOption.Type = optionDefinition.Type;
				fieldOption.WaveAmplitude = optionDefinition.WaveAmplitude;
				fieldOption.WavePeriod = optionDefinition.WavePeriod;
				fieldOption.WidthStretch = optionDefinition.WidthStretch;
				fieldOption.PerCharacterCost = optionDefinition.PerCharacterCost;
				fieldOption.ColorRequired = optionDefinition.FillColorRequired;
				fieldOption.OutlineRequired = optionDefinition.TextOutlineRequired;
				fieldOption.InnerTextOutlineEnabled = optionDefinition.InnerTextOutlineEnabled;
				fieldOption.ShadowRequired = optionDefinition.TextShadowRequired;
				fieldOption.CustomOptions = optionDefinition.CustomOptions;
				fieldOption.AdminImageTextUpcharge = optionDefinition.AdminImageTextUpcharge;
				fieldOption.AdminImageTextSku = optionDefinition.AdminImageTextSku;
				fieldOption.AdminImageTextImageId = optionDefinition.AdminImageTextIDs.length > 0 ? optionDefinition.AdminImageTextIDs[0] : 0;
				fieldOption.AdminImageTextEnabled = optionDefinition.AdminImageTextEnabled;
				fieldOption.FieldOptionPrice = optionDefinition.FieldOptionPrice;
				fieldOption.FieldOptionSku = optionDefinition.FieldOptionSku;

				// if the text format definition is retrieved, then include that information in the build state
				if (formatDefinition) {
					fieldOption.FontID = formatDefinition.FontID;
					var font = getFont(fieldOption.FontID);
					if (font)
						fieldOption.AddedCost = font.AddedCost;
					fieldOption.TextFormatPerCharacterCost = formatDefinition.PerCharacterCost;
					fieldOption.FontSize = formatDefinition.FontSize;
					fieldOption.MaxCharacters = formatDefinition.MaxCharacters;
					fieldOption.TextKerning = formatDefinition.TextKerning;
				}


				// include the selected option data in the build state
				buildField.SelectedOption = fieldOption;

				// update common remaining vairition fields 
				for (var i = 0; i < buildField.CustomizableItemFields.length; i++) {
					buildField.CustomizableItemFields[i].EBMSLabel = buildField.EBMSLabel;
					buildField.CustomizableItemFields[i].Position = buildField.Position;
					buildField.CustomizableItemFields[i].SelectedOption = buildField.SelectedOption;
				}

				buildHotspot.Fields.push(buildField);
			});

			// put the processed data together...
			buildTemplate.Fields = templateFields;
			buildHotspot.Template = buildTemplate;
			buildState.Hotspots.push(buildHotspot);
		});

		return buildState;
	};


	var getColorSku = function (colorID) {
		for (var i = 0; i < viewData.AvailableColors.length; i++) {
			if (viewData.AvailableColors[i].ColorID == colorID && viewData.AvailableColors[i].Sku != null)
				return viewData.AvailableColors[i].Sku;
		}
		return '';			
	}	

	var getColorPrice = function (colorID) {		
		for (var i = 0; i < viewData.AvailableColors.length; i++) {
			if (viewData.AvailableColors[i].ColorID == colorID && viewData.AvailableColors[i].Price)
				return viewData.AvailableColors[i].Price;
		}
		return 0.00;
	}

	// get the font from the view data font array
	var getFont = function (fontID) {
		for (var i = 0; i < viewData.AvailableFonts.length; i++) {
			if (viewData.AvailableFonts[i].FontID == fontID)
				return viewData.AvailableFonts[i];
		}
		return null;
	};

	// get the color name from the view data color array
	var getColorName = function (id) {
		for (var i = 0; i < viewData.AvailableColors.length; i++) {
			var color = viewData.AvailableColors[i];
			if (color.ColorID == id)
				return color.Label;
		}
		return null;
	};

	// get the field from the fields array based on the id
	var getField = function (fields, id) {
		for (var i = 0; i < fields.length; i++) {
			var field = fields[i];
			if (field.UUID == id)
				return field;
		}
		return null;
	};

	// get the field option from the field options array based on the id
	var getFieldOption = function (fieldOptions, id) {
		for (var i = 0; i < fieldOptions.length; i++) {
			var option = fieldOptions[i];
			if (option.UUID == id)
				return option;
		}
		return null;
	};

	// get the text format from the text formats array based on the id
	var getTextFormat = function (formats, id) {
		for (var i = 0; i < formats.length; i++) {
			var option = formats[i];
			if (option.UUID == id)
				return option;
		}
		return null;
	};

	return {
		processReviewStateData: processReviewStateData
	};
});

define('salespersonFieldControl', ['messaging', 'jquery', 'viewData'], function (messaging, $, viewData) {
    'use strict';

    var Timer = null;
    var textChangedTimer = -1;

    var init = function () {
        $(document).on('keyup', '.NS-Sku1', SkuKeyUp);
        $(document).on('keyup', '.NS-Sku1-Price', SkuPriceKeyUp);
        $(document).on('keyup', '.NS-Sku1-Cost', CostPriceKeyUp);
        $(document).on('keyup', '.NS-Sku1-Description', SkuDescriptionKeyUp);
        $(document).on('click', '.sku-search-result-wrapper .sku-item-wrapper', SkuListItemClick);       


        $(document).on('click', '#NetSuite-Sku2-Option', NetSuiteSku2OptionClick);
        $(document).on('keyup', '.NS-Sku2', Sku2KeyUp);
        $(document).on('keyup', '.NS-Sku2-Price', Sku2PriceKeyUp);
        $(document).on('keyup', '.NS-Sku2-Cost', Cost2PriceKeyUp);
        $(document).on('keyup', '.NS-Sku2-Description', Sku2DescriptionKeyUp);
        $(document).on('click', '.sku2-search-result-wrapper .sku-item-wrapper', Sku2ListItemClick);   
    };



    var  initializeTextFieldControl = function () {

        var $container = $('<div>').addClass('field-option-netsuite-control field-option-control NetSuite-field');

        // sku 1
        var $skuWrapper = $('<div>').addClass('field-option-netsuite-sku-wrapper');
        $skuWrapper.append($('<label>').html('NetSuite Sku'));
        $skuWrapper.append($('<input>').attr('type', 'text').addClass('form-control p-4 NS-Sku1'));
        $skuWrapper.append($('<input>').attr('type', 'hidden').addClass('NS-Sku1-Hidden'));
        $skuWrapper.append($('<div>').addClass('sku-search-result-wrapper'));
        $container.append($skuWrapper);

        var $priceWrapper = $('<div>').addClass('mt-5');
        $priceWrapper.append($('<label>').html('NetSuite Price'));
        $priceWrapper.append($('<input>').attr('type', 'text').addClass('form-control p-4 NS-Sku1-Price'));
        $priceWrapper.append($('<input>').attr('type', 'text').attr('type', 'hidden').addClass('form-control p-4 NS-Sku1-Price-Hidden'));
        $container.append($priceWrapper);

        var $costWrapper = $('<div>').addClass('mt-5');
        $costWrapper.append($('<label>').html('NetSuite Cost'));
        $costWrapper.append($('<input>').attr('readonly', 'readonly').attr('type', 'text').addClass('form-control p-4 NS-Sku1-Cost'));
        $costWrapper.append($('<input>').attr('readonly', 'readonly').attr('type', 'text').attr('type', 'hidden').addClass('form-control p-4 NS-Sku1-Cost-Hidden'));
        $container.append($costWrapper);

        var $descriptionWrapper = $('<div>').addClass('mt-5');
        $descriptionWrapper.append($('<label>').html('NetSuite Description'));
        $descriptionWrapper.append($('<textarea>').addClass('form-control p-4 NS-Sku1-Description'));
        $descriptionWrapper.append($('<textarea>').attr('type', 'hidden').addClass('form-control p-4  NS-Sku1-Description-Hidden hide'));
        $container.append($descriptionWrapper);


        // sku 2
        var $sku2Checkbox = $('<div>').addClass('mt-5 mb-3 netSuite-sku2-option-wrapper hide');
        $sku2Checkbox.append($('<input>').attr('type', 'checkbox').attr('id', 'NetSuite-Sku2-Option'));
        $sku2Checkbox.append($('<label>').html('Companion Sku?').attr('for', 'NetSuite-Sku2-Option').addClass('pl-2'));
        $container.append($sku2Checkbox);


        var $sku2Container = $('<div>').addClass('sku2-wrapper hide');


        var $sku2Wrapper = $('<div>').addClass('field-option-netsuite-sku2-wrapper sku2-item');
        $sku2Wrapper.append($('<label>').html('NetSuite Sku2').addClass('pl-2'));
        $sku2Wrapper.append($('<input>').attr('type', 'text').addClass('form-control p-4 NS-Sku2'));
        $sku2Wrapper.append($('<input>').attr('type', 'hidden').addClass('NS-Sku2-Hidden'));
        $sku2Wrapper.append($('<div>').addClass('sku2-search-result-wrapper'));
        $sku2Container.append($sku2Wrapper);


        var $price2Wrapper = $('<div>').addClass('mt-5 sku2-item');
        $price2Wrapper.append($('<label>').html('NetSuite Price2'));
        $price2Wrapper.append($('<input>').attr('type', 'text').addClass('form-control p-4 NS-Sku2-Price'));
        $price2Wrapper.append($('<input>').attr('type', 'text').attr('type', 'hidden').addClass('form-control p-4 NS-Sku2-Price-Hidden'));
        $sku2Container.append($price2Wrapper);


        var $cost2Wrapper = $('<div>').addClass('mt-5 sku2-item');
        $cost2Wrapper.append($('<label>').html('NetSuite Cost2'));
        $cost2Wrapper.append($('<input>').attr('readonly', 'readonly').attr('type', 'text').addClass('form-control p-4 NS-Sku2-Cost'));
      //  $cost2Wrapper.append($('<input>').attr('type', 'text').attr('readonly', true).addClass('form-control p-4 NS-Sku2-Cost'));
        $cost2Wrapper.append($('<input>').attr('readonly', 'readonly').attr('type', 'text').attr('type', 'hidden').addClass('form-control  NS-Sku2-Cost-Hidden'));
        $sku2Container.append($cost2Wrapper);


        var $description2Wrapper = $('<div>').addClass('mt-5  sku2-item');
        $description2Wrapper.append($('<label>').html('NetSuite Description2'));
        $description2Wrapper.append($('<textarea>').addClass('form-control p-4 NS-Sku2-Description'));
        $description2Wrapper.append($('<textarea>').attr('type', 'hidden').addClass('form-control p-4  NS-Sku2-Description-Hidden hide'));
        $sku2Container.append($description2Wrapper);


        $container.append($sku2Container);

        return $container;
    };


    var salespersonElementChangeHandler = function () {
        // wait at least 200 milliseconds before updating the notes
        clearTimeout(textChangedTimer);
        textChangedTimer = setTimeout(function () {
            messaging.reviewUpdateRequested.publish();
        }, 750);
    };




    var SkuListItemClick = function () {
        var $this = $(this);
        $this.closest('.field-option-netsuite-sku-wrapper').find('.sku-search-result-wrapper .sku-item-wrapper').removeClass('selected');
        $this.addClass('selected');
        var $fieldOption = $this.closest('.field-option-netsuite-control');

        // set sku 1
        $fieldOption.find('.NS-Sku1-Description').html($this.attr('data-description'));
        $fieldOption.find('.NS-Sku1-Description').val($this.attr('data-description'));
        $fieldOption.find('.NS-Sku1-Description-Hidden').val($this.attr('data-description'));
        $fieldOption.find('.NS-Sku1-Description-Hidden').html($this.attr('data-description'));
        $fieldOption.find('.NS-Sku1-Price').val($this.attr('data-price'));
        $fieldOption.find('.NS-Sku1-Price-Hidden').val($this.attr('data-price'));
        $fieldOption.find('.NS-Sku1-Cost').val($this.attr('data-cost'));
        $fieldOption.find('.NS-Sku1-Cost-Hidden').val($this.attr('data-cost'));
        $fieldOption.find('.NS-Sku1-Hidden').val($this.attr('data-sku'));
        $fieldOption.find('.NS-Sku1').val($this.attr('data-sku'));
        $fieldOption.addClass('valid-NS-sku');
        $fieldOption.find('.sku-search-result-wrapper').html('');    


        // need to set some sku 2 options
        if ($this.attr('data-HasCompanionSku').toLowerCase() == 'true') {
            $fieldOption.find('#NetSuite-Sku2-Option').prop('checked', true);
            $fieldOption.find('.sku2-wrapper').removeClass('hide');
        }
        $fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
        salespersonElementChangeHandler();
    };
    var Sku2ListItemClick = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');

        $fieldOption.find('.NS-Sku2-Description').html($this.attr('data-description'));
        $fieldOption.find('.NS-Sku2-Description').val($this.attr('data-description'));
        $fieldOption.find('.NS-Sku2-Description-Hidden').val($this.attr('data-description'));
        $fieldOption.find('.NS-Sku2-Description-Hidden').html($this.attr('data-description'));
        $fieldOption.find('.NS-Sku2-Price').val($this.attr('data-price'));
        $fieldOption.find('.NS-Sku2-Price-Hidden').val($this.attr('data-price'));
        $fieldOption.find('.NS-Sku2-Cost').val($this.attr('data-cost'));
        $fieldOption.find('.NS-Sku2-Cost-Hidden').val($this.attr('data-cost'));
        $fieldOption.find('.NS-Sku2-Hidden').val($this.attr('data-sku'));
        $fieldOption.find('.NS-Sku2').val($this.attr('data-sku'));
        $fieldOption.addClass('valid-NS-sku2');
        $fieldOption.find('.sku2-search-result-wrapper').html('');

        salespersonElementChangeHandler();

    };



    var SkuDescriptionKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku1-Description-Hidden').html($this.val());
        salespersonElementChangeHandler();

    };
    var Sku2DescriptionKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku2-Description-Hidden').html($this.val());
        salespersonElementChangeHandler();

    };



    var SkuPriceKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku1-Price-Hidden').val($this.val());
        salespersonElementChangeHandler();

    };
    var Sku2PriceKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku2-Price-Hidden').val($this.val());
        salespersonElementChangeHandler();

    };



    var CostPriceKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku1-Cost-Hidden').val($this.val());
        salespersonElementChangeHandler();
    };

    var Cost2PriceKeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.NS-Sku2-Cost-Hidden').val($this.val());
        salespersonElementChangeHandler(); 
    };

    var MakeNetSuiteCall = function (sku, $fieldOption, isSku2) {
        $.ajax({
            url: '/api/services/netsuite/GetBySku?sku=' + sku,
            type: 'GET',
            success: function (response) {
               
                $.ajax({
                    url: '/build/project/GetSkuSearchResultView',
                    type: 'POST',
                    data: JSON.stringify(response),
                    contentType: 'application/json',
                    success: function (htmlResponse) {
                        if (!isSku2) {
                            $fieldOption.find('.sku-search-result-wrapper').html(htmlResponse);                
                        }
                        else {
                            $fieldOption.find('.sku2-search-result-wrapper').html(htmlResponse);                    
                        }
                    },
                    error: function (error) {
                       alert('Error Getting Item Data')
                    },
                    complete: function () {
                        if (!isSku2) {
                            ClearSku1Data($fieldOption);
                            $fieldOption.find('.sku-search-result-wrapper').removeClass('opacity');
                        }
                        else {
                            ClearSku2Data($fieldOption);
                            $fieldOption.find('.sku2-search-result-wrapper').removeClass('opacity');
                        }
                       
                       
                    }
                });
                messaging.reviewUpdateRequested.publish();              
            },
            error: function (response) {
                alert('Error Getting Item Data.')
                $fieldOption.find('.ns-sku1-error-message').html(response.ErrorMessage).removeClass('Error connecting to NetSuite');
                $fieldOption.removeClass('valid-NS-sku');
            },
            complete: function () {
                salespersonElementChangeHandler();
            }
        });

    };



    var ClearSku1Data = function ($fieldOption) {
        $fieldOption.find('.NS-Sku1-Description').html('');
        $fieldOption.find('.NS-Sku1-Description-Hidden').html('');
        $fieldOption.find('.NS-Sku1-Description').val('');
        $fieldOption.find('.NS-Sku1-Description-Hidden').val('');
        $fieldOption.find('.NS-Sku1-Price').val('');
        $fieldOption.find('.NS-Sku1-Price-Hidden').val('');
        $fieldOption.find('.NS-Sku1-Cost').val('');
        $fieldOption.find('.NS-Sku1-Cost-Hidden').val('');
        $fieldOption.find('.NS-Sku1-Hidden').val('');
        $fieldOption.removeClass('valid-NS-sku');
        salespersonElementChangeHandler();

    };
    var ClearSku2Data = function ($fieldOption) {
        $fieldOption.find('.NS-Sku2-Description').html('');
        $fieldOption.find('.NS-Sku2-Description-Hidden').html('');
        $fieldOption.find('.NS-Sku2-Description').val('');
        $fieldOption.find('.NS-Sku2-Description-Hidden').val('');
        $fieldOption.find('.NS-Sku2-Price').val('');
        $fieldOption.find('.NS-Sku2-Price-Hidden').val('');
        $fieldOption.find('.NS-Sku2-Cost').val('');
        $fieldOption.find('.NS-Sku2-Cost-Hidden').val('');
        $fieldOption.find('.NS-Sku2-Hidden').val('');
        $fieldOption.removeClass('valid-NS2-sku');
        salespersonElementChangeHandler();

    };




    var SkuKeyUp = function () {
       
        var $this = $(this);
        var $fieldOption = $this.closest('.field-option-netsuite-control');
        $fieldOption.find('.sku-search-result-wrapper').html('searching....');
       // $fieldOption.find('.sku-search-result-wrapper').addClass('opacity');
        $fieldOption.find('.ns-sku1-error-message').addClass('hide');
        $fieldOption.removeClass('valid-NS-sku');
        clearTimeout(Timer);
        Timer = setTimeout(function () {
            var sku1 = $this.val();
            if (sku1 == '' || sku1 == null) {
                ClearSku1Data($fieldOption);
                $fieldOption.find('.sku-search-result-wrapper').html('');
                return;
            }
            MakeNetSuiteCall(sku1, $fieldOption, false);
        }, 3000);
    };
    var Sku2KeyUp = function () {
        var $this = $(this);
        var $fieldOption = $this.closest('.sku2-wrapper');
        $fieldOption.find('.sku2-search-result-wrapper').html('searching....');    
        //$fieldOption.find('.sku2-search-result-wrapper').addClass('opacity');
        $fieldOption.find('.ns-sku2-error-message').addClass('hide');
        $fieldOption.removeClass('valid-NS-sku2');
        clearTimeout(Timer);
        Timer = setTimeout(function () {
            var sku2 = $this.val();
            if (sku2 == '' || sku2 == null) {
                ClearSku2Data($fieldOption);
                $fieldOption.find('.sku2-search-result-wrapper').html('');
                return;
            }
            MakeNetSuiteCall(sku2, $fieldOption, true);
        }, 3000);
    };      


    var NetSuiteSku2OptionClick = function () {
        var $this = $(this);
        if ($this.is(':checked')) {
            $this.closest('.field-option-netsuite-control').find('.sku2-wrapper').removeClass('hide');
        } else {
            $this.closest('.field-option-netsuite-control').find('.sku2-wrapper').addClass('hide');
        }
        salespersonElementChangeHandler();

    };


    return {
        init: init,
        initializeTextFieldControl: initializeTextFieldControl,
    };
});					
// module for validating setting data.  
// setting data is for teh template field, field options and text formats that is stored in the db.  
// this has not hitng to do with user-inputted data
define('settingValidation', [], function () {

	// get valid options from the option array
	var getValidOptions = function (options) {
		var validOptions = [];
		// loop over each option and push it into the returned array if the option is valid
		options.forEach(function (option) {
			if (isValidFieldOption(option))
				validOptions.push(option);
		});
		return validOptions;
	};

	// return true if the option parameter is valid, false otherwise
	var isValidFieldOption = function (option) {
		if (!option.Enabled)
			return false;
		if (!option.Type)
			return false;
		// if the field option is a text field, then make sure it has valid text formats
		if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(option.Type) != -1) {
			var validTextFormats = getValidTextFormats(option.TextFormats);
			if (validTextFormats.length == 0)
				return false;
		}
		return true;
	};

	// get teh valid text formats from the formats array
	var getValidTextFormats = function (formats) {
		var validFormats = [];
		// get the valid formats from the format array
		formats.forEach(function (format) {
			if (isValidTextFormat(format))
				validFormats.push(format);
		});
		return validFormats;
	};

	// for a valid format, it just has to be enabled and have a set font
	var isValidTextFormat = function (format) {
		return format.Enabled && format.FontID && (format.FontSizeKerningBreakpoints.length > 0);
	};

	return {
		getValidOptions: getValidOptions,
		isValidFieldOption: isValidFieldOption,
		getValidTextFormats: getValidTextFormats,
		isValidTextFormat: isValidTextFormat
	};
});
// module that accesses the template data api
define('templateData', ['http'], function (http) {
	'use strict';

	// get the templates associated with the hotspot template group
	var getTemplatesForHotspotTemplateGroup = function (id) {
		var params = {
			templateGroupID: id
		};
		return http.get('/api/projects/perspectives/hotspots/groups/templates/list', params).fail(serviceError).then(processResponse);
	};

	// get the specific hotspot template for the id
	var getTemplate = function (id) {
		var params = {
			id: id
        };
        return http.get('/api/projects/perspectives/hotspots/groups/templates/details', params).fail(serviceError).then(processResponse);
	};

	// handle an error when calling the api
	var serviceError = function (error) {
		//alert(error);
	};

	// process the response from the data api
	var processResponse = function (response) {
		return response.Data;
	};

	return {
		getTemplatesForHotspotTemplateGroup: getTemplatesForHotspotTemplateGroup,
		getTemplate: getTemplate
	};
});
// module that handles the template form rendering
define('templateForm', ['messaging', 'jquery', 'viewData', 'modalView', 'templateGroups', 'imageSelectorFieldControl', 'optionFormatListing', 'fieldOptionListing', 'settingValidation', 'utils', 'commonColorClick', 'customImage', 'templateFormValidator', 'applyHotspot', 'salespersonFieldControl'],
    function (messaging, $, viewData, modalView, templateGroups, imageSelectorFieldControl, optionFormatListing, fieldOptionListing, settingValidation, utils, commonColorClick, customImage, templateFormValidator, applyHotspot, salespersonFieldControl) {

        // private modules for the module
        var currentTextContent = '';
        var currentTextColor = - 1;
        var currentTextOutlineColor = - 1;
        var currentTextShadowColor = -1;
        var currentTemplate = null;
        var notesChangedTimer = -1;



        // initialization function to set up pubsub subscriptions
        var init = function () {
            messaging.hotspotTemplateSelected.subscribe(hotspotTemplateSelected);

            // alert back
            //if (window.history && history.pushState) {
            //    if (viewData.SavedBuild == undefined || viewData.SavedBuild == null) {
            //        window.addEventListener('load', function () {
            //            history.pushState(null, null, null); // creates new history entry with same URL
            //            window.addEventListener('popstate', function () {
            //                var stayOnPage = confirm("Hey! You are about to leave and your design has not been saved. Do you wish to continue anyway?");
            //                if (stayOnPage) {
            //                    history.back();
            //                } else {
            //                    history.pushState(null, null, null);
            //                }
            //            });
            //        });
            //    }
            //}
        };


        // hotspot template selected message handler
        var hotspotTemplateSelected = function (template) {
            // get the active template group container
            var $tabGroup = templateGroups.getActiveGroupFormContainer();
            // inser the template form into the template group container
            initializeForm($tabGroup, template);
            // request a new preview
            messaging.hotspotPreviewRequested.publish();
        };

        // initialize the form for the specified template in the container
        var initializeForm = function ($container, template) {

            // update template common color groups to viewData array of all common color groups
            if (viewData.CommonColorGroups == null) {
                viewData.CommonColorGroups = [];
            }
            if (template.CommonColorGroups != null && template.CommonColorGroups != '[]') {
                var commonColorGroupsServerArray = JSON.parse(template.CommonColorGroups);
                var singleCommonColorGroupObject = commonColorGroupsServerArray[0];
                singleCommonColorGroupObject.TemplateID = template.ID;
                viewData.CommonColorGroups.push(singleCommonColorGroupObject);
            }
            // needed for color limit
            viewData.AvailableColorGroups = template.AvailableColorGroups;
            currentTemplate = template;
            currentTextContent = '';
            // empty the contents of the container
            $container.empty();
            // set the template that is currently set
            $container.attr('data-templateId', template.ID);
            $container.parents('.template-group').find('.select-template-link').html('change');
            var firstDisplayed = false;
            // get the ordered colors for the template
            template.Colors = getOrderedColors(template.ColorGroups);
            // loop over each field in the template and add it to the form
            var counter = 0;
            template.Fields.forEach(function (field, i) {
                if (field.Enabled) {
                    // create the field element for each template
                    var $field = createFieldElement(template, field, counter);
                    // append the field to the container
                    $container.append($field);

                    //add field option selection
                    var validOptions = settingValidation.getValidOptions(field.FieldOptions);
                    var $fieldOptionSelect = createFieldOptionSelectElement(field, validOptions[0]);
                    $field.find('.field-helptext-wrapper').after($fieldOptionSelect);

                    // add the the first-field class to the first visible field
                    if (!$field.hasClass('hidden-field') && (!firstDisplayed)) {
                        $field.addClass('first-field');
                        firstDisplayed = true;
                    }
                    counter++;
                }

            });
            // add the notes element to the bottom of the form          
            $container.append(createFieldNotesElement(counter));
            if (template.Fields.length == 0) {
                $container.find('.field-notes').removeClass('hide');
            }

            // add field naviagtion buttons
            $container.closest('.template-group-content').find('.navigation-wrapper').remove();
            $container.closest('.template-group-content').append(createFieldNavigationElements());

            // initialize the selected text format select option
            $container.find('input.text-format-select').each(function (index, element) {
                $(element).change();
            });

            // needed for first field in the hotspot template - if the first field is image
            messaging.initializeActiveImageField.publish();
        };

        // create the field element for the template
        var createFieldElement = function (template, field, counter) {
            var $field = $('<div>').addClass('field').attr('data-id', field.UUID).attr('data-order', counter);
            if (counter != 0) {
                $field.addClass('hide');
            }
            else {
                $field.addClass('open');
            }
            // check if hte field should be hidden and if so, then hide it
            if (shouldHideField(field))
                $field.addClass('hidden-field');
            // add the basic display info for the field like label and help text
            var $labelWrapper = $('<div>').addClass('field-label-wrapper');
            var $label = $('<span>').addClass('field-label').html(field.Label);
            var $optionCopy = $('<span>').addClass('field-requirement-type-label');
            $labelWrapper.append($label).append($optionCopy);

            // field label, help text, field option help text....
            $field.append($labelWrapper);
            var $fieldHelpTextWrapper = $('<div>').addClass('field-helptext-wrapper');
            var $fieldHelpText = $('<div>').addClass('field-helptext').html(field.HelpText);
            if (field.HelpText != '') {
                $fieldHelpText.addClass('has-copy');
            }
            $fieldHelpTextWrapper.append($fieldHelpText);
            $field.append($fieldHelpTextWrapper);
            var $fieldContainer = $('<div>').addClass('field-container');
            var $options = $('<div>').addClass('field-options');
            var selected = true;
            // get the valid options for the field
            var validOptions = settingValidation.getValidOptions(field.FieldOptions);
            // if the field has multiple field options, then it should show the select element for the field option
            if (validOptions.length > 1)
                $field.addClass('has-multiple-fieldoptions');

            // loop over each valid option for the field and add it to the form
            validOptions.forEach(function (option) {
                var $option = createFieldOption(template, field, option);
                if (selected) {
                    if (validOptions.length == 1) {
                        $option.addClass('active-field-option');
                        if (validOptions[0].Required) {
                            $optionCopy.html('(required)');
                        } else {
                            $optionCopy.html('(optional)');
                        }
                    }
                    selected = false;
                    if ($option.hasClass('has-multiple-textformats'))
                        $field.addClass('has-multiple-textformats');
                }
                $options.append($option);
            });
            $fieldContainer.append($options);
            $field.append($fieldContainer);

            //add common color group hidden textarea        
            if (viewData.CommonColorGroups != null)
            {
                var $commonColorGroup = $('<textarea>').addClass('hide CommonColorGroupUUID');
                var commonColorGroups = viewData.CommonColorGroups;
                for (var i = 0; i < commonColorGroups.length; i++)
                {
                    if (commonColorGroups[i].TemplateID == template.ID)
                    {
                        for (var j = 0; j < commonColorGroups[i].FieldUUIDs.length; j++)
                        {
                            if (commonColorGroups[i].FieldUUIDs[j] == field.UUID)
                            {
                                $commonColorGroup.html(commonColorGroups[i].UUID);
                                $field.append($commonColorGroup);

                                if ($fieldHelpTextWrapper.find('.common-color-copy').length == 0)
                                {
                                    $fieldHelpTextWrapper.append($('<div>').addClass('common-color-copy').html('The color selection for this line is linked to the color selection in other fields. If you change the color here it will change in other fields as well.'));
                                }
                                break;
                            }
                        }
                    }
                }
            }

            return $field;
        };

        // function that checks if the field should be hidden
        // requirements for hiding fields are if it has only one option, that option is an image field, and that image field has one option
        var shouldHideField = function (field) {
            if (field.FieldOptions.length == 1) {
                var option = field.FieldOptions[0];
                if ((option.Type === 1) && (option.ImageIDs.length === 1) && (option.ImageCategoryIDs.length === 0))
                    return true;
            }
            return false;
        };

        // create the field notes element
        var createFieldNotesElement = function (counter) {
            var $notesContainer = $('<div>').addClass('field-notes hide').attr('data-order', counter);
            var $labelWrapper = $('<div>').addClass('field-label-wrapper');
            var $label = $('<span>').addClass('field-label').html('Notes');
            var $optionCopy = $('<span>').addClass('field-requirement-type-label').html('(optional)');
            $labelWrapper.append($label).append($optionCopy);
            $notesContainer.append($labelWrapper);
            $notesContainer.append($('<div>').addClass('notes-container').append($('<textarea>').attr('placeholder', 'Type your notes here...').change(notesElementChangeHandler).keyup(notesElementChangeHandler)));
            return $notesContainer;
        };

        // navigation buttons for template fields
        var fieldNavigationButtonClick = function () {
            var $this = $(this);
            // get current field order
            var orderId = parseInt($('.hotspot-view.open  .template-group.open  .field.open').attr('data-order'));
            if (isNaN(orderId)) {
                // current field is the field notes
                orderId = parseInt($('.hotspot-view.open  .template-group.open  .field-notes').attr('data-order'));
            }
            // total hotspot fields (NOT including the field notes)
            var totalHotspotFields = $('.hotspot-view.open  .template-group.open  .field').length;


            if ($this.hasClass('back')) {
                if (orderId == 0) // go back and select a new hotspot template design
                {
                    if ($('.hotspot-view.open').hasClass('applied')) {
                        // indicated the current hotspot has an applied template.  Navigation changes when the temaplte is applied
                        $('.hotspot-view.open').addClass('back-to-design');
                    }

                    if ($this.closest('.menu-tab').hasClass('locked-in')) {
                        //move to pubsub
                        $('.zoom-out-link').click();
                        return;
                    }


                    // update hotspot area UI
                    var hotspotStepData = {
                        currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
                        nextStep: parseInt($('.hotspot-view.open').attr('data-step')) - 1,
                    }
                    messaging.stepChange.publish(hotspotStepData);
                    return;
                }

                if (totalHotspotFields == orderId) {
                    // at field notes/last field.  Go back to last hotspot template field       
                    if (!viewData.Project.OneViewProject) {
                        // change button text of last field on multi-view builds
                        $('.hotspot-view.open .navigation-wrapper .field-nav-button.next span.copy').html('Next');
                    }
                    $('.hotspot-view.open  .template-group.open  .field-notes').addClass('hide');
                }
                else {
                    if (viewData.Project.OneViewProject && orderId == 1) {
                        // back button is hidden on the first hotspot template field.  hide it for next field
                        $('.hotspot-view.open  .navigation-wrapper .field-nav-button.back, .hotspot-view.open .navigation-wrapper .field-nav-button.next').addClass('one-view-first-field');
                    }
                    // go back to previous field   
                    $('.hotspot-view.open  .template-group.open  .field.open').removeClass('open').addClass('hide');
                }
                // show previous field
                var $previousField = $('.hotspot-view.open  .template-group.open  .field[data-order="' + (orderId - 1) + '"]');
                if ($previousField.hasClass('hidden-field')) {
                    // skip hidden field
                    $previousField = $('.hotspot-view.open  .template-group.open  .field[data-order="' + (orderId - 2) + '"]');
                }
                $previousField.addClass('open').removeClass('hide');
                window.scrollTo(0, 0);
                //$(window).scrollTop();
            }
            else {
                orderId++;
                if (orderId > totalHotspotFields) {
                    // moved past field notes and ready to apply hotspot template
                    if (viewData.Project.OneViewProject) {
                        $('.menu .project-help-text').addClass('hide');
                        $('.template-form').addClass('hide');
                        $('.menu .review-container').removeClass('hide');
                        $('.project-ui').addClass('review-step');
                        $('.hotspot-view.open .template-group-content').addClass('hide');
                        return;
                    }
                    else {
                        $('.hotspot-view.open .navigation-wrapper .field-nav-button.next span.copy').html('Next');
                        applyHotspot.applyButtonClicked();
                        return;
                    }
                }

                //validate field before moving forward
                var valid = templateFormValidator.validateForm($('.hotspot-view.open .menu-tab .template-group.open .template-form'));
                if (!valid) {
                    return;
                }

                // move to next field
                var $nextField = $('.hotspot-view.open  .template-group.open  .field[data-order="' + orderId + '"]');
                if ($nextField.hasClass('hidden-field')) {
                    // skip hidden field
                    orderId += 1;
                    $nextField = $('.hotspot-view.open  .template-group.open  .field[data-order="' + orderId + '"]');
                }
                var $activeFieldOption = $nextField.find('.field-options .field-option.active-field-option');
                $('.hotspot-view.open  .template-group.open  .field.open').removeClass('open').addClass('hide');
                // back button is hidden on the first hotspot template field.  Unhide it for next field
                $('.hotspot-view.open  .navigation-wrapper .field-nav-button.back, .hotspot-view.open .navigation-wrapper .field-nav-button.next').removeClass('one-view-first-field');
                if (totalHotspotFields == orderId) {
                    // next field is last -- notes field
                    if (!viewData.Project.OneViewProject) {
                        window.scrollTo(0, 0);
                        // change button text of last field on multi-view builds
                        $('.hotspot-view.open .navigation-wrapper .field-nav-button.next span.copy').html('Apply');
                    }
                    $('.hotspot-view.open  .template-group.open  .field-notes').removeClass('open').removeClass('hide');
                }
                else {
                    // next field, not field notes/not last field
                    $nextField.addClass('open').removeClass('hide'); //.show("slide", { direction: "right" }, 200); //.removeClass('hide');
                    messaging.initializeActiveImageField.publish();
                    window.scrollTo(0, 0);
                    // $(window).scrollTop();
                }
            }
        }

        // create the field notes element
        var createFieldNavigationElements = function () {
            var $navigationContainer = $('<div>').addClass('navigation-wrapper');
            var $backButton = $('<div>').addClass('field-nav-button back').html('<span><img src="/images/double-arrows-back.png" /></span> <span class="copy">Back</span>').click(fieldNavigationButtonClick);
            var $nextButton = $('<div>').addClass('field-nav-button next').html('<span class="copy">Next</span> <span><img src="/images/double-arrows-forward.png" /></span>').click(fieldNavigationButtonClick);
            $navigationContainer.append($backButton).append($nextButton);
            return $navigationContainer;
        };

        // notes element change handler method
        var notesElementChangeHandler = function () {
            // wait at least 200 milliseconds before updating the notes
            clearTimeout(notesChangedTimer);
            notesChangedTimer = setTimeout(function () {
                messaging.reviewUpdateRequested.publish();
            }, 200);
        };

        // create the field options select element
        var createFieldOptionSelectElement = function (field, currentOption) {
            // get the valid options for the field
            var validOptions = settingValidation.getValidOptions(field.FieldOptions);
            var $optionDropDown = fieldOptionListing.createSelectElement(field.UUID, field.FieldOptionLabel, validOptions, currentOption, field.Label);
            return $optionDropDown;
        };

        // get the preview image for each option.  this will probably be the text distortion (or lack therof) most of the time
        var getPreviewImageForOption = function (field, option) {
            var url = '/Images/Cache/FieldOptionPreview_' + option.UUID + '.png';
            return url;
        };

        // create a field option element
        var createFieldOption = function (template, field, option) {
            var $option = $('<div>').addClass('field-option').attr('data-parentid', field.UUID).attr('data-id', option.UUID).attr('data-type', option.Type).attr('data-maxchars', option.MaxCharacters).attr('data-percharcost', option.PerCharacterCost)
                .attr('data-adminImageTextCharge', option.AdminImageTextUpcharge).attr('data-fieldOptionPrice', option.FieldOptionPrice);
            if (option.Required)
                $option.addClass('required');
            $option.removeClass('has-multiple-textformats');
            // handle the text-based field types
            if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(option.Type) != -1) {
                var validFormats = settingValidation.getValidTextFormats(option.TextFormats);
                if (validFormats.length > 1)
                    $option.addClass('has-multiple-textformats');

                // $option.append(initializeTextFieldControl(option, option.MaxCharacters, option.RestrictedCharacters, null, field));
                $option.append(initializeTextFormatSelect(field, option, validFormats));
                //added for responsive
                var $clearfix = $('<div>').addClass('clearfix');
                $option.append($clearfix);

                // add the color controls (if enabled for the field option)
                var $colorOptionControls = $('<div>').addClass('field-option-color-controls');
                if (option.FillColorEnabled)
                    $colorOptionControls.append(initializeColorControl(template, field, option, 'Color', 'Color', option.ColorIDs, 'color'));
                if (option.TextOutlineEnabled)
                    $colorOptionControls.append(initializeColorControl(template, field, option, 'Outline', 'OutlineColor', option.OutlineColorIDs, 'outline'));
                if (option.TextShadowEnabled)
                    $colorOptionControls.append(initializeColorControl(template, field, option, 'Shadow', 'ShadowColor', option.ShadowColorIDs, 'shadow'));

                $option.append($colorOptionControls);

                //admin image text price
                if (option.AdminImageTextEnabled && option.AdminImageTextUpcharge > 0) {
                    var $imageTextPrice = $('<div>').addClass('admin-image-upcharge').html('This selection has an additional cost of $' + option.AdminImageTextUpcharge);
                    $option.append($imageTextPrice);
                }

                //add field option price
                if (option.FieldOptionSku != null && option.FieldOptionSku != '') {
                    var $fieldOptionPrice = $('<div>').addClass('field-option-price').html('This selection has an additional cost of $' + option.FieldOptionPrice);
                    $option.append($fieldOptionPrice);
                }

                // add the text input last.  font, color, than text input
                $option.append(initializeTextFieldControl(option, option.MaxCharacters, option.RestrictedCharacters, null, field));
            }
            // handle the image image field type
            else if (option.Type == 1) {
                //$option.append(createFieldOptionSelectElement(field, option));
                var availableReplacementColors = getFilteredColors(template, option.ImageReplacementColorIDs);
                var $control = imageSelectorFieldControl.initializeControl(option.ImageIDs, option.ImageCategoryIDs, availableReplacementColors, field);
                $option.append($control);
            }
            else if (option.Type === 11) {
                var options = {
                    Name: option.UUID,
                    CustomOptions: option.CustomOptions,
                    AllowMutipleCustomOptions: option.AllowMutipleCustomOptions
                };
                var $control = initializeCustomSelectControl(options, option.Required);
                $option.append($control);
            }
            else if (option.Type === 12) {
                var postModel = {
                    CloudinaryId: option.CustomImage.CloudinaryId,
                    CustomImageUpcharge: option.CustomImage.Price,
                    Version: option.CustomImage.Version,
                    SKU: option.CustomImage.SKU,
                    ChargeLabel: option.CustomImage.ChargeLabel,
                    CustomChargeID: option.CustomImageChargeID,
                    IsCustomImageBackground: option.IsCustomImageBackground,
                    UseImagga: option.CustomImage.UseImagga,
                }

                // if saved build then get saved custom image data
                if (viewData.SavedBuild != null) {
                    var buildHotspots = viewData.SavedBuild.Hotspots;
                    for (var i = 0; i < buildHotspots.length; i++) {
                        var hotspotFields = buildHotspots[i].Fields;
                        for (var j = 0; j < hotspotFields.length; j++) {
                            if (option.UUID == hotspotFields[j].ActiveOptionUUID) {
                                postModel.CloudinaryId = hotspotFields[j].CustomImage.CloudinaryId;
                                postModel.Version = hotspotFields[j].CustomImage.Version;
                                postModel.UseImagga = hotspotFields[j].CustomImage.UseImagga;
                                break;
                            }
                        }
                    }
                }
                $.ajax({
                    url: '/build/project/GetCustomImageFieldOptionView',
                    type: "POST",
                    data: JSON.stringify(postModel),
                    contentType: 'application/json',
                    success: function (HTMLResponse) {
                        $option.append(HTMLResponse);
                        customImage.InitializeRecaptcha();
                        if (postModel.CustomImage != null && postModel.CustomImage.CloudinaryId != '') {
                            // initialize recaptcha  for custom image fields
                            $option.find('.custom-image-content-wrapper .custom-image-wrapper .clear-image-preview').removeClass('hide').click(customImage.clearCustomImagePreview);
                        }
                    },
                    error: function () {
                        $option.append('Error setting custom image field');
                    }
                });
            }
            else if (option.Type == 15) { 
                var $netSuiteFieldOptionControl = salespersonFieldControl.initializeTextFieldControl();
                $option.append($netSuiteFieldOptionControl);
                salespersonFieldControl.init();
            }

            //required help copy
            if (option.Required || option.FillColorRequired || option.TextOutlineRequired || option.TextShadowRequired) {
                $requiredCopy = $('<div>').html('<span>*</span> Indicates fields required to customize this location.').addClass('required-copy');
                $requiredSubCopy = $('<div>').html('Your design will be visible once you have made a selection for all required fields.').addClass('required-subcopy');
                $option.append($requiredCopy).append($requiredSubCopy);
            }
            return $option;
        };

        // initialize a custom image field option
        var initializeCustomSelectControl = function (options, isRequired) {
            // initialize the custom select field control
            var $control = $('<div>').addClass('field-option-control CustomSelectOptions-field');
            var type = "radio";
            if (options.AllowMutipleCustomOptions)
                type = 'checkbox';
            if (options.CustomOptions.length == 1) {
                var optionWrapper = $('<div>').addClass('input-wrapper');
                var checkbox = $('<input>').attr('type', 'checkbox').click(customOptionSelected).attr('data-label', options.CustomOptions[0].Label).attr('data-sku', options.CustomOptions[0].Sku).attr('data-price', options.CustomOptions[0].Price);
                var label = $('<span>').html(options.CustomOptions[0].Label);
                optionWrapper.append(checkbox).append(label).append($('<div>').addClass('clearfix'));
                $control.append(optionWrapper);
            }
            else {
                for (var i = 0; i < options.CustomOptions.length; i++) {
                    var optionWrapper = $('<div>').addClass('input-wrapper');
                    var input = $('<input>').attr('type', type).attr('name', options.Name).click(customOptionSelected).attr('data-label', options.CustomOptions[i].Label).attr('data-sku', options.CustomOptions[i].Sku).attr('data-price', options.CustomOptions[i].Price);
                    var label = $('<span>').html(options.CustomOptions[i].Label + '<br />');
                    optionWrapper.append(input).append(label).append($('<div>').addClass('clearfix'));
                    $control.append(optionWrapper);
                }
            }

            // add required asterisk
            if (isRequired) {
                $control.append($('<span>').addClass('required').html('*'));
            }
            return $control;
        };

        // initialize the text format select element
        var initializeTextFormatSelect = function (field, fieldOption, validFormats) {
            var $formatDropDown = optionFormatListing.createSelectElement(fieldOption.UUID, field.TextFormatLabel, validFormats, fieldOption);
            return $formatDropDown;
        };

        // initialize a text field control
        var initializeTextFieldControl = function (option, maxCharacters, restrictedCharacters, value, field) {
            // default the value to an empty string
            if (!value)
                value = '';
            // add the text field control elements
            var $control = $('<div>').addClass('field-option-text-control field-option-control Text-field');
            if (option.Required || option.FillColorRequired || option.TextOutlineRequired || option.TextShadowRequired) {
                var $required = $('<span>').html('*').addClass('required');
                $control.append($required);
            }
            for (var i = 0; i < viewData.CustomizableItems.length; i++) {
                // each group is a single variation/item/base garment.  Within each group is a list of the variation/item/base garment quantities.  Each of those has its own BuildID
                var customizableItemGroup = viewData.CustomizableItems[i];
                var customizableItemGroupLength = customizableItemGroup.length; //field.MultipleFields ? customizableItemGroup.length : 1;
                var $customizableGroupWrapper = $('<div>').addClass('customizable-group-wrapper');
                for (var j = 0; j < customizableItemGroup.length; j++) {            
                    // the indiviudal item to customize.  This is the variation/item/base garment
                    var customizableItem = customizableItemGroup[j];
                    //main wrapper of the UI segment
                    var $customizableItemWrapper = $('<div>').addClass('customizable-item-wrapper').attr('data-buildID', viewData.BuildID).attr('data-order', j)
                                                    .attr('data-variationID', customizableItem.VariationID)
                                                    .attr('data-quantity', customizableItem.Quantity).attr('data-label', customizableItem.Label)
                                                    .attr('data-buildID', customizableItem.BuildID);;
                    $customizableGroupWrapper.append($customizableItemWrapper);
                    // main input where the user inputs their text
                    var $textInput = $('<input>').addClass('field-option-text-input input-sm').attr('maxlength', maxCharacters).attr('placeholder', 'Text')
                        .attr('data-restrictedCharacters', restrictedCharacters).val(value).keyup(textContentChanged);
                    $customizableItemWrapper.append($textInput);
                    // text field properties
                    var $charactersStatus = $('<div>').addClass('field-option-text-control-chars');
                    $charactersStatus.append($('<span>').addClass('status-label').html('Available Characters:'));
                    $charactersStatus.append($('<span>').addClass('max-characters-value').html(maxCharacters - value.length));
                    $customizableItemWrapper.append($charactersStatus);

                    // add restricted characters UI message
                    if (restrictedCharacters != null && restrictedCharacters != '') {
                        var $restrictedCharacters = $('<div>').addClass('field-option-text-restricted-characters').html('Restricted Characters: ' + utils.stringToCSV(restrictedCharacters, ','));
                        $customizableItemWrapper.append($restrictedCharacters);
                        $textInput.click(textContentChanged);
                    }



                    // this will initiate the action neeeded to update the hotspot template preview.  Needed for multiple builds
                    $textInput.click(textContentChanged);
                    
                    // UI/UX update for multiple builds
                    if (field.MultipleFields && (customizableItemGroup.length > 1 || viewData.CustomizableItems.length > 1)) {
                        // add gray background to customizable item group divs
                        $customizableGroupWrapper.addClass('multiple-fields');

                        // add orange border around textbox when focus
                        $customizableItemWrapper.addClass('multiple-fields');       
                    }                    

                    if (j == 0 && field.MultipleFields && (customizableItemGroup.length > 1 || viewData.CustomizableItems.length > 1)) {
                        // add label to the first varition of the set
                        var $variationLabel = $('<div>').addClass('customizable-item-label')
                            .html(customizableItem.Label + ' (' + viewData.CustomizableItems[i].length + ')').click(VariationLabelClick);
                        $customizableGroupWrapper.prepend($variationLabel);

                    }
                    if (i == 0 && j == 0 && (customizableItemGroup.length > 1 || viewData.CustomizableItems.length > 1)) {
                        // add checkbox that will apply text input to other variations.  Added to just the first item listed
                        $customizableItemWrapper.addClass('apply-all');
                        var $applyAllCheckbox = $('<input>').attr('type', 'checkbox').addClass('apply-all-check-box').change(applyAllTextBoxChange);
                        var $applyAllCheckboxLabel = $('<span>').html('Use value for all variations').addClass('apply-all-label');
                        $customizableItemWrapper.find('input.field-option-text-input').after($applyAllCheckboxLabel).after($applyAllCheckbox);
                    }
                 

                    // UI/ UX updates. -----IS this really needed????
                    if ((!field.MultipleFields && j > 0) || (!field.MultipleFields && j >= 0 && i > 0)) {       
                        $customizableItemWrapper.addClass('hide');

                        // remove gray background to customizable item group divs
                        $customizableGroupWrapper.removeClass('multiple-fields');

                        // remove orange border around textbox when focus
                        $customizableItemWrapper.removeClass('multiple-fields');

                        $applyAllCheckbox.prop('checked', true).addClass('hide');
                        $applyAllCheckboxLabel.addClass('hide');
                    }

                    $control.append($customizableGroupWrapper);


                    //// 1 variation cannot not be customed on quantity level.  so escape the loop.
                    //// without this break then a single multiview-build with a quantity of 10 would show 10 textboxes.  There should be just 1 
                    //if (viewData.CustomizableItems.length  /*loopCount*/ == 1) {
                    //   // break;
                    //}
                }
            }

    
            return $control;
        };

        // used when more than 1 variation in the build.  It will populate other options with one common field
        var applyAllTextBoxChange = function () {
            var $this = $(this);
            if ($this.is(':checked')) {
                // get global value
                var value = $this.closest('.customizable-item-wrapper').find('input.field-option-text-input').val();
                // apply that value to the other variations
                $this.closest('.field-option-text-control').find('.customizable-item-wrapper').not('.apply-all').find('input.field-option-text-input').val(value);
                //get updated char count
                var charValueRemaining = $this.closest('.customizable-item-wrapper').find('.max-characters-value').html();
                // apply updated char count to the other variations
                $this.closest('.field-option-text-control').find('.max-characters-value').html(charValueRemaining);
            }
        };

        // used when more than 1 variation in the build.  This will trigger an event that will update the hotspote template preview of the current vaitaion in the UI
        var VariationLabelClick = function () {
            var $this = $(this);
            $this.closest('.customizable-item-wrapper').find('input.field-option-text-input').click();
        };

        // initailize the color control element
        // this could probably be changed to accept a javascript options instead of a bunch of parameters
        var initializeColorControl = function (template, field, option, label, name, availableColorIDs, type) {
            // default the allow no fill property of the option parameter
            if (!option.AllowNoFill)
                option.AllowNoFill = false;
            // add the text color field control elements
            var $control = $('<div>').addClass('field-option-color-control field-option-control ' + name + '-field').attr('data-type', type);
            var $colorControlWrapper = $('<div>').addClass('color-control-wrapper');
            var $labelWrapper = $('<div>').addClass('field-option-label');
            var $label = $('<div>').html(label);
            var required = $('<span>').addClass('required').html('*');


            if (label == 'Color' && option.FillColorRequired) {
                $label.append(required);
            }
            else if (label == 'Outline' && option.TextOutlineRequired) {
                $label.append(required);
            } else if (label == 'Shadow' && option.TextShadowRequired) {
                $label.append(required);
            }
            $labelWrapper.append($label);
            $colorControlWrapper.append($labelWrapper);

            var textColor = label.toLowerCase() == 'color';
            // add the color form control element
            $colorControlWrapper.append(initializeColorFormControl(template, field, option, label, availableColorIDs));
            // if no fill color is allowed and there are this is a fill color control, then add a checkbox that lets the customer select no fill
            if (option.AllowNoFill && textColor) {
                var $noFillLabel = $('<label>').addClass('no-fill-label');
                $noFillLabel.append($('<input>').attr('type', 'checkbox').change(noFillClicked));
                $noFillLabel.append($('<span>').html('No Fill Color'));
                $colorControlWrapper.append($noFillLabel);
            }
            $control.append($colorControlWrapper);
            return $control;
        };

        // initialize the color form control (this is the actual color options container)
        var initializeColorFormControl = function (template, field, option, label, filteredColorIDs) {

            // check if common color group and get common color group selected colors
            if (viewData.CommonColorGroups != null) {
                var commonColorGroups = viewData.CommonColorGroups;
                for (var i = 0; i < commonColorGroups.length; i++) {
                    if (commonColorGroups[i].TemplateID == template.ID) {
                        for (var j = 0; j < commonColorGroups[i].FieldUUIDs.length; j++) {
                            if (commonColorGroups[i].FieldUUIDs[j] == field.UUID) {
                                filteredColorIDs = utils.arrayToCSV(commonColorGroups[i].ColorIDs);

                            }
                        }
                    }
                }
            }

            // get the filtered colors for the field from the colors allowed by the template
            var colors = getFilteredColors(template, filteredColorIDs);
            var $field = $('<div>').addClass('color-field-container');
            var $input = $('<input>').attr('type', 'hidden').change(colorValueChanged);
            $field.append($input);
            // if the colors are for the outline of text and no fill color is enabled, then default to the first outline color
            if ((label.toLowerCase() == 'outline') && (colors.length > 0) && (!option.FillColorEnabled))
                $input.val(colors[0].ColorID);

            // loop over the colors for the template and display it
            colors.forEach(function (color, index) {
                var $option = $('<div>').addClass('color-option').css('background-color', '#' + color.HexadecimalCode).click(colorClicked);
                $option.append($('<div>').addClass('color-option-label callout bottom-left').html(color.Label));

                var $optionContainer = $('<div>').addClass('color-option-border').attr('data-id', color.ColorID).attr('data-sku', color.Sku).attr('data-price', color.Price).append($option).attr('data-group-id', color.ColorGroupIDs);
                $field.append($optionContainer);
            });
            return $field;
        };

        // get the colors from each group as one array ordered how the admin specified
        var getOrderedColors = function (groups) {
            var colors = [];
            groups.forEach(function (group) {
                group.Colors.forEach(function (color) {
                    colors.push(color);
                });
            });
            return colors;
        };

        // get the colors available for the template and filter based on a csv integer array
        var getFilteredColors = function (template, filteredColorIDs) {
            var colorIDArray = utils.csvToIntegerArray(filteredColorIDs);
            var colorGroupIDs = template.ColorGroupIDs;
            var availableColors = viewData.AvailableColors;
            // handle multiple color groups
            if (colorGroupIDs.length > 0) {
                availableColors = [];
                viewData.AvailableColors.forEach(function (color) {
                    var assignedColorGroupIDs = utils.csvToIntegerArray(color.ColorGroupIDs);
                    var intersection = utils.arrayIntersect(colorGroupIDs, assignedColorGroupIDs);
                    if (intersection.length > 0)
                        availableColors.push(color);
                });
            }
            // get the filtered colors from the available colors
            var filteredColors = [];
            availableColors.forEach(function (color, index) {
                if ((colorIDArray.length > 0) && (colorIDArray.indexOf(color.ColorID) == -1)) {
                    return;
                }
                // this bit of code needed to order the colors
                var templateColor = _.findWhere(template.Colors, { ID: color.ColorID });
                color.OrderIndex = templateColor.OrderIndex;
                filteredColors.push(color);
            });
            //sort colors
            filteredColors = _.sortBy(filteredColors, "OrderIndex");

            // get color Upcharge
            filteredColors = addColorUpcharge(template, filteredColors);

            return filteredColors;
        };

        // add color upcharge
        var addColorUpcharge = function (template, filteredColors) {
            for (var i = 0; i < template.ColorGroups.length; i++) {
                var colorsInColorGroup = template.ColorGroups[i].Colors;

                for (var j = 0; j < colorsInColorGroup.length; j++) {
                    for (var k = 0; k < filteredColors.length; k++) {
                        if (colorsInColorGroup[j].ID == filteredColors[k].ColorID) {
                            if (colorsInColorGroup[j].Sku != null) {
                                filteredColors[k].Sku = colorsInColorGroup[j].Sku;
                                filteredColors[k].Price = colorsInColorGroup[j].Price;
                            }
                        }
                    }
                }
            }
            return filteredColors;
        }

        // event handlers
        var customOptionSelected = function () {
            messaging.hotspotPreviewRequested.publish();
        };

        // text format select option click handler
        var textFormatSelectOptionClicked = function () {
            var $this = $(this);
            var $parent = $this.parent();
            var id = $this.attr('data-id');
            var $input = $parent.find('input');
            $this.siblings().removeClass('selected');
            $this.removeClass('selected').addClass('selected');
            $input.val(id).change();
        };

        // field option select option click handler
        var fieldOptionSelectOptionClicked = function () {
            var $this = $(this);
            var $parent = $this.parent();
            var id = $this.attr('data-id');
            var $input = $parent.find('input');
            $this.siblings().removeClass('selected');
            $this.removeClass('selected').addClass('selected');
            $input.val(id).change();
        };

        // event handler when text content has changed
        var textContentChanged = function () {
            var $input = $(this);

            //set active field for current hotspot
            var order = $input.closest('.customizable-item-wrapper').attr('data-order');
            $input.closest('.field-option').find('.customizable-item-wrapper').removeClass('active');
            viewData.BuildID = $input.closest('.customizable-item-wrapper').attr('data-buildID');
            // update other text fields in the template so the preview image shows the preview of 1 variation.  Otherwise the preview will show a mixed variation desgin image.
            $('.hotspot-view.open .field-option-text-control.Text-field .customizable-item-wrapper').removeClass('active');
            $('.hotspot-view.open .field-option-text-control.Text-field .customizable-item-wrapper[data-buildid="' + viewData.BuildID + '"][data-order="' + order + '"]').addClass('active');
           
            //update other fields in applied hotspots.  So a mixed variation preview is not generated
            if (viewData.CustomizableItems.length > 1) {
                $('.hotspot-view.applied').each(function () {
                    $('.field-option-text-control.Text-field .customizable-item-wrapper').removeClass('active');
                    $('.field-option-text-control.Text-field .customizable-item-wrapper[data-buildid="' + viewData.BuildID + '"]').addClass('active');
                });
            }         

            if ($input.hasClass('force-uppercase'))
                $input.val($input.val().toUpperCase());
            var $fieldControl = $input.closest('.customizable-item-wrapper');// $input.parents('.field-option-text-control');
            var $count = $fieldControl.find('.max-characters-value');
            var maxCharacters = parseInt($input.attr('maxlength'));
            var length = $input.val().length;
            var difference = maxCharacters - length;
            if (difference < 0) {
                var substring = $input.val().substring(0, maxCharacters);
                $input.val(substring);
                $count.html(0);
            } else {
                $count.html(maxCharacters - length);

            }
            messaging.hotspotPreviewRequested.publish();
            currentTextContent = $input.val();
            var $fieldOption = $input.parents('.field-option');
            $fieldOption.siblings().each(function (index, element) {
                $(element).find('.Text-field input').val($input.val());
            });

            // if there is just one color then auto select the color
            var $colorOptions = $input.closest('.field').find('.field-option.active-field-option .Color-field .color-control-wrapper .color-option-border');
            if ($colorOptions.length == 1) {
                if (!$colorOptions.hasClass('selected')) {
                    $colorOptions.find('.color-option').click();
                }
            }

            //remove restricted characters
            var restrictedCharactersArray = $input.attr('data-restrictedCharacters');          
            if (restrictedCharactersArray != null && restrictedCharactersArray != '') {
                var finalInputValue = '';
                var inputValueArray = $input.val().split('');                
                for (var i = 0; i < inputValueArray.length; i++) {
                    if (restrictedCharactersArray.indexOf(inputValueArray[i].toLowerCase()) == -1 && restrictedCharactersArray.indexOf(inputValueArray[i].toUpperCase()) == -1) {
                        finalInputValue += inputValueArray[i];
                        $fieldControl.find('.field-option-text-restricted-characters').css('color', '#858585');
                    } else {
                        $fieldControl.find('.field-option-text-restricted-characters').css('color', '#ff0000');
                    }
                }
                $input.val(finalInputValue);
                $count.html(maxCharacters - $input.val().length);
            }

            // apply all check box.  apply text value to all variations - if there is more than 1 build
            if ($input.closest('.customizable-item-wrapper').hasClass('apply-all') && $input.closest('.customizable-item-wrapper').find('.apply-all-check-box').is(':checked'))
            {
                var charValueRemaining = $input.closest('.customizable-item-wrapper').find('.max-characters-value').html();
                $input.closest('.field-option-text-control').find('.customizable-item-wrapper').not('.apply-all').find('input.field-option-text-input').val($input.val());
                $input.closest('.field-option-text-control').find('.max-characters-value').html(charValueRemaining);
            }
        };

        // event handler when a color is clicked
        var colorClicked = function () {
            var $this = $(this).parent();
            var $field = $this.parents('.field-option-color-control');
            if ($field.hasClass('read-only') || $this.hasClass('inactive'))
                return;

            var colorID = parseInt($this.attr('data-id'));
            // $this.closest('.color-field-container').find('input[type=hidden]').val(colorID);
            $this.toggleClass('selected');
            $this.siblings('.selected').removeClass('selected');

            if ($this.closest('.color-field-container').find('.color-option-border.selected').length > 0) {
                $this.closest('.color-field-container').find('input[type=hidden]').val(colorID);
            }
            else {
                colorID = null;
                $this.closest('.color-field-container').find('input[type=hidden]').val('');
            }


            // not sure what this line is for
            $field.find('input[type=checkbox]').prop('checked', false);



            // common color click functions
            var commonColorGroupUUID = $this.closest('div.field').find('.CommonColorGroupUUID').html();
            commonColorClick.CommonColorGroup(commonColorGroupUUID, $field.attr('data-type'), colorID, $this.hasClass('selected'));
            commonColorClick.ColorLimitDisplay();


            // display color price if exist
            var colorPrice = $this.attr('data-price');
            var fieldUUID = $this.closest('div.field').attr('data-id');
            var $colorMessageParent = $(this).closest('.color-control-wrapper');
            if (colorPrice != null) {
                var colorLabel = $this.find('div.color-option-label').html();
                var $colorPriceMessageDiv = $('<div>').addClass('color-price-message').html(colorLabel + ' has an added cost of $' + colorPrice);
                $colorMessageParent.find('.color-price-message').remove();
                $colorMessageParent.append($colorPriceMessageDiv);
            }
            else {
                $colorMessageParent.find('.color-price-message').remove();
            }

            messaging.hotspotPreviewRequested.publish();
        }

        // no fill color checkbox clicked
        var noFillClicked = function () {
            var $this = $(this);
            var $control = $this.parents('.field-option-color-control');
            $control.find('.color-option-border.selected').removeClass('selected');
            $control.find('input[type=hidden]').val('');
            messaging.hotspotPreviewRequested.publish();
        };

        // color value changed handler
        var colorValueChanged = function () {
            var $this = $(this);
            var $colorControl = $this.parents('.field-option-color-control');
            var $colorOptions = $colorControl.find('.color-option-border');
            $colorOptions.removeClass('selected');
            $colorControl.find('.color-option-border[data-id=' + $this.val() + ']').addClass('selected');
        };

        return {
            init: init,
            initializeForm: initializeForm,
        };
    });
// WAS module to validate a template form.  With the repsosnive update this module now checks if a hotspot template field is valid - one field at a time (When the "Next" button is clicked)
define('templateFormValidator', ['jquery', 'validationAlert', 'loadedTemplates', 'utils', 'viewData', 'underscore'],
    function ($, validationAlert, loadedTemplates, utils, viewData, _) {

        // text types id array
        var fieldOptionTextTypes = [2, 3, 4, 5, 6, 7, 8, 9, 13, 14];

        // form that validates a form ------- has been updated for resposnsive to review the current open field
        var validateForm = function ($form) {
            var validForm = true;

            // don't require any validation when saving a build template
            //if (viewData.IsDepartmentManagerEnvironment) {
            //    return validForm;
            //}

            var messages = [];
            // get the template id and load it
            var templateId = parseInt($form.attr('data-templateid'));
            var template = loadedTemplates.getTemplate(templateId);
            var activeFieldId = $form.find('.field.open').attr('data-id');
            var field = _.find(template.Fields, function (field) { return field.UUID === activeFieldId });
            $('.field-option').removeClass('incomplete-field fill-color-missing outline-color-missing shadow-color-missing format-missing font-missing');
            $('.field-option-image-color-control').removeClass('replaceable-color-missing');
            $('.field-option .customizable-item-wrapper input.field-option-text-input').removeClass('input-text-missing');


            // check if active field option is valid if it is required
            var $fieldOption = $('.hotspot-view.open .field.open .field-option.active-field-option.required');
            if ($fieldOption.length == 1 && !viewData.IsDepartmentManagerEnvironment) {
                var $field = $fieldOption.parents('.field');
                var fieldLabel = $field.find('.field-label').html();
                var type = parseInt($fieldOption.attr('data-type'));
                // handle text field types
                if (fieldOptionTextTypes.indexOf(type) != -1) {
                    var $textControl = $fieldOption.find('.field-option-text-control');
                    var text = $textControl.find('input.field-option-text-input').val();  //$textControl.children('input').val() --> this was the TFS value before NS
                    // if the text is empty then show a message about it being a required field
                    if (text.trim() == '') {
                        validForm = false;
                        messages.push('"' + fieldLabel + '" is a required field.');
                        $fieldOption.addClass('incomplete-field');
                    }
                }
                // handle image field types
                else if (type == 1) {
                    var $imageControl = $fieldOption.find('.field-option-image-control');
                    var imageID = parseInt($imageControl.children('input').val());
                    // if the image isn't selected, then show a message about it being a required field
                    if (isNaN(imageID) || (imageID <= 0)) {
                        validForm = false;
                        messages.push('"' + fieldLabel + '" is a required field.');
                        $fieldOption.addClass('incomplete-field');
                    }

                    // loop over each replaceable color and add it to the form state, and also validate it
                    var $replaceableColors = $fieldOption.find('.Image-field .field-color-controls .field-option-color-control');
                    $replaceableColors.each(function (index, element) {
                        var $element = $(element);
                        var $colorInput = $element.find('input[type=hidden]');
                        // initialize the replaceable color javascript object
                        var replaceableColor = {
                            ID: parseInt($element.attr('data-replaceableid')),
                            Label: $element.attr('data-label'),
                            ColorID: utils.parseIntOrZero($colorInput.val()),
                            Required: $element.hasClass('required'),
                            ImageGroupColorID: $(element).find('.color-option-border.selected').attr('data-group-id'),
                        };

                        if (replaceableColor.ImageGroupColorID == null) {
                            replaceableColor.ImageGroupColorID = $(element).find('.color-option-border[data-id="' + replaceableColor.ColorID + '"]').attr('data-group-id');
                        }


                        // this is validation logic for specific replaceable colors
                        if (replaceableColor.Required && (replaceableColor.ColorID === 0)) {
                            validForm = false;//state.ValidField = false;
                            var validationLabel = replaceableColor.Label;
                            // this bit checks to see if the replaceable color already has color in its name so that it doesn't double up in the validation message
                            if (validationLabel.toLowerCase().indexOf('color') === -1)
                                validationLabel += ' Color';
                            messages.push('"' + field.Label + '" must have a Color selected.');
                            $fieldOption.addClass('incomplete-field');
                            $(element).addClass('replaceable-color-missing');
                        }
                    });

                }
                // handle custom select
                else if (type == 11 && !viewData.IsDepartmentManagerEnvironment) {
                    var $options = $fieldOption.find('.CustomSelectOptions-field');
                    var selectedOptions = $options.find('input:checked');
                    if (selectedOptions.length == 0) {
                        validForm = false;
                        messages.push('"' + fieldLabel + '" is a required field.');
                        $fieldOption.addClass('incomplete-field');
                    }
                }
                // handle custom images
                else if (type == 12 && !viewData.IsDepartmentManagerEnvironment) {
                    var cloudinaryId = $fieldOption.find('input.custom-image-id').val();
                    if (cloudinaryId == '') {
                        validForm = false;
                        messages.push('"' + fieldLabel + '" is a required field.');
                        $fieldOption.addClass('incomplete-field');
                    }
                }
                // handle NS sku
                else if (type == 15 /*&& !viewData.IsDepartmentManagerEnvironment*/) {

                    //sku 1
                    var nsSku1 = $fieldOption.find('.NS-Sku1-Hidden').val();
                    var isNSSku1Valid = false;
                    if (nsSku1 == '') {
                        validForm = false;
                        messages.push('NetSuite Sku is a required field');
                        $fieldOption.find('.NS-Sku1').addClass('incomplete-field');
                    }
                    else if (nsSku1 != '' && !$fieldOption.find('.field-option-netsuite-control').hasClass('valid-NS-sku'))
                    {
                        validForm = false;
                        messages.push(nsSku1 + ' is not a valid NetSuite Sku');
                        $fieldOption.find('.NS-Sku1').addClass('incomplete-field');
                    }
                    else {
                        $fieldOption.find('.NS-Sku1').removeClass('incomplete-field');
                    }

                    //if (nsSku1 != '' && !$fieldOption.find('.field-option-netsuite-control').hasClass('valid-NS-sku')) {
                    //    validForm = false;
                    //    messages.push(nsSku1 + ' is not a valid NetSuite Sku');
                    //    $fieldOption.find('.NS-Sku1').addClass('incomplete-field');
                    //}
                    //else {
                    //    $fieldOption.find('.NS-Sku1').removeClass('incomplete-field');
                    //}

                    var nsSku1Price = $fieldOption.find('.NS-Sku1-Price-Hidden').val();
                    if (nsSku1Price == '') {
                        validForm = false;
                        messages.push('NetSuite Price is a required field.');
                        $fieldOption.find('.NS-Sku1-Price').addClass('incomplete-field');
                    }
                    else if (nsSku1Price != '' && !$.isNumeric(nsSku1Price))
                    {
                        validForm = false;
                        messages.push(nsSku1Price + ' is not a vlid Price.');
                        $fieldOption.find('.NS-Sku1-Price').addClass('incomplete-field');
                    }
                    else {
                        $fieldOption.find('.NS-Sku1-Price').removeClass('incomplete-field');
                    }

                    //if (nsSku1Price != '' && !$.isNumeric(nsSku1Price)) {
                    //    validForm = false;
                    //    messages.push(nsSku1Price + ' is not a vlid Price.');
                    //    $fieldOption.find('.NS-Sku1-Price').addClass('incomplete-field');
                    //}
                    //else {
                    //    $fieldOption.find('.NS-Sku1-Price').removeClass('incomplete-field');
                    //}

                    var nsSku1Description = $fieldOption.find('.NS-Sku1-Description-Hidden').html();
                    if (nsSku1Description == '') {
                        validForm = false;
                        messages.push('NetSuite Description is a required field.');
                        $fieldOption.find('.NS-Sku1-Description').addClass('incomplete-field');
                    }
                    else {
                        $fieldOption.find('.NS-Sku1-Description').removeClass('incomplete-field');
                    }


                    // sku 2
                    var hasCompanionSku = $fieldOption.find('#NetSuite-Sku2-Option').is(':checked');
                    if (hasCompanionSku) {
                        var nsSku2 = $fieldOption.find('.NS-Sku2-Hidden').val();
                        if (nsSku2 == '') {
                            validForm = false;
                            messages.push('NetSuite Sku2 is a required field');
                            $fieldOption.find('.NS-Sku2').addClass('incomplete-field');
                        }
                        else if (nsSku2 != '' && !$fieldOption.find('.field-option-netsuite-control').hasClass('valid-NS-sku2'))
                        {
                            validForm = false;
                            messages.push(nsSku2 + ' is not a valid NetSuite Sku');
                            $fieldOption.find('.NS-Sku2').addClass('incomplete-field');
                        }
                        else {
                            $fieldOption.find('.NS-Sku2').removeClass('incomplete-field');
                        }

                        //if (nsSku2 != '' && !$fieldOption.find('.field-option-netsuite-control').hasClass('valid-NS-sku2')) {
                        //    validForm = false;
                        //    messages.push(nsSku2 + ' is not a valid NetSuite Sku');
                        //    $fieldOption.find('.NS-Sku2').addClass('incomplete-field');
                        //}
                        //else {
                        //    $fieldOption.find('.NS-Sku2').removeClass('incomplete-field');
                        //}

                        var nsSku2Price = $fieldOption.find('.NS-Sku2-Price-Hidden').val();
                        if (nsSku2Price == '') {
                            validForm = false;
                            messages.push('NetSuite Price2 is a required field.');
                            $fieldOption.find('.NS-Sku2-Price').addClass('incomplete-field');
                        }
                        else if (nsSku2Price != '' && !$.isNumeric(nsSku2Price)) {
                            validForm = false;
                            messages.push(nsSku2Price + ' is not a vlid Price.');
                            $fieldOption.find('.NS-Sku2-Price').addClass('incomplete-field');
                        }
                        else {
                            $fieldOption.find('.NS-Sku2-Price').removeClass('incomplete-field');
                        }

                        //if (nsSku2Price != '' && !$.isNumeric(nsSku2Price)) {
                        //    validForm = false;
                        //    messages.push(nsSku2Price + ' is not a vlid Price.');
                        //    $fieldOption.find('.NS-Sku2-Price').addClass('incomplete-field');
                        //}
                        //else {
                        //    $fieldOption.find('.NS-Sku2-Price').removeClass('incomplete-field');
                        //}

                        var nsSku2Description = $fieldOption.find('.NS-Sku2-Description-Hidden').html();
                        if (nsSku2Description == '') {
                            validForm = false;
                            messages.push('NetSuite Description2 is a required field.');
                            $fieldOption.find('.NS-Sku2-Description').addClass('incomplete-field');
                        } 
                        else {
                            $fieldOption.find('.NS-Sku2-Description').removeClass('incomplete-field');
                        }
                    }

                }
            }


            // fill color and replaceable color requirement checks           
            var activeOptionId = $form.find('.field.open .field-option.active-field-option').attr('data-id');
            var allFieldOptions = _.flatten(_.pluck(template.Fields, 'FieldOptions'));
            var option = _.find(allFieldOptions, function (fieldOption) { return fieldOption.UUID === activeOptionId });           
            // no option is selected - that MAY be ok...
            if (option == null || option == undefined) {
                // more than 1 field option which are all required but user has not selected one yet
                var $allFieldOptions = $('.field.open .field-option');
                var $allRequiredfieldOptions = $('.field.open .field-option.required').not('active-field-option');
                if ($allFieldOptions.length == $allRequiredfieldOptions.length) {
                    $('.field.open .field-option-select-button').addClass('incomplete-field')
                    validForm = false;
                    messages.push('At least 1 field option is a required.');   
                    validationAlert.showAlert(messages);
                    return validForm;
                } else {
                    return validForm;
                }

            }

            var $activeOption = $('.hotspot-view.open .field.open .field-option[data-id=' + option.UUID + ']');
            // handle text fields
            if (fieldOptionTextTypes.indexOf(option.Type) != -1 && !viewData.IsDepartmentManagerEnvironment) {
                var $textControl = $activeOption.find('.field-option-text-control');

                // added for multiple builds.  All fields must be required if there is more than 1 variation
                var allRequired = false;
                $textControl.find('.field-option-text-input').each(function () {
                    var text = $(this).val();
                    if (text != '')
                        allRequired = true;
                });

                $textControl.find('.field-option-text-input').each(function () {
                    var text = $(this).val();// $textControl.children('input').val();
                    if (text.trim() === '' && !$activeOption.hasClass('required') && !allRequired/*&& $textControl.find('.field-option-text-input').length == 1*/) {
                        if (!validForm) {
                            validationAlert.showAlert(messages);
                        }
                        return validForm;
                    }
                    // check for required colors
                    var colors = getTextFieldOptionColorSettings($activeOption.find('.field-option-color-controls'));
                    //if there are 2 field options only check the validation for the one current selected
                    var activeFieldOptionDiv = $('.field-option[data-id=' + option.UUID + ']');
                    var activeFieldOptionID = '';
                    if (activeFieldOptionDiv.hasClass('active-field-option')) {
                        activeFieldOptionID = option.UUID;
                    }
                    if (option.FillColorRequired && (colors.ColorID == 0) && activeFieldOptionID == option.UUID) {
                        validForm = false;
                        messages.push('"' + option.Label + '" must have a Color selected.');
                        $activeOption.addClass('fill-color-missing');
                    }
                    if (option.TextOutlineRequired && (colors.OutlineColorID == 0) && activeFieldOptionID == option.UUID) {
                        validForm = false;
                        messages.push('"' + option.Label + '" must have an Outline selected.');
                        $activeOption.addClass('outline-color-missing');
                    }
                    if (option.TextShadowRequired && (colors.ShadowColorID == 0) && activeFieldOptionID == option.UUID) {
                        validForm = false;
                        messages.push('"' + option.Label + '" must have a Shadow selected.');
                        $activeOption.addClass('shadow-color-missing');
                    }
                    // check a field option is selected
                    if ($activeOption.find('.option-select-dropdown-container input').val() == '') {
                        validForm = false;
                        messages.push('"' + option.Label + '" must have a Format selected.');
                        $activeOption.addClass('format-missing');
                    }
                    // check a font is selected
                    if ($activeOption.find('.format-select-dropdown-container input').val() == '') {
                        validForm = false;
                        messages.push('"' + option.Label + '" must have a Font selected.');
                        $activeOption.addClass('font-missing');
                    }
                    // check if multiple field option and if required.  If 1 variation input is entered than they all are required
                    if (text == "" && allRequired) {
                        validForm = false;
                        messages.push('If 1 variation is customized than all variations are required to be customized');
                        $(this).addClass('input-text-missing');
                    }
                });
            }

            // handle image replacable colors
            if (option.Type == 1) {
                var $replaceableColors = $activeOption.find('.Image-field .field-color-controls .field-option-color-control');
                $replaceableColors.each(function (index, element) {
                    var $element = $(element);
                    var $colorInput = $element.find('input[type=hidden]');
                    // initialize the replaceable color javascript object
                    var replaceableColor = {
                        ID: parseInt($element.attr('data-replaceableid')),
                        Label: $element.attr('data-label'),
                        ColorID: parseIntOrZero($colorInput.val()),
                        Required: $element.hasClass('required'),
                        ImageGroupColorID: $(element).find('.color-option-border.selected').attr('data-group-id'),
                    };
                    if (replaceableColor.ImageGroupColorID == null) {
                        replaceableColor.ImageGroupColorID = $(element).find('.color-option-border[data-id="' + replaceableColor.ColorID + '"]').attr('data-group-id');
                    }


                    // this is validation logic for specific replaceable colors
                    if (replaceableColor.Required && (replaceableColor.ColorID === 0)) {
                        validForm = false;
                        var validationLabel = replaceableColor.Label;
                        // this bit checks to see if the replaceable color already has color in its name so that it doesn't double up in the validation message
                        if (validationLabel.toLowerCase().indexOf('color') === -1)
                            validationLabel += ' Color';
                        messages.push('"' + field.Label + '" must have a Color selected.');
                        $activeOption.addClass('fill-color-missing');
                        // add to dummy color selction
                        $activeOption.find('.field-option-color-control.dummy').addClass('replaceable-color-missing');
                    }
                });
            }
            
            // if the form isn't valid, then show an alert with the messages
            if (!validForm) {
                validationAlert.showAlert(messages);
            }

            return validForm;
        };

        // get the text field option color settings using the color controls element
        var getTextFieldOptionColorSettings = function ($colorControls) {
            var values = {
                ColorID: 0,
                OutlineColorID: 0,
                ShadowColorID: 0
            };
            var $fillColor = $colorControls.find('.Color-field input');
            var $outlineColor = $colorControls.find('.OutlineColor-field input');
            var $shadowColor = $colorControls.find('.ShadowColor-field input');
            if ($fillColor.length == 1)
                values.ColorID = utils.parseIntOrZero($fillColor.val());
            if ($outlineColor.length == 1)
                values.OutlineColorID = utils.parseIntOrZero($outlineColor.val());
            if ($shadowColor.length == 1)
                values.ShadowColorID = utils.parseIntOrZero($shadowColor.val());

            return values;
        };

        //TODO  This function is wirtten more than once.  Centrialize in one utlity module
        var parseIntOrZero = function (value, defaultValue) {
            if (!defaultValue)
                defaultValue = 0;
            var numberValue = parseInt(value);
            if (isNaN(numberValue))
                return defaultValue;
            return numberValue
        };


        return {
            validateForm: validateForm
        };
    });
// module for handling template groups
define('templateGroups', ['messaging', 'jquery', 'templateOptionsView', 'menu'], function (messaging, $, templateOptionsView, menu) {

    // private members for teh method
    var designActiveGroupSelector = '.hotspot-view.open .template-group.open .template-form';

    var $activeDialog = -1;

    // initialization function for topic subscription and click event handling
    var init = function () {
        $('.template-group .group-label').click(templateSelectButtonClicked);
    };



    // get the active group form element
    var getActiveGroupFormContainer = function () {
        // added for responsive
        return $(designActiveGroupSelector);
    };



    // dialog closed message handler
    var dialogClosed = function () {
        $activeDialog.dialog('destroy');
    };

    // template group label click handler
    var handleTemplateGroupLabelClick = function ($group) {
        if ($group.hasClass('locked-in'))
            return;
        // close any other open template group
        $group.siblings('.open').removeClass('open');
        // toggle the open state for the current template group
        $group.toggleClass('open');
        messaging.hotspotPreviewRequested.publish();
    };



    // template select button click handler
    var templateSelectButtonClicked = function () {
        var $this = $(this);

        // selecteds styling
        $this.closest('.menu-tab-content').find('.group-label .image-wrapper').removeClass('selected');
        $this.find('.image-wrapper').addClass('selected');


        var $group = $this.parents('.template-group');
        // added for responsive
        $this.closest('.menu-tab-content').find('.template-group').addClass('hide');
        $group.siblings('.open').removeClass('open');
        $group.removeClass('open').addClass('open');
        messaging.hotspotPreviewRequested.publish();
        // show view with the template options for the template group clicked
        var id = parseInt($group.attr('data-id'));

        if (parseInt($this.closest('.hotspot-view.open').find('.template-category-listing').attr('data-templategroupid')) == id) {
            // current user selected chocie is already shown.... don't do new ajax call
            // update hotspot area UI
            var hotspotStepData = {
                currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
                nextStep: parseInt($('.hotspot-view.open').attr('data-step')) + 1,
            }
            messaging.stepChange.publish(hotspotStepData);

        } else {
            templateOptionsView.openTemplateOptionsView(id);
        }


    };

    return {
        init: init,
        getActiveGroupFormContainer: getActiveGroupFormContainer
    };
});
// module for generating the template listing content for the modal window
define('templateListingGenerator', ['messaging', 'jquery', 'templateData'], function (messaging, $, templateData) {
	'use strict';

	// get the templates for the hotspot
    var getDesignTemplatesForHotspot = function (data, templateGroupId) {
        var $listingContainer = $('<div>').addClass('template-category-listing').attr('data-templateGroupId', templateGroupId);
		// loop over each category and add a category item to the modal view content
		data.forEach(function (category, categoryIndex, categories) {
			$listingContainer.append(createCategoryElement(category, categoryIndex));
		});
		return $listingContainer;
	};

	// create a category element for each category in the listing
	var createCategoryElement = function (category, categoryIndex) {
        var $category = $('<div>').addClass('category col-md-6  col-lg-4 col-xs-6').attr('data-id', category.ID).click(categoryClickHandlerFactory(category, categoryIndex));
		$category.append($('<div>').addClass('category-label').html(category.Label));	
        $category.append($('<div>').addClass('category-preview').css('background-image', 'url(' + category.PreviewImage + ')' + ', radial-gradient(#4F4F4F, #333)'));
		$category.append($('<div>').addClass('category-indicator'));
		return $category;
	};

	// create a category template element
	var createCategoryTemplateElement = function (category, template) {
        var $templateContainer = $('<div>').addClass('template-container col-md-6  col-lg-4 col-xs-6');
        var $template = $('<div>').addClass('template').attr('data-id', template.ID).click(applyButtonClickHandlerFactory(category, template));
        var $templateImage = $('<div>').addClass('template-preview').css('background-image', 'url(' + template.PreviewImage + ')');
        $template.append($templateImage);
        var $templateInfo = $('<div>').addClass('template-info');
        var $templateLabel = $('<div>').addClass('template-label-container');
        $templateLabel.append($('<div>').addClass('template-label').html(template.Label));
		$templateLabel.append($('<span>').addClass('template-sku').html(template.SKU));
        $templateInfo.append($templateLabel); 
        $templateInfo.append($('<div>').addClass('template-price').html('$' + template.Price.toFixed(2)));
        $template.append($templateInfo);
		$templateContainer.append($template);
		return $templateContainer;
	};

	

	// category click handler factory
	var categoryClickHandlerFactory = function (category, categoryIndex) {
		return function () {
            var $this = $(this);

            // return if current selection is already there
            var templateCategoryId = $this.attr('data-id');
            var $categoryTemplatesdiv = $this.closest('.template-category-listing').find('.category-row-templates');
            if ($categoryTemplatesdiv != undefined && $categoryTemplatesdiv != null && $categoryTemplatesdiv.length == 1 && $categoryTemplatesdiv.attr('data-id') == templateCategoryId)
            {
               // do nothing
            }
            else
            {
                //add selected styling
                $this.closest('.template-category-listing').find('.category-preview').removeClass('selected');
                $this.find('.category-preview').addClass('selected');

                var $rowTemplates = $('<div>').addClass('category-row-templates open').attr('data-id', templateCategoryId);
                // loop over each template for the category and add it to the template container
                category.Templates.forEach(function (template) {
                    $rowTemplates.append(createCategoryTemplateElement(category, template));
                });
                //remove any showing category templates
                $this.closest('.template-category-listing').find('.category-row-templates').remove();
                // show new category templates
                $this.closest('.template-category-listing').append($rowTemplates);
            }          

            // update hotspot area UI
            var hotspotStepData = {
                currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
                nextStep: parseInt($('.hotspot-view.open').attr('data-step')) + 1,
            }
            messaging.stepChange.publish(hotspotStepData);
		};
	};

	

	// category apply button click handler factory method
	var applyButtonClickHandlerFactory = function (category, template) {
        return function () {

            var $this = $(this);
            //add selected styling
            $this.closest('.category-row-templates').find('.template').removeClass('selected');
            $this.addClass('selected');


            // update hotspot area UI
            var hotspotStepData = {
                currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
                nextStep: parseInt($('.hotspot-view.open').attr('data-step')) + 1,
            }
            messaging.stepChange.publish(hotspotStepData);
            templateData.getTemplate(template.ID).done(templateGrabbed);
		};
	};

	// template grabbed promise handler
    var templateGrabbed = function (template) {
		messaging.hotspotTemplateSelected.publish(template);
	};

	return {
		getDesignTemplatesForHotspot: getDesignTemplatesForHotspot
	};
});
// template options view module
define('templateOptionsView',
    ['modalView', 'templateData', 'templateListingGenerator', 'messaging'], function
        (modalView, templateData, templateListingGenerator, messaging) {


        var templateGroupId = 0;

        // get the data for the hotspot template group id, then open the modal view with the template data
        var openTemplateOptionsView = function (id) {
            templateGroupId = id;
            templateData.getTemplatesForHotspotTemplateGroup(id).done(templatesGrabbed);
        };

        // open the template selection modal view with the data grabbed from the api
        var templatesGrabbed = function (data) {
            var $viewContent = templateListingGenerator.getDesignTemplatesForHotspot(data, templateGroupId);
            $('.template-group-categories-content').html($viewContent);

            // update hotspot area UI
            var hotspotStepData = {
                currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
                nextStep: parseInt($('.hotspot-view.open').attr('data-step')) + 1,
            }
            messaging.stepChange.publish(hotspotStepData);
        };


        return {
            openTemplateOptionsView: openTemplateOptionsView,
        };
    });
// another important module that handles the requesting, loading and rendering of preview images for hotspots
define('updateUI', ['messaging'], function (messaging) {
    'use strict';


    // private variables
    var resizedTimer = 0;

    // init function that subscribes to a few topics
    var init = function () {
        messaging.stepChange.subscribe(updateUI);
        $(window).resize(windowResized);
    };


    var windowResized = function () {
        clearTimeout(resizedTimer);
        resizedTimer = setTimeout(function () {
            // calcualte top offset
            var $currentHotspot = $('.hotspot-view.open'); 
            var top = $currentHotspot.find('.dynamic-content-wrapper').outerHeight();
            $currentHotspot.find('.menu-tab').css('top', top); 
        }, 100);
    };



    var updateUI = function(hotspotStepData)
    {
        var $currentHotspot = $('.hotspot-view.open'); 

        // Clicking a perspective where an applied hotspot template is applied.  Go right to the field instead of the template groups
        if ($currentHotspot.hasClass('applied') && !$currentHotspot.hasClass('back-to-design') && hotspotStepData.currentStep == 0) {
            SetStep4Settings();
            return;
        }
        else if ($currentHotspot.hasClass('applied') && $currentHotspot.hasClass('back-to-design') && hotspotStepData.currentStep == 4) {
            // go back to the design step after originally coming from a persepctive area click
            $currentHotspot.removeClass('back-to-design');
        }


        if (hotspotStepData.nextStep <= 0) {
            SetStep0Settings()
        }
        else if (hotspotStepData.nextStep == 1) {
            SetStep1Settings()
        }
        else if (hotspotStepData.nextStep == 2) {
            var moveForward = true;
            if (hotspotStepData.currentStep > hotspotStepData.nextStep) {
                moveForward = false;
            }
            SetStep2Settings(moveForward);
        }
        else if (hotspotStepData.nextStep == 3) {
            var moveForward = true;
            if (hotspotStepData.currentStep > hotspotStepData.nextStep) {
                moveForward = false;
            }
            SetStep3Settings(moveForward);
        }
        else if (hotspotStepData.nextStep == 4) {
            SetStep4Settings();
        }
    }



    // added for responsive
    $('.previous-step-button').click(function () {
        var hotspotStepData = {
            currentStep: parseInt($('.hotspot-view.open').attr('data-step')),
            nextStep: parseInt($('.hotspot-view.open').attr('data-step')) - 1,
        }
        updateUI(hotspotStepData);
    })



    var SetStep0Settings = function ()
    {
        var $currentHotspot = $('.hotspot-view.open');

        //update mobile project help text
        $('.mobile-project-helptext').removeClass('hide');

        // current hotspot no longer open
        $currentHotspot.removeClass('open');

        //remove elements in menu-overlay area 
        $('.menu-overlay').removeClass('open');



        // if there is at least 1 hotspot applied than show the review content instead of the project help text
        if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
            $('.menu .project-help-text').addClass('hide');
            $('.menu .review-container').removeClass('hide');
        }

        // mobile hack to only show preview when at step 4
        $('.project-ui').removeClass('active-fields');

        // hide hotspot views
        $currentHotspot.closest('.hotspot-content-container').addClass('hide');
        $currentHotspot.find('.template-group-content .navigation-wrapper').addClass('hide');

        // show persepctives
        $('.perspectives').removeClass('hide');
        
        //update step number
        $currentHotspot.attr('data-step', '0');
    }

    var SetStep1Settings = function () {

        var $currentHotspot = $('.hotspot-view.open');

        // hide review pane, if showing
        $('.menu .review-container').addClass('hide');

        // hide persepctives
        $('.perspectives').addClass('hide');

        // update top-info-content
        $currentHotspot.find('.top-info-content .application-copy-wrapper').removeClass('hide');
        $currentHotspot.find('.top-info-content .application-category-wrapper').addClass('hide').html('Select Category');
        $currentHotspot.find('.top-info-content').removeClass('hide');

        // update step button
        $currentHotspot.find('.menu-tab .previous-step-button').addClass('hide');

        // hide template group categories
        $currentHotspot.find('.template-group-categories-content').addClass('hide');

        // show all template groups
        $currentHotspot.find('.template-group').removeClass('hide');
        $currentHotspot.find('.template-group .group-label').removeClass('hide');
        $currentHotspot.find('.template-form').addClass('hide');     

        //remove hide class from parent of all previous elements
        $currentHotspot.closest('.hotspot-content-container').removeClass('hide');

        // calcualte top offset
        var top = $currentHotspot.find('.dynamic-content-wrapper').outerHeight();
        $currentHotspot.find('.menu-tab').css('top', top);

        //update step number
        $currentHotspot.attr('data-step', '1');
    }

    var SetStep2Settings = function (moveForward) {

        var $currentHotspot = $('.hotspot-view.open');

        // show all template group categories
        $currentHotspot.find('.template-group-categories-content').removeClass('hide');

        // update top-info-content
        $currentHotspot.find('.top-info-content .application-copy-wrapper').addClass('hide');
        $currentHotspot.find('.top-info-content .application-category-wrapper').html('Select Category').removeClass('hide');

        // update step buttons
        $currentHotspot.find('.menu-tab .previous-step-button').removeClass('hide');
        $currentHotspot.find('.previous-step-button .application-back').removeClass('hide');
        $currentHotspot.find('.previous-step-button .category-back').addClass('hide');
        $currentHotspot.find('.previous-step-button').removeClass('mobile-step-3');

        // hide templates within a category
        $currentHotspot.find('.category-row-templates').addClass('hide');
        $('.mobile-underlay').remove();

        // show template group categories
        $currentHotspot.find('.category').removeClass('hide');

        // calcualte top offset
        var top = $currentHotspot.find('.dynamic-content-wrapper').outerHeight();
        $currentHotspot.find('.menu-tab').css('top', top);

        //update step number
        $currentHotspot.attr('data-step', '2');

        // skip to next step if only 1 template category
        var $templateCategories = $currentHotspot.find('.menu-tab .menu-tab-content .template-category-listing .category');
        if ($templateCategories.length <= 1) {
            if (moveForward) {
                $templateCategories.click();
            } else {
                SetStep1Settings();
            }
        }     
    }

    var SetStep3Settings = function (moveForward)
    {
        var $currentHotspot = $('.hotspot-view.open');
        // hide field navigation buttons
        $currentHotspot.find('.template-group-content .navigation-wrapper').addClass('hide');

        // hide tempalte fields and field notes
        $currentHotspot.find('.template-form .field, .template-form .field-notes').addClass('hide');
        $currentHotspot.find('.template-form').addClass('hide');

        //hide hotspot preview - mobile hack
        $('.project-ui').removeClass('active-fields');
        
        // hide all template group categories
        $currentHotspot.find('.template-category-listing .category').addClass('hide');
        //$('.mobile-underlay').removeClass('hide');

        //show category templates
        $currentHotspot.find('.template-category-listing .category-row-templates').removeClass('hide');

        // update top-info-content
        $currentHotspot.find('.application-category-wrapper').html('Select Design');
        $currentHotspot.find('.top-info-content').removeClass('hide');

        // update step buttons
        $currentHotspot.find('.previous-step-button .application-back').addClass('hide');
        $currentHotspot.find('.previous-step-button .category-back').removeClass('hide');
        $currentHotspot.find('.previous-step-button').removeClass('hide');
        $currentHotspot.find('.previous-step-button').addClass('mobile-step-3');

        // show design
        $currentHotspot.find('.template-group-categories-content').removeClass('hide');


        // calcualte top offset
        var top = $currentHotspot.find('.dynamic-content-wrapper').outerHeight();
        $currentHotspot.find('.menu-tab').css('top', top);

        //update step number
        $currentHotspot.attr('data-step', '3');

        // skip to next step if only 1 template 
        var $templates = $currentHotspot.find('.menu-tab .menu-tab-content .category-row-templates .template');
        if ($templates.length <= 1) {
            if (moveForward) {
                $templates.click();
            } else {
                SetStep1Settings();
            }
        }   
    }

    var SetStep4Settings = function () {

        var $currentHotspot = $('.hotspot-view.open');

        // needed for first field in the hotspot template - if the first field is image
        messaging.initializeActiveImageField.publish();

        //show preview
        $('.preview-review-column').removeClass('hide');  

        // update template groups
        $currentHotspot.find('.template-group-categories-content').addClass('hide');
        $currentHotspot.find('.template-group').addClass('hide');
        $currentHotspot.find('.template-group.open').removeClass('hide');
        $currentHotspot.find('.template-group.open .group-label').addClass('hide');
        $('.mobile-underlay').addClass('hide');

        // hide top-info-content
        $currentHotspot.find('.top-info-content').addClass('hide');
        $('.mobile-project-helptext').addClass('hide');

        // update step button
        $currentHotspot.find('.menu-tab .previous-step-button').addClass('hide');

        //show hotspot preview - mobile hack
        $('.project-ui').addClass('active-fields');

        // hide review menu
        $('.menu .review-container').addClass('hide');

        //hide perspectives
        $('.perspectives').addClass('hide');      

        //update template feilds UI        
        $currentHotspot.find('.template-form .field, .template-form .field-notes').addClass('hide');
        $currentHotspot.find('.template-form .field:first').addClass('open').removeClass('hide');
        $currentHotspot.find('.template-group-content .navigation-wrapper').removeClass('hide');
        if ($currentHotspot.find('.template-form .field').length == 0) {
            $currentHotspot.find('.template-form .field-notes').removeClass('hide');
        }
        $currentHotspot.find('.template-form').removeClass('hide');

        // calcualte top offset
        var top = $currentHotspot.find('.dynamic-content-wrapper').outerHeight();
        $currentHotspot.find('.menu-tab').css('top', top);

        //remove hide class from parent of all previous elements
        $currentHotspot.closest('.hotspot-content-container').removeClass('hide');

        //update step number
        $currentHotspot.attr('data-step', '4');
    }

    
    return {
        updateUI: updateUI,
        init: init
    };
});
// validation alert module
// show a validation alert that slides down from the top of the screen
define('validationAlert', ['floatingAlert', 'viewData', 'underscore'], function (floatingAlert, viewData, _) {

	// show the messages provided in a div that slides down from the top of the window
    var showAlert = function (messages, label) {

        //remove duplicate error messages.  sometimes happens if image fiels with replacable colors
        messages = _.uniq(messages, false);
		// default the label value
		if (!label)
			label = 'Please make a selection for all required fields.';
		// create the template ui
		var alertContent = '<strong>' + label + '</strong><ul>';
		messages.forEach(function (message) {
			alertContent += '<li>' + message + '</li>';
		});
        alertContent += '</ul>';
        alertContent += '<div class="floating-alert-confirm-btn">OK</div>'              
		// display the alert content
		floatingAlert.showAlert(alertContent);
	};

	return {
		showAlert: showAlert
	};
});